import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	FloatingLabel,
	Form,
	Modal,
	Row,
	Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError, getStatus } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			accounts: action.payload,
			loadingFetch: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddDebt() {
	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [amount, setAmount] = useState();
	const [type, setType] = useState();
	const [types, setTypes] = useState([]);
	const [dues, setDues] = useState();
	const [expirationDate, setExpirationDate] = useState();
	const [periodicity, setPeriodicity] = useState();
	const [owner, setAccount] = useState();

	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const [{ loadingFetch, loadingCreate, accounts }, dispatch] = useReducer(
		reducer,
		{
			loadingFetch: true,
			accounts: [],
			loadingCreate: false,
			error: '',
		}
	);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get('/api/accounts', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				getTypes(result.data[1]._id);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(getError(error));
				}
			}
		};
		fetchData();
	}, []);

	//GET TYPES OF SHIPMENT BY ACCOUNT
	const getTypes = async (accountId) => {
		try {
			const { data } = await axios.get(
				`/api/shipmentTypes/account/${accountId}`
			);
			const sortedTypes = await data.reduce((acc, element) => {
				if (element.name === 'Normal') {
					return [element, ...acc];
				}
				return [...acc, element];
			}, []);
			await setTypes(sortedTypes);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const addDebtHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await axios.post(
				'/api/debts/createDebt',
				{
					amount,
					type,
					dues,
					expirationDate,
					periodicity,
					owner,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			toast.success('Deuda creada');
			navigate('/AdminScreen/debts');
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};
	return (
		<div>
			{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Crear Deuda</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea crear deuda?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={addDebtHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>Agregar Deuda</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Agregar Deuda
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Row>
													<Col md={5}>
														<Form.Group className="mb-3" controlId="amount">
															<Form.Label>Monto</Form.Label>
															<Form.Control
																type="text"
																onChange={(e) => setAmount(e.target.value)}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group className="mb-3" controlId="owner">
															<Form.Label>Tipo de envio</Form.Label>
															<Form.Select
																className="border border-1"
																onChange={async (e) => {
																	setType(e.target.value);
																}}
															>
																{types
																	? types.map((t) => {
																		return (
																			<option key={t._id} value={t._id}>
																				{t.name}
																			</option>
																		);
																	})
																	: null}
															</Form.Select>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col>
														<Form.Group className="mb-3" controlId="dues">
															<Form.Label>Cuotas</Form.Label>
															<Form.Control
																type="text"
																onChange={(e) => setDues(e.target.value)}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group
															className="mb-3"
															controlId="expirationDate"
														>
															<Form.Label>Vencimiento</Form.Label>

															<Form.Control
																type="date"
																onChange={(e) =>
																	setExpirationDate(e.target.value)
																}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group
															className="mb-3"
															controlId="periodicity"
														>
															<Form.Label>Periodicicidad</Form.Label>
															<Form.Control
																type="text"
																onChange={(e) => setPeriodicity(e.target.value)}
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<Form.Group className="mb-3 mt-3" controlId="owner">
													<FloatingLabel
														controlId="floatingInput"
														label="Selecciona una cuenta"
														className="mb-3"
													>
														<Form.Select
															className="border border-1"
															onChange={(e) => {
																setAccount(e.target.value);
															}}
														>
															<option key={0}></option>
															{accounts.map((account) => {
																return (
																	<option key={account._id} value={account._id}>
																		{account.name}
																	</option>
																);
															})}
														</Form.Select>
													</FloatingLabel>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
													>
                            Agregar Deuda
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddDebt;
