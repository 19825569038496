import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useContext, useState } from 'react';
import './MercadoPagoConfig.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Store } from '../../Store';

export default function MercadoPagoConfig() {
	const [appKey, setAppKey] = useState('');
	const { state } = useContext(Store);
	const { userInfo } = state;

	const handleUploadAppKey = () => {
		if (appKey) {
			uploadAppKey();
		} else {
			toast.error('Error. Campo vacío.');
		}
	};
	const uploadAppKey = async () => {
		try {
			const key = {
				mercadoPagoAppKey: appKey,
			};
			const { data } = await axios.put('/api/config/mpAppKey', key, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			console.error('data', data);
			toast.success(data.message);
		} catch (ex) {
			console.error(ex);
			toast.error('Error.');
		}
	};

	return (
		<div id="mercadopagoconfig">
			<Row className="align-items-center">
				<div className="ms-1">
					<p id="label-boton-faq" className="d-inline-block">
            AppKey de MercadoPago
					</p>
				</div>
			</Row>
			<Row className="align-items-center">
				<Form className="row align-items-center">
					<Form.Group className="col-10 mt-2" controlId="appkey">
						<Form.Control
							onChange={(e) => setAppKey(e.target.value)}
							type="password"
							required
						></Form.Control>
					</Form.Group>
				</Form>
			</Row>
			<Row className="justify-content-start mt-2">
				<Button
					onClick={handleUploadAppKey}
					className="col-6"
					disabled={!appKey}
				>
          Establecer
				</Button>
			</Row>
		</div>
	);
}
