import React, { createContext, useReducer } from 'react';

export const Store = createContext();
const initialState = {
	userInfo: localStorage.getItem('userInfo')
		? JSON.parse(localStorage.getItem('userInfo'))
		: null,
};
function reducer(state, action) {
	switch (action.type) {
	case 'USER_SIGNIN':
		localStorage.clear();
		return { ...state, userInfo: action.payload };
	case 'USER_SIGNOUT':
		localStorage.clear();
		return {
			...state,
			userInfo: null,
		};
	case 'SAVE_SHIPPING_ADDRESS':
		return {
			...state,
			cart: {
				...state.cart,
				shippingAddress: action.payload,
			},
		};

	default:
		return state;
	}
}
export function StoreProvider(props) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = { state, dispatch };
	return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
