import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	FloatingLabel,
	Form,
	Modal,
	Row,
	Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError, getStatus } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_ACCOUNTS_FAIL':
		return { ...state, loadingAccounts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, debt: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditDebt() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, debt, loadingAccounts, accounts }, dispatch] =
    useReducer(reducer, {
    	accounts: [],
    	loadingAccounts: true,
    	debt: {},
    	loading: true,
    	error: '',
    });
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [amount, setAmount] = useState();
	const [type, setType] = useState();
	const [types, setTypes] = useState([]);
	const [dues, setDues] = useState();
	const [expirationDate, setExpirationDate] = useState();
	const [periodicity, setPeriodicity] = useState();
	const [owner, setOwner] = useState();
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get(`/api/debts/${id}`);
				setAmount(result.data.amount);
				setType(result.data.shipment.type);
				setDues(result.data.dues);
				setExpirationDate(result.data.expirationDate);
				setPeriodicity(result.data.periodicity);
				setOwner(result.data.owner);
				getTypes();
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		const fetchAccounts = async () => {
			dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
			try {
				const result = await axios.get('/api/accounts', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(getError(error));
				}
			}
		};
		fetchData();
		fetchAccounts();
	}, [id]);

	//GET TYPES OF SHIPMENT BY ACCOUNT
	const getTypes = async () => {
		try {
			const { data } = await axios.get('/api/shipmentTypes/');
			const sortedTypes = await data.reduce((acc, element) => {
				if (element.name === 'Normal') {
					return [element, ...acc];
				}
				return [...acc, element];
			}, []);
			await setTypes(sortedTypes);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};
	const editDebtHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await axios.put(
				`/api/debts/${debt._id}`,
				{
					amount,
					balance: amount,
					type,
					dues,
					expirationDate,
					periodicity,
					owner,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			localStorage.setItem('debt', JSON.stringify(data));

			toast.success('Deuda actualizada Correctamente');
			navigate('/AdminScreen/debts');
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	return (
		<div>
			{(loading || loadingAccounts) && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={editDebtHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>Editar Deuda</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Editar Deuda
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Row>
													<Col md={5}>
														<Form.Group className="mb-3" controlId="amount">
															<Form.Label>Monto</Form.Label>
															<Form.Control
																type="text"
																defaultValue={debt.amount}
																onChange={(e) => setAmount(e.target.value)}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group className="mb-3" controlId="owner">
															<Form.Label>Tipo de envio</Form.Label>
															<Form.Select
																defaultValue={type ? type.name : null}
																className="border border-1"
																onChange={async (e) => {
																	setType(e.target.value);
																}}
															>
																{type ? (
																	<option key={type._id}>{type.name}</option>
																) : null}
																{types
																	? types.map((t) => {
																		if (type._id !== t._id) {
																			return (
																				<option key={t._id} value={t._id}>
																					{t.name}
																				</option>
																			);
																		}
																	})
																	: null}
															</Form.Select>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col>
														<Form.Group className="mb-3" controlId="dues">
															<Form.Label>Cuotas</Form.Label>
															<Form.Control
																type="text"
																defaultValue={debt.dues}
																onChange={(e) => setDues(e.target.value)}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group
															className="mb-3"
															controlId="expirationDate"
														>
															<Form.Label>Vencimiento</Form.Label>

															<Form.Control
																type="date"
																defaultValue={debt.expirationDate}
																onChange={(e) =>
																	setExpirationDate(e.target.value)
																}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group
															className="mb-3"
															controlId="periodicity"
														>
															<Form.Label>Periodicicidad</Form.Label>
															<Form.Control
																type="text"
																defaultValue={debt.periodicity}
																onChange={(e) => setPeriodicity(e.target.value)}
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<Form.Group
													className="mb-3 mt-2 border"
													controlid="account"
												>
													<FloatingLabel
														controlId="floatingInput"
														label="Selecciona una cuenta"
														className="mb-1"
													>
														<Form.Select
															defaultValue={debt.owner ? debt.owner._id : null}
															onChange={(e) => {
																setOwner(e.target.value);
															}}
														>
															<option
																key={debt.owner ? debt.owner._id : null}
																value={debt.owner ? debt.owner._id : null}
															>
																{debt.owner ? debt.owner.name : null}
															</option>
															{accounts.map((account) => {
																if (
																	account._id !==
                                  (debt.owner ? debt.owner._id : '')
																) {
																	return (
																		<option
																			key={account._id}
																			value={account._id}
																		>
																			{account.name}
																		</option>
																	);
																}
															})}
														</Form.Select>
													</FloatingLabel>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
													>
                            Guardar Cambios
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default EditDebt;
