import React from 'react';
import { useLocation } from 'react-router-dom';
import './AdminFooter.css';

function AdminFooter() {
	const location = useLocation();
	const route = location.pathname;
	if (!route.includes('AdminScreen')) return null;

	return (
		<footer className='bg-light admin-footer text-lg-start gotaFooter borderFooter'>
			<a href='https://gotasoft.io/' target='_blank' title='Powered by Gotasoft' rel='noreferrer'>
				<p className='gotaFooter'>
     Powered by <img src='/LogoBlanco.svg' alt='Gotasoft' />
				</p>
				<p className='copyrightFooter'>©All rights reserved 2022</p>
			</a>
		</footer>
	);
}
export default AdminFooter;
