import axios from 'axios';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import './DeleteFaqQuestionModal.css';

export default function ModalDeleteFaqQuestion({ deleteShow, handleClose, question, onHide }) {
	const deleteMethod = async () => {
		try {
			await axios.delete(`/api/faq/${question._id}`);
			toast.success('Pregunta eliminada');
			handleClose();
		} catch (ex) {
			toast.error('Error');
			console.error(ex);
		}
	};

	return (
		<Modal show={deleteShow} onHide={onHide} dialogClassName='modal-delete-question'>
			<Modal.Header closeButton>
				<Modal.Title>Borrar pregunta</Modal.Title>
			</Modal.Header>
			<Modal.Body>¿Estás seguro de que quieres eliminar esta pregunta?</Modal.Body>
			<Modal.Footer>
				<Button variant='primary' onClick={deleteMethod}>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
