import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Dropdown,
	DropdownButton,
	Modal,
	Row,
	Table,
} from 'react-bootstrap';
import Geocode from 'react-geocode';
import { Helmet } from 'react-helmet-async';
import {
	BsFileEarmarkRichtext,
	BsPencilSquare,
	BsPlusCircle,
	BsTrash,
} from 'react-icons/bs';
import { FaAddressBook } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import {
	GoogleMapsApiKey,
	getAddress,
	getError,
	getStatus,
	maxitemsPerPage,
} from '../../utils';
import ContactDetails from './ContactDetails';
import './ContactList.css';
import EditContact from './EditContact';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			contacts: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'DELETE_RESET':
		return {
			...state,
			loadingDelete: false,
			successDelete: false,
			loading: true,
		};
	case 'SHOW_CONTACT_DETAILS':
		return {
			...state,
			showContactModal: true,
			selectedContact: action.payload || {},
		};
	case 'HIDE_CONTACT_DETAILS':
		return { ...state, showContactModal: false, selectedContact: {} };
	case 'SHOW_EDIT_CONTACT':
		return {
			...state,
			editContactModal: true,
			selectedContact: action.payload,
		};
	case 'SHOW_NEW_CONTACT':
		return { ...state, editContactModal: true };
	case 'HIDE_EDIT_CONTACT':
		return { ...state, editContactModal: false, selectedContact: {} };
	case 'RELOAD_CONTACTS':
		return {
			...state,
			editContactModal: false,
			showContactModal: false,
			selectedContact: {},
			loading: true,
		};
	default:
		return state;
	}
};

function ContactList() {
	const [
		{
			loading,
			error,
			contacts,
			successDelete,
			itemQuantity,
			selectedContact,
			showContactModal,
			editContactModal,
		},
		dispatch,
	] = useReducer(reducer, {
		contacts: [],
		loading: true,
		error: '',
		itemQuantity: 0,
		showContactModal: false,
		editContactModal: false,
		selectedContact: {},
	});
	Geocode.setApiKey(GoogleMapsApiKey);

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//#region pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = contacts.slice(indexOfFirstPost, indexOfLastPost);
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = contacts.slice(indexOfFirstPost, indexOfLastPost);
	};
	//#endregion

	useEffect(() => {
		if (!userInfo || (userInfo.isAdmin && !userInfo.isSuperAdmin)) {
			navigate('/');
			return;
		}
		const fetchData = async () => {
			const route = userInfo.isSuperAdmin
				? '/api/contacts'
				: `/api/contacts/account/${userInfo.account}`;
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				let { data: contacts } = await axios.get(route, {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});

				for (const contact of contacts) {
					if (contact.destination) {
						const address = await getAddress(contact.destination);
						contact.address = address[0].formatted_address;
					}
				}
				dispatch({ type: 'FETCH_SUCCESS', payload: contacts });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(getError(error));
				}
			}
		};

		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else if (loading) {
			fetchData();
		}
	}, [successDelete, loading]);

	async function handleDelete(contact) {
		setContactToDelete(contact);
		handleShow();	}

	async function deleteContactHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.delete(
				`/api/contacts/${contactToDelete._id}`,
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({ type: 'DELETE_SUCCESS' });
			setShowModal(false);
			toast.success('Contacto Eliminado');
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	}
	//#region MODALS

	async function editContactHandler(contact) {
		dispatch({ type: 'SHOW_EDIT_CONTACT', payload: contact });
	}
	//MODAL
	const [contactToDelete, setContactToDelete] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	async function detailsContactHandler(contact) {
		dispatch({ type: 'SHOW_CONTACT_DETAILS', payload: contact });
	}

	async function newContactHandler() {
		dispatch({ type: 'SHOW_NEW_CONTACT' });
	}
	//#endregion
	return (
		<div id="contact-list-screen">
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Contacto</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar el contacto?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deleteContactHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Helmet>
				<title>Lista de Contactos</title>
			</Helmet>
			<ContactDetails
				show={showContactModal}
				contact={selectedContact}
				onHide={() => dispatch({ type: 'HIDE_CONTACT_DETAILS' })}
			/>
			<EditContact
				show={editContactModal}
				contactId={selectedContact._id}
				onHide={() => dispatch({ type: 'HIDE_EDIT_CONTACT' })}
				onSuccess={() => dispatch({ type: 'RELOAD_CONTACTS' })}
			/>
			<div
				id="contact-list-table"
			 className="container admin-con">

	
				<div className="w-100" style={{ overflow: 'visible' }}>
					<h3 className="text-right mt-3 mb-3" style={{ margin: '2.5% 0' }}>
						<FaAddressBook /> Contactos
					</h3>
					<div
						style={{
							display: 'flex',
							justifyContent: 'right',
							alignItems: 'center',
						}}
					>
						<Button
							className="btn btn-dark m-1 fixed-right"
							value="Crear Contacto"
							onClick={() => newContactHandler()}
						>
							<span>
								<BsPlusCircle /> Crear Contacto
							</span>
						</Button>
					</div>
					{loading ? (
						<Row className="justify-content-center spinner-row">
							<LoadingBox className="col-1" />
						</Row>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : currentPosts ? (
						<>
							<Card>
								<Table responsive size="sm">
									<thead className="align-items-center table-order tHead">
										<th className="col-lg-2 tableHeader">Nombre</th>
										<th className="col-lg-3 tableHeader">Email</th>
										<th className="col-lg-1 tableHeader">Teléfono</th>
										{userInfo && userInfo.isSuperAdmin && (
											<th className="col-lg-3 tableHeader">Cuenta</th>
										)}
										<th className="col-lg-3 tableHeader">Dirección</th>
										<th className="col-lg-1 tableHeader">Acciones</th>
									</thead>
									{currentPosts.map((contact, i) => (
										<tbody
											key={i}
											className="align-items-center table-order tableBodyHover"
										>
											<tr key={contact._id}>
												<td className="col-lg-2 tableBody">{contact.name}</td>
												<td className="col-lg-3 tableBody">{contact.email}</td>
												<td className="col-lg-1 tableBody">{contact.phone}</td>
												{userInfo && userInfo.isSuperAdmin && (
													<td className="col-lg-3 tableBody">
														{contact.account && contact.account.name}
													</td>
												)}
												<td className="col-lg-3 tableBody">
													{contact.address}
												</td>
												<td className="col-lg-1 tableBody">
													<DropdownButton drop="start" title="" style={{ position: 'static' }}>
														<Dropdown.Item
															onClick={() => detailsContactHandler(contact)}
														>
															<BsFileEarmarkRichtext />
                              Ver Detalles
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() => editContactHandler(contact)}
														>
															<BsPencilSquare />
                              Editar
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() => handleDelete(contact)}
														>
															<BsTrash> </BsTrash> Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										</tbody>
									))}
								</Table>
							</Card>

							<Pagination
								className="pagination-bar"
								totalCount={itemQuantity}
								onPageChange={onPageChange}
								currentPage={currentPage}
								pageSize={maxitemsPerPage}
							></Pagination>
						</>
					) : (
						<MessageBox>
							{userInfo && userInfo.isSuperAdmin
								? 'No existen contactos en el sistema.'
								: 'No hay contactos asociados a esta cuenta'}
						</MessageBox>
					)}
				</div>
			</div>
		</div>
	);
}

export default ContactList;
