import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';

import { Button, Card, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, getStatus, maxitemsPerPage } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			maps: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function MapList() {
	const [{ loading, error, maps, successDelete, itemQuantity }, dispatch] =
    useReducer(reducer, {
    	maps: [],
    	loading: true,
    	error: '',
    	itemQuantity: 0,
    });

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = maps.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = maps.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/maps', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});

				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(getError(error));
				}
			}
		};

		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	const handleMap = (mapId) => {
		navigate(`/AdminScreen/mapScreen/${mapId}`);
	};

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Mapas</title>
			</Helmet>

			<div className="container admin-con">
				<div className="w-100">
					<h3 className="text-right mt-3 mb-3" style={{ margin: '2.5% 0' }}>
						<FaMapMarkedAlt /> Mapas
					</h3>

					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Card>
							<Table responsive size="sm">
								<thead className="align-items-center table-order tHead">
									<th className="col-lg-4 tableHeader">Cuenta</th>
									<th className="col-lg-3 tableHeader"></th>
								</thead>
								{currentPosts.map((map, i) => (
									<tbody
										key={i}
										className="align-items-center table-order tableBodyHover"
									>
										<tr key={map._id}>
											<td className="col-lg-5 tableBody">
												{map.account ? map.account.name : null}
											</td>
											<td className="col-lg-3 tableBody">
												{' '}
												<Button
													className="btn-outline-info btn light ml-1"
													onClick={() => handleMap(map._id)}
												>
													<FaMapMarkedAlt className="fa-lg mx-1" /> Ver Mapa
												</Button>
											</td>
										</tr>
									</tbody>
								))}
							</Table>
						</Card>
					)}
				</div>

				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</div>
	);
}

export default MapList;
