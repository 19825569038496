import { Box, Flex, SkeletonText } from '@chakra-ui/react';
import {
	Accordion,
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	OverlayTrigger,
	Row,
	Stack,
	Table,
	Tooltip,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

import {
	Autocomplete,
	DirectionsRenderer,
	GoogleMap,
	Marker,
	Polygon,
	useJsApiLoader,
} from '@react-google-maps/api';
import axios from 'axios';
import moment from 'moment';
import Geocode from 'react-geocode';
import { BsTrash, BsWhatsapp } from 'react-icons/bs';
import { FaEraser, FaRegPlusSquare, FaRoute } from 'react-icons/fa';
import { MdOutlineEditLocationAlt, MdOutlineMyLocation } from 'react-icons/md';
import { TbArrowsUpDown } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { GoogleMapsApiKey, getError } from '../../utils';

import React, {
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import ShipmentAdditionalDetailsModal from './ModalExtraShipmentInfo/ShipmentAdditionalDetailsModal';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_ZONES_REQUEST':
		return { ...state, loadingZones: true };
	case 'FETCH_ZONES_SUCCESS':
		return { ...state, zones: action.payload, loadingZones: false };
	case 'FETCH_ZONES_FAIL':
		return { ...state, loadingZones: false, error: action.payload };
	case 'FETCH_CONTACTS_REQUEST':
		return { ...state, contact: null };
	case 'FETCH_CONTACTS_SUCCESS':
		return { ...state, contacts: action.payload, loadingContacts: false };
	case 'FETCH_CONTACTS_FAIL':
		return {
			...state,
			loadingContacts: false,
			fetchContactsError: action.payload,
		};
	case 'SET_CONTACT':
		return { ...state, contact: action.payload };
	case 'FETCH_MAP_REQUEST':
		return { ...state, loadingMap: true };
	case 'FETCH_MAP_SUCCESS':
		return { ...state, map: action.payload, loadingMap: false };
	case 'FETCH_MAP_FAIL':
		return { ...state, loadingMap: false, error: action.payload };
	case 'FETCH_TYPES_REQUEST':
		return { ...state, loadingTypes: true };
	case 'FETCH_TYPES_SUCCESS':
		return {
			...state,
			types: action.payload,
			loadingTypes: false,
		};
	case 'CALCULATE_ROUTE_REQUEST':
		return { ...state, loadingRoute: true };
	case 'CALCULATE_ROUTE_SUCCESS':
		return { ...state, loadingRoute: false };
	case 'CALCULATE_ROUTE_FAIL':
		return { ...state, loadingRoute: false, error: action.payload };
	case 'FETCH_TYPES_FAIL':
		return { ...state, loadingTypes: false, error: action.payload };
	case 'FETCH_ACCOUNT_REQUEST':
		return { ...state };
	case 'FETCH_ACCOUNT_SUCCESS':
		return { ...state, account: action.payload };
	case 'FETCH_ACCOUNT_FAIL':
		return { ...state, error: action.payload };
	case 'FETCH_REL_REQUEST':
		return { ...state, loadingRel: true };
	case 'FETCH_REL_SUCCESS':
		return { ...state, rels: action.payload, loadingRel: false };
	case 'FETCH_REL_FAIL':
		return { ...state, loadingRel: false, error: action.payload };
	case 'FETCH_COMPANY_REQUEST':
		return { ...state };
	case 'FETCH_COMPANY_SUCCESS':
		return { ...state, GotaSettings: action.payload, loadingCompany: false };
	case 'FETCH_COMPANY_FAIL':
		return { ...state, loadingCompany: false, error: action.payload };

	case 'DELETE_RESET':
		return { ...state, successDelete: false };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SHIPMENT_DATA':
		return {
			...state,
			shipmentData: action.payload,
			showAdditionalDetailsModal: true,
		};
	case 'SAVE_EXTRA_SHIPMENT_INFO':
		return {
			...state,
			shipmentData: {
				...state.shipmentData,
				additionalDetails: { ...action.payload },
			},
		};
	case 'CLOSE_SHIPMENT_INFO_MODAL':
		return { ...state, showAdditionalDetailsModal: false };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
			showAdditionalDetailsModal: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	case 'FETCH_SHIPMENTS_REQUEST':
		return { ...state, loadingShipments: true };
	case 'FETCH_SHIPMENTS_SUCCESS':
		return { ...state, loadingShipments: false };
	case 'FETCH_SHIPMENTS_FAIL':
		return { ...state, loadingShipments: false, error: action.payload };
	}
};

function NewShipment() {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: GoogleMapsApiKey,
		libraries: ['places', 'geometry'],
	});

	Geocode.setApiKey(GoogleMapsApiKey);

	const [
		{
			loading,
			error,
			zones,
			account,
			map,
			successDelete,
			loadingCreate,
			loadingZones,
			loadingTypes,
			loadingMap,
			loadingRoute,
			loadingShipments,
			types,

			rels,
			loadingRel,
			loadingCompany,
			GotaSettings,
			shipmentData,
			showAdditionalDetailsModal,
			contacts,
			fetchContactsError,
			loadingContacts,
			contact,
		},
		dispatch,
	] = useReducer(reducer, {
		account: {},
		zones: [],
		types: [],
		rels: [],
		map: {},
		shipmentData: {},
		loadingRel: true,
		loadingZones: true,
		loadingTypes: true,
		loadingMap: true,
		loading: true,
		loadingCreate: false,
		loadingCompany: true,
		loadingRoute: false,
		loadingShipments: false,
		showAdditionalDetailsModal: false,
		GotaSettings: {},
		error: '',
		contacts: [],
		fetchContactsError: false,
		loadingContacts: false,
		contact: null,
	});

	const navigate = useNavigate();
	const { state } = useContext(Store);
	const { userInfo } = state;

	//AUTOCOMPLETE
	const [autocomplete, setAutocomplete] = useState(null);
	const [autocompleteDest, setAutocompleteDest] = useState(null);

	//MODAL
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	//GOOGLE MAPS SERVICE
	const [gMap, setGmap] = useState(/** @type window.google.maps.Map */ (null));
	const [directionsResponse, setDirectionsResponse] = useState(null);
	const [distance, setDistance] = useState('');
	const [duration, setDuration] = useState('');
	const [shipmentPrice, setShipmentPrice] = useState('');

	//SETTING ROUTE
	const [editOrigin, setEditOrigin] = useState(false);
	const [origin, setOrigin] = useState(null);
	const [originMarker, setOriginMarker] = useState({
		lat: null,
		lng: null,
		show: false,
	});

	const [destination, setDestination] = useState(null);
	const [destinationMarker, setDestinationMarker] = useState({
		lat: null,
		lng: null,
		show: false,
	});

	//CLIENT DATA
	const [center, setCenter] = useState({ lat: null, lng: null });

	//ENVIO FORM
	const [shipmentList, setShipmentList] = useState([]);
	const [shipmentType, setShipmentType] = useState({});
	const [selectDate, setSelectDate] = useState(false);
	const [showZones, setShowZones] = useState(false);
	const [date, setDate] = useState();
	const [showContactButton, setShowContactButton] = useState(false);
	const [useAgenda, setUseAgenda] = useState(false);

	//SEARCH HANDLING

	/** @type React.MutableRefObject<HTMLInputElement> */
	const originRef = useRef(null);
	/** @type React.MutableRefObject<HTMLInputElement> */
	const destinationRef = useRef();

	//GET TYPES OF SHIPMENT BY ACCOUNT
	const getTypes = async () => {
		try {
			dispatch({ type: 'FETCH_TYPES_REQUEST' });

			const { data } = await axios.get('/api/shipmentTypes/');
			const sortedTypes = await data.reduce((acc, element) => {
				if (element.name === 'Normal') {
					return [element, ...acc];
				}
				return [...acc, element];
			}, []);

			dispatch({ type: 'FETCH_TYPES_SUCCESS', payload: sortedTypes });
			await setShipmentType(sortedTypes[0]._id);
		} catch (err) {
			dispatch({ type: 'FETCH_TYPES_FAIL', payload: getError(err) });
		}
	};

	//GET TYPES and ZONES OF SHIPMENT BY MAP
	const getTypesAndZones = async (mapId) => {
		try {
			dispatch({ type: 'FETCH_REL_REQUEST' });
			const { data } = await axios.get(
				`/api/typeZoneRelation/relationByMap/${mapId}`
			);
			dispatch({ type: 'FETCH_REL_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_REL_FAIL', payload: getError(err) });
		}
	};

	const fetchContacts = async () => {
		try {
			dispatch({ type: 'FETCH_CONTACTS_REQUEST' });
			const { data: contactList } = await axios.get(
				`/api/contacts/account/${userInfo.account}`,
				{
					headers: {
						authorization: `Bearer ${userInfo.token}`,
					},
				}
			);
			for (const c of contactList) {
				if (c.destination) {
					const { results: address } = await Geocode.fromLatLng(
						c.destination.lat,
						c.destination.lng
					);
					c.address = address[0];
				}
			}
			dispatch({ type: 'FETCH_CONTACTS_SUCCESS', payload: contactList });
		} catch (err) {
			console.error(err);
			dispatch({ type: 'FETCH_CONTACTS_FAIL' });
		}
	};
	const handleSelectContact = (contactId) => {
		const contact = contacts.find(({ _id }) => _id === contactId);
		dispatch({ type: 'SET_CONTACT', payload: contact });
	};
	//GET ACCOUNT OF USER
	const getAccount = async () => {
		try {
			dispatch({ type: 'FETCH_ACCOUNT_REQUEST' });
			if (!userInfo) {
				const defaultAccount = await axios.get(
					'/api/accounts/defaultAccount/get'
				);
				getTypes();
				fetchDataMap(defaultAccount.data._id);
				dispatch({
					type: 'FETCH_ACCOUNT_SUCCESS',
					payload: defaultAccount.data,
				});
			} else {
				const { data } = await axios.get(`/api/accounts/${userInfo.account}`);
				getTypes();
				fetchDataMap(data._id);
				dispatch({ type: 'FETCH_ACCOUNT_SUCCESS', payload: data });
			}
		} catch (err) {
			console.error(err);
			dispatch({ type: 'FETCH_ACCOUNT_FAIL', payload: getError(err) });
		}
	};

	const fetchDataMap = async (accountId) => {
		try {
			dispatch({ type: 'FETCH_MAP_REQUEST' });
			const { data } = await axios.get(`/api/maps/mapAccount/${accountId}`);
			getTypesAndZones(data._id);
			setCenter({ lat: data.latCentral, lng: data.lngCentral });
			getZones(data._id);
			setOriginMarker({
				lat: data.latCentral,
				lng: data.lngCentral,
				show: true,
			});
			if (originRef.current) {
				Geocode.fromLatLng(data.latCentral, data.lngCentral).then(
					(response) => {
						setOrigin(response.results[0]);
						originRef.current.value = response.results[0].formatted_address;
					},
					(error) => {
						console.error(error);
					}
				);
			}
			dispatch({ type: 'FETCH_MAP_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_MAP_FAIL', payload: getError(err) });
		}
	};

	const getZones = async (mapId) => {
		try {
			dispatch({ type: 'FETCH_ZONES_REQUEST' });
			const { data } = await axios.get(`/api/zones/shipment-map/${mapId}`);
			dispatch({ type: 'FETCH_ZONES_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_ZONES_FAIL', payload: getError(err) });
		}
	};

	const getCompanyData = async () => {
		try {
			dispatch({ type: 'FETCH_COMPANY_REQUEST' });
			const { data } = await axios.get('/api/config/public');
			dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: data });
			getAccount();
		} catch (err) {
			console.error(err);
			dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
		}
	};

	//GET MAP AND ZONES
	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else if (loadingCompany) {
			getCompanyData();
			fetchContacts();
		}
		if (!useAgenda) {
			dispatch({ type: 'SET_CONTACT', payload: null });
		}
		if (useAgenda && contact) {
			loadContactDestination();
		}
	}, [successDelete, shipmentData, useAgenda, contact]);

	if (!isLoaded) {
		return <SkeletonText />;
	}

	//AUTOCOMPLETE HANDLING
	//loading autocomplete
	const onLoadAutoComplete = (autocompleteInstance) => {
		setAutocomplete(autocompleteInstance);
	};
	const onLoadAutoCompleteDest = (autocompleteInstance) => {
		setAutocompleteDest(autocompleteInstance);
	};
	const loadContactDestination = () => {
		const { lat, lng } = contact.destination;
		setDestinationMarker({ lat, lng, show: true });
		setDestination(contact.address);
		destinationRef.current.value = contact.address.formatted_address;
	};
	//handling changes autocomplete
	const onOriginChanged = () => {
		if (autocomplete) {
			const originPlace = autocomplete.getPlace();
			if (originPlace.geometry) {
				setOrigin(originPlace);
				setOriginMarker({
					lat: originPlace.geometry.location.lat(),
					lng: originPlace.geometry.location.lng(),
					show: true,
				});
			} else {
				setOrigin(null);
			}
		} else {
			console.warning('Autocomplete is not loaded yet!');
		}
	};
	// Call setPath with new edited path
	const onEditOrigin = async (evt) => {
		originMarker.lat = evt.latLng.lat();
		originMarker.lng = evt.latLng.lng();
		Geocode.fromLatLng(evt.latLng.lat(), evt.latLng.lng()).then(
			(response) => {
				originRef.current.value = response.results[0].formatted_address;
			},
			(error) => {
				console.error(error);
			}
		);
		if (directionsResponse) {
			await calculateRoute(originMarker, destinationMarker);
		}
	};

	const onEditDestination = (evt) => {
		destinationMarker.lat = evt.latLng.lat();
		destinationMarker.lng = evt.latLng.lng();
		Geocode.fromLatLng(evt.latLng.lat(), evt.latLng.lng()).then(
			(response) => {
				const address = response.results[0].formatted_address;
				destinationRef.current.value = address;
			},
			(error) => {
				console.error(error);
			}
		);
		if (directionsResponse) {
			calculateRoute(originMarker, destinationMarker);
		}
	};

	const onDestinationChanged = () => {
		if (autocompleteDest) {
			const destinationPlace = autocompleteDest.getPlace();
			if (destinationPlace.geometry) {
				setDestination(destinationPlace);
				setDestinationMarker({
					lat: destinationPlace.geometry.location.lat(),
					lng: destinationPlace.geometry.location.lng(),
					show: true,
				});
			} else {
				setDestination(null);
			}
		} else {
			console.warning('Autocomplete is not loaded yet!');
		}
	};

	async function changeOrigin() {
		setEditOrigin(!editOrigin);
		if (directionsResponse) {
			setDestinationMarker({
				lat: destinationMarker.lat,
				lng: destinationMarker.lng,
				show: true,
			});
			setShowContactButton(false);
			setDirectionsResponse(null);
			setDistance('');
			setDuration('');
			setOriginMarker({
				lat: originMarker.lat,
				lng: originMarker.lng,
				show: false,
			});
			originRef.current.value = '';
		}
		if (!editOrigin) {
			setOriginMarker({
				lat: originMarker.lat,
				lng: originMarker.lng,
				show: false,
			});
			originRef.current.value = '';
		} else {
			setOriginMarker({
				lat: originMarker.lat,
				lng: originMarker.lng,
				show: true,
			});
			Geocode.fromLatLng(map.latCentral, map.lngCentral).then(
				(response) => {
					setOrigin(response.results[0]);
					originRef.current.value = response.results[0].formatted_address;
				},
				(error) => {
					console.error(error);
				}
			);
		}
	}

	async function exchangePoints() {
		const change = async () => {
			if (
				originRef.current.value === '' ||
        destinationRef.current.value === ''
			) {
				return;
			}
			const auxCurrentPlace = destinationRef.current.value;
			destinationRef.current.value = originRef.current.value;
			originRef.current.value = auxCurrentPlace;
			const auxMarker = {
				lat: destinationMarker.lat,
				lng: destinationMarker.lng,
				show: destinationMarker.show,
			};

			const result = {
				origin: destination,
				destination: origin,
				originMarker: auxMarker,
				destinationMarker: originMarker,
			};

			await setDestinationMarker({
				lat: originMarker.lat,
				lng: originMarker.lng,
				show: originMarker.show,
			});
			await setOriginMarker({
				lat: auxMarker.lat,
				lng: auxMarker.lng,
				show: auxMarker.show,
			});

			const auxPlace = destination;
			await setDestination(origin);
			await setOrigin(auxPlace);
			return result;
		};

		change().then(async (result) => {
			if (directionsResponse) {
				await calculateRoute(result.originMarker, result.destinationMarker);
			}
		});
	}
	const validateMarker = ({ lat, lng }) => lat != null && lng != null;
	//CALCULATE AND DRAW ROUTE
	async function calculateRoute(oMarker, dMarker) {
		if (!validateMarker(oMarker) || !validateMarker(dMarker)) {
			return;
		}
		try {
			dispatch({ type: 'CALCULATE_ROUTE_REQUEST' });
			await locationInZone();
			const coordinateDestination = new window.window.google.maps.LatLng(
				dMarker.lat,
				dMarker.lng
			);
			const coordinateOrigin = new window.google.maps.LatLng(
				oMarker.lat,
				oMarker.lng
			);
			//eslint-disable-next-line no-undef
			const directionsService = new google.maps.DirectionsService();
			const results = await directionsService.route({
				origin: coordinateOrigin,
				destination: coordinateDestination,
				// eslint-disable-next-line no-undef
				travelMode: google.maps.TravelMode.DRIVING,
			});
			oMarker.show = false;
			dMarker.show = false;
			setDirectionsResponse(results);
			setDistance(results.routes[0].legs[0].distance.text);
			setDuration(results.routes[0].legs[0].duration.text);
			if (destination.zone) {
				await calculatePrice(destination.zone._id, shipmentType);
			} else {
				setShipmentPrice(0);
				setShowContactButton(true);
			}
		} catch {
			dispatch({ type: 'CALCULATE_ROUTE_FAIL' });
			console.error('No fue posible calcular la ruta');
		}
		dispatch({ type: 'CALCULATE_ROUTE_SUCCESS' });
	}

	async function calculatePrice(selectedZoneId, typeOfShipment) {
		//SET PRICE ACCORDING ZONE, TYPE AND DATA

		if (!selectedZoneId) {
			setShipmentPrice(0);
			setShowContactButton(true);
			return;
		}
		const price = await getPrice(selectedZoneId, typeOfShipment);
		if (price !== 0) {
			setShipmentPrice(price);
			setShowContactButton(false);
		} else {
			setShipmentPrice(0);
			setShowContactButton(true);
		}
	}

	//CLEAR ROUTE
	function clearRoute() {
		setSelectDate(false);
		setShowContactButton(false);
		setDirectionsResponse(null);
		setDistance('');
		setDuration('');
		setDestinationMarker({ lat: null, lng: null, show: false });
		originMarker.show = true;
		setDestination(null);
		destinationRef.current.value = '';
	}

	//DETERMINE ZONE OF LOCATION
	async function locationInZone() {
		const coordinateDestiation = new window.google.maps.LatLng(
			destinationMarker.lat,
			destinationMarker.lng
		);
		const coordinateOrigin = new window.google.maps.LatLng(
			originMarker.lat,
			originMarker.lng
		);

		zones.forEach((z) => {
			const zpolygon = new window.google.maps.Polygon({ paths: z.location });
			const resultDestination =
        window.google.maps.geometry.poly.containsLocation(
        	coordinateDestiation,
        	zpolygon
        );
			if (resultDestination) {
				if(destination.zone){
					if(getPrice(destination.zone._id, shipmentType) < getPrice(z._id, shipmentType)){
						destination.zone = z;
					}

				} else{
					destination.zone = z;
				}			}
			const resultOrigin = window.google.maps.geometry.poly.containsLocation(
				coordinateOrigin,
				zpolygon
			);
			if (resultOrigin) {
				origin.zone = z;
			}
		});
	}

	//SAVES SHIPMENT DATA(create route, order and debt)
	//AND AWAITS EXTRA SHIPMENT INFO FROM MODAL
	async function saveShipmentData() {
		if (!userInfo) {
			handleShow();
			return;
		}
		//CREAR RUTA
		const destinantionAddress = {
			address: destination.formatted_address,
			city: destination.address_components.find(
				(a) => 'locality' === a.types[0]
			).long_name,
			zone: destination.zone,
		};

		const originAddress = {
			address: origin.formatted_address,
			city: origin.address_components.find((a) => 'locality' === a.types[0])
				.long_name,
			zone: origin.zone,
		};
		const shipmentData = {
			typeName: shipmentType.name,
			zoneName: destinantionAddress.zone.name,
			userEmail: userInfo.email,
			shipmentDate: date,
			type: shipmentType,
			price: shipmentPrice,
			account,
			user: userInfo._id,
			originAddress,
			destinantionAddress,
			distance: distance.value,
			duration: duration.value,
		};
		setSelectDate(false);
		dispatch({ type: 'CREATE_SHIPMENT_DATA', payload: shipmentData });
	}

	//CREATE SHIPMENT(create route, order,debt and additional details)
	async function addShipment(additionalDetails) {
		try {
			dispatch({ type: 'FETCH_SHIPMENTS_REQUEST' });
			const finalShipmentData = { ...shipmentData, additionalDetails };
			setShipmentList([...shipmentList, finalShipmentData]);
			clearRoute();
			setUseAgenda(false);
		} catch (ex) {
			dispatch({ type: 'FETCH_SHIPMENTS_FAIL' });
			toast.error(getError(ex));
		}
		dispatch({ type: 'FETCH_SHIPMENTS_SUCCESS' });
	}

	//get prices
	function getPrice(selectedZoneId, selectedTypeId) {
		let priceToShow = 0;
		const filterByZone = rels.filter((r) => r.zone._id === selectedZoneId);
		const filterByZoneAndType = filterByZone.find(
			(r) => r.type._id === selectedTypeId
		);
		if (filterByZoneAndType) {
			priceToShow = filterByZoneAndType.price;
		}
		return priceToShow;
	}

	//CREATE ALL SHIPMENTS(create route, order,debt and additional details)
	async function createShipments() {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await axios.post('/api/orders/createOrders', {
				shipmentList: shipmentList,
			});
			toast.success('Envios creados');
			dispatch({ type: 'CREATE_SUCCESS' });
			navigate('/AdminScreen/shipments');
		} catch (ex) {
			dispatch({ type: 'CREATE_FAIL' });
			toast.error(getError(ex));
		}
		dispatch({ type: 'CREATE_SUCCESS' });
	}

	const getTypeName = (typeId) => {
		const type = types.find((type) => type._id === typeId);
		return type ? type.name : ' ';
	};

	async function handleDelete(indexOfOrder) {
		dispatch({ type: 'FETCH_SHIPMENTS_REQUEST' });
		try {
			const updatedList = shipmentList.slice();
			updatedList.splice(indexOfOrder, 1);
			setShipmentList(updatedList);
		} catch {
			dispatch({ type: 'FETCH_SHIPMENTS_FAIL' });
		}
		dispatch({ type: 'FETCH_SHIPMENTS_SUCCESS' });
	}

	return (
		<div>
			<ShipmentAdditionalDetailsModal
				handleClose={() => dispatch({ type: 'CLOSE_SHIPMENT_INFO_MODAL' })}
				show={showAdditionalDetailsModal}
				submitHandler={addShipment}
				name={contact && contact.name}
				phone={contact && contact.phone}
			/>
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Crear Envío</Modal.Title>
				</Modal.Header>
				<Modal.Body>Debe estar logueado para programar un envío</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={() => navigate('/signin')}>Iniciar Sesión</Button>
				</Modal.Footer>
			</Modal>

			<div className="large-container">
				<Helmet>
					<title>Nuevo Envio</title>
				</Helmet>

				<div className="container admin-con">
					<div className="w-100">
						<Row className=" d-flex justify-content-center align-items-center">
							<Col md={12} className="m-2 bg-white">
								<div className="borderLine"></div>
								<div className="mb-3 mt-md-4">
									<div className="mb-3">
										<h2 className="fw-bold mb-3 text-uppercase px-5 text-center">
                      Nuevo Envio
										</h2>
										<div className="borderLine"></div>

										{loading &&
                    loadingMap &
                      loadingTypes &
                      loadingZones &
                      loadingCompany &
                      loadingCreate ? (
												<Row className="justify-content-center spinner-row">
													<LoadingBox className="col-1" />
												</Row>
											) : (
												<Row>
													<Col md={shipmentList.length > 0 ? 8 : 12}>
														{userInfo ? (
															<div id="toggle-use-agenda-container">
																<Form.Group
																	id="toggle-use-agenda-form-group"
																	controlId="toggle-use-agenda"
																>
																	<Form.Check
																		type="checkbox"
																		label="Seleccionar destino desde agenda de contactos"
																		onChange={() => setUseAgenda(!useAgenda)}
																		checked={useAgenda}
																	></Form.Check>
																</Form.Group>
															</div>
														) : null}
													</Col>
													<Col md={shipmentList.length > 0 ? 8 : 12}>
														<div className="m-1 border border-1">
															<Form
																onSubmit={(e) => e.preventDefault()}
																className="m-2"
															>
																{useAgenda && (
																	<div>
																		<Form.Group
																			className="mb-3 mx-0.5"
																			controlId="name"
																		>
																			{loadingContacts ? (
																				<LoadingBox />
																			) : fetchContactsError ? (
																				<MessageBox variant="danger">
                                        Error. No se pudo obtener los contactos
                                        asociados a la cuenta.
																				</MessageBox>
																			) : (
																				<>
																					<Form.Label>
                                          Seleccione un contacto
																					</Form.Label>
																					<Form.Select
																						className="border border-1"
																						onChange={(e) =>
																							handleSelectContact(e.target.value)
																						}
																						disabled={directionsResponse}
																					>
																						<option value="">-</option>
																						{contacts &&
                                            contacts.map((contact) => (
                                            	<option
                                            		value={contact._id}
                                            		key={contact._id}
                                            	>
                                            		{`${contact.name} - ${contact.address.formatted_address}`}
                                            	</option>
                                            ))}
																					</Form.Select>
																				</>
																			)}
																		</Form.Group>
																	</div>
																)}

																<div className="w-100 border border-1 border-light mb-3"></div>
																<div></div>
																<Row className="mx-0.5">
																	<Col xs={9} md={10}>
																		<Form.Group className="mb-1" controlId="name">
																			<Autocomplete
																				onLoad={onLoadAutoComplete}
																				onPlaceChanged={onOriginChanged}
																				restrictions={{
																					country: ['uy'],
																				}}
																			>
																				<Form.Control
																					type="text"
																					placeholder="Origen"
																					ref={originRef}
																					required
																					disabled={!editOrigin}
																				></Form.Control>
																			</Autocomplete>
																		</Form.Group>
																	</Col>

																	<Col xs={1} md={2}>
																		<OverlayTrigger
																			key="top"
																			placement="top"
																			overlay={
																				<Tooltip id={'tooltip-top'}>
																					{!editOrigin
																						? 'Editar Origen'
																						: 'Resetear Origen'}
																				</Tooltip>
																			}
																		>
																			<Button
																				onClick={changeOrigin}
																				className="mb-2"
																			>
																				<MdOutlineEditLocationAlt />
																			</Button>
																		</OverlayTrigger>
																	</Col>
																</Row>

																<Row>
																	<Col xs={9} md={10}>
																		<Form.Group
																			className="mb-3 mx-0.5"
																			controlId="name"
																		>
																			<Autocomplete
																				onLoad={onLoadAutoCompleteDest}
																				onPlaceChanged={onDestinationChanged}
																				restrictions={{
																					country: ['uy'],
																				}}
																			>
																				<Form.Control
																					type="text"
																					placeholder="Destino"
																					ref={destinationRef}
																					required
																					disabled={
																						directionsResponse ? true : false
																					}
																				></Form.Control>
																			</Autocomplete>
																		</Form.Group>
																	</Col>
																	<Col xs={1} md={2}>
																		<Button
																			onClick={exchangePoints}
																			className="mb-3 mx-0.5"
																		>
																			<TbArrowsUpDown />
																		</Button>
																	</Col>
																</Row>
																{originMarker.show && destinationMarker.show ? (
																	<div>
																		<div className="w-100 border border-1 border-light mb-3"></div>
																		<Stack
																			direction="horizontal"
																			className="my-2 justify-content-center align-items-center"
																			gap={5}
																			controlId="route"
																		>
																			<Button
																				variant="outline-info"
																				onClick={() =>
																					calculateRoute(
																						originMarker,
																						destinationMarker
																					)
																				}
																				size="md"
																				disabled={
																					directionsResponse ? true : false
																				}
																			>
																				<FaRoute /> <small> Calcular Ruta</small>
																			</Button>
																		</Stack>
																	</div>
																) : null}
																{directionsResponse ? (
																	<div>
																		<div className="w-100 border border-1 border-light mb-3"></div>
																		<Row>
																			<Col>
																				<Form.Group
																					className="mb-3"
																					controlId="owner"
																				>
																					<Form.Label>Tipo de envio</Form.Label>
																					<Form.Select
																						className="border border-1"
																						onChange={async (e) => {
																							setShipmentType(e.target.value);
																							{
																								if (directionsResponse && destination.zone) {
																									await calculatePrice(
																										destination.zone._id,
																										e.target.value
																									);
																								}
																							}
																						}}
																					>
																						{loadingTypes ? (
																							<LoadingBox></LoadingBox>
																						) : types ? (
																							types.map((t) => {
																								return (
																									<option
																										key={t._id}
																										value={t._id}
																									>
																										{t.name}
																									</option>
																								);
																							})
																						) : null}
																					</Form.Select>
																				</Form.Group>
																			</Col>
																			<Col>
																				<Form.Group
																					className="mb-3"
																					controlId="name"
																				>
																					<Form.Check
																						type="checkbox"
																						label="Elegir Fecha y Hora de Envio"
																						onChange={() =>
																							setSelectDate(!selectDate)
																						}
																					></Form.Check>
																				</Form.Group>
																				{selectDate ? (
																					<React.Fragment>
																						<Row>
																							<Col>
																								<Form.Group
																									className="mb-3"
																									controlId="name"
																								>
																									<Form.Label>Fecha</Form.Label>
																									<Form.Control
																										type="date"
																										disabled={!selectDate}
																										min={
																											new Date()
																												.toISOString()
																												.split('T')[0]
																										}
																										onChange={(e) =>
																											setDate(e.target.value)
																										}
																									></Form.Control>
																								</Form.Group>
																							</Col>
																							<Col>
																								<Form.Group
																									className="mb-3"
																									controlId="hora"
																								>
																									<Form.Label>Hora</Form.Label>
																									<Form.Control type="time"></Form.Control>
																								</Form.Group>
																							</Col>
																						</Row>
																					</React.Fragment>
																				) : null}
																			</Col>
																		</Row>
																		<div className="w-100 border border-1 border-light mb-3"></div>
																		<Table striped bordered hover responsive>
																			<thead className="align-items-center table-order tHead">
																				<tr className="align-items-center table-order text-center">
																					<th> Fecha de Envío</th>
																					<th> Distancia</th>
																					<th> Precio estimado</th>
																				</tr>
																			</thead>
																			<tbody className="align-items-center table-order tableBodyHover pointerCursor">
																				<tr className="align-items-center table-order text-center">
																					<td>
																						{' '}
																						{selectDate
																							? moment(date).format('DD/MM/YY')
																							: 'Antes posible'}
																					</td>
																					<td>{distance}</td>
																					<td>
																						{shipmentPrice === 0
																							? ' Consulta al proveedor por el precio de esta zona'
																							: '$' + shipmentPrice}
																					</td>
																				</tr>
																			</tbody>
																		</Table>
																		<div className="w-100 border border-1 border-light"></div>
																		<Stack
																			direction="horizontal"
																			gap={5}
																			className="my-2 justify-content-center align-items-center"
																		>
																			
																			{userInfo && !showContactButton ? (
																				<Button
																					variant="outline-success"
																					className="m-1"
																					disabled={!directionsResponse}
																					value="Crear Envio"
																					onClick={saveShipmentData}
																				>
																					<FaRegPlusSquare />
																					<small> Agregar Envio</small>
																				</Button>
																			) : (
																				<Button
																					variant="success"
																					color="white"
																					href={GotaSettings.whatsappUrl}
																					target="_blank"
																					rel="noopener noreferrer"
																				>
																					<BsWhatsapp color="white" />
																					{'   '} Contactate
																				</Button>
																			)}
																			<Button
																				variant="outline-secondary"
																				onClick={clearRoute}
																				disabled={
																					directionsResponse ? false : true
																				}
																			>
																				<FaEraser />
																				<small> Borrar Ruta</small>
																			</Button>
																		</Stack>
																	</div>
																) : null}
															</Form>
														</div>

														<div className="d-flex mt-2 justify-content-center align-items-center">
															{!loadingRoute &&
                            !loadingZones &&
                            !loadingShipments &&
                            !loadingMap ? (
																	error ? (
																		<MessageBox cols={12} variant="danger">
																			{error}
																		</MessageBox>
																	) : (
																		<Flex
																			position="relative"
																			flexDirection="column"
																			alignItems="center"
																			h="60vh"
																			w="100vw"
																		>
																			<Box
																				position="relative"
																				left={0}
																				top={0}
																				h="80%"
																				w="100%"
																				className="border border-2"
																			>
																				{loadingMap ||
                                      loadingZones ||
                                      (loadingShipments && (
                                      	<LoadingBox></LoadingBox>
                                      ))}

																				<Row className="justify-content-center align-items-center m-2">
																					<Col>
																						<Form.Check
																							type="checkbox"
																							label="Mostrar Zonas"
																							onChange={() =>
																								setShowZones(!showZones)
																							}
																						></Form.Check>
																					</Col>
																					<Col>
																						<OverlayTrigger
																							key="top"
																							placement="top"
																							overlay={
																								<Tooltip id={'tooltip-top'}>
                                              Centrar Mapa
																								</Tooltip>
																							}
																						>
																							<Button
																								variant="outline-info"
																								onClick={() => {
																									gMap.panTo(center);
																									gMap.setZoom(12);
																								}}
																							>
																								<MdOutlineMyLocation />
																							</Button>
																						</OverlayTrigger>
																					</Col>
																				</Row>
																				<GoogleMap
																					center={center}
																					zoom={12}
																					mapContainerStyle={{
																						width: '100%',
																						height: '100%',
																					}}
																					options={{
																						zoomControl: false,
																						streetViewControl: false,
																						mapTypeControl: false,
																						fullscreenControl: false,
																					}}
																					onLoad={(map) => setGmap(map)}
																				>
																					{originMarker.show ? (
																						<Marker
																							label={'O'}
																							position={{
																								lat: originMarker.lat,
																								lng: originMarker.lng,
																							}}
																							draggable
																							onDragEnd={onEditOrigin}
																						/>
																					) : null}

																					{destinationMarker.show ? (
																						<Marker
																							label={'D'}
																							position={{
																								lat: destinationMarker.lat,
																								lng: destinationMarker.lng,
																							}}
																							draggable
																							onDragEnd={onEditDestination}
																						/>
																					) : null}
																					{directionsResponse && (
																						<DirectionsRenderer
																							directions={directionsResponse}
																						/>
																					)}

																					{showZones
																						? zones.map(
																							(zone) => (
																								(zone['options'] = {
																									fillColor: zone.color,
																									fillOpacity: 0.6,
																									strokeWeight: 1,
																									strokeColor: zone.color,
																								}),
																								(
																									<Polygon
																										options={zone.options}
																										path={zone.location}
																									/>
																								)
																							)
																						)
																						: null}
																				</GoogleMap>
																			</Box>
																		</Flex>
																	)
																) : (
																	<Row className="justify-content-center spinner-row">
																		<LoadingBox className="col-1" />
																	</Row>
																)}
														</div>
													</Col>
													<Col
														md={4}
														style={{
															display: shipmentList.length > 0 ? 'block' : 'none',
														}}
													>
														<Container fluid>
															<h5 className="text-center my-3">
                              Listado de Envios
															</h5>

															{loadingTypes || loadingRel ? (
																<LoadingBox></LoadingBox>
															) : (
																<Accordion
																	className="bg-transparent ml-5"
																	alwaysOpen
																>
																	<Card className="mb-2 bg-light ">
																		<Card.Body>
																			{shipmentList.map((order, i) => {
																				return (
																					<Accordion.Item key={i} eventKey={i}>
																						<Accordion.Header className="table-container shipmentList">
																							<Button
																								onClick={() => handleDelete(i)}
																								className="mx-2"
																							>
																								<BsTrash> </BsTrash>
																							</Button>
																							{'  | Envio # ' + Number(i + 1)}
																						</Accordion.Header>

																						<Accordion.Body className="p-0 m-2">
																							<Form
																								onSubmit={(e) =>
																									e.preventDefault()
																								}
																							>
																								<Row>
																									<Col xs={11} md={12}>
																										<Form.Group
																											className="mb-3"
																											controlid="name"
																										>
																											<Form.Label>
                                                      Cuenta
																											</Form.Label>
																											<Form.Control
																												type="text"
																												required
																												disabled
																												value={
																													order.account
																														? order.account.name
																														: null
																												}
																											></Form.Control>
																										</Form.Group>
																									</Col>

																									<Col>
																										<Form.Group
																											className="mb-3"
																											controlid="name"
																										>
																											<Form.Label>
                                                      Tipo de Envio
																											</Form.Label>
																											<Form.Control
																												type="text"
																												required
																												disabled
																												value={
																													order.type
																														? getTypeName(
																															order.type
																														)
																														: null
																												}
																											></Form.Control>
																										</Form.Group>
																									</Col>
																									<Col>
																										<Form.Group
																											className="mb-3"
																											controlid="amount"
																										>
																											<Form.Label>
                                                      Monto Total
																											</Form.Label>
																											<Form.Control
																												type="number"
																												required
																												disabled
																												value={Math.round(
																													order.price
																												)}
																											></Form.Control>
																										</Form.Group>
																									</Col>
																								</Row>

																								<Row>
																									<Col>
																										<Form.Group
																											className="mb-3"
																											controlid="balance"
																										>
																											<Form.Label>
                                                      Fecha Solicitada
																											</Form.Label>
																											<Form.Control
																												type="text"
																												required
																												disabled
																												value={
																													order
																														? order.shipmentDate
																															? moment(
																																new Date(
																																	order.shipmentDate
																																)
																															).format(
																																'DD/MM/YY'
																															)
																															: 'Antes posible'
																														: null
																												}
																											></Form.Control>
																										</Form.Group>
																									</Col>

																									<Col>
																										<Form.Group
																											className="mb-3"
																											controlid="name"
																										>
																											<Form.Label>
                                                      Zona de Destino
																											</Form.Label>
																											<Form.Control
																												type="text"
																												required
																												disabled
																												value={order.zoneName}
																											></Form.Control>
																										</Form.Group>
																									</Col>
																								</Row>

																								{order.originAddress ? (
																									<React.Fragment>
																										<Row>
																											<Form.Group
																												className="mb-3"
																												controlid="name"
																											>
																												<Form.Label>
                                                        Direccion de Origen
																												</Form.Label>
																												<Form.Control
																													type="text"
																													required
																													disabled
																													value={
																														order.originAddress
																															.address
																													}
																												></Form.Control>
																											</Form.Group>
																										</Row>

																										<Row>
																											<Form.Group
																												className="mb-3"
																												controlid="name"
																											>
																												<Form.Label>
                                                        Direccion de Destino
																												</Form.Label>
																												<Form.Control
																													type="text"
																													required
																													disabled
																													value={
																														order.destinantionAddress
																															? order
																																.destinantionAddress
																																.address
																															: null
																													}
																												></Form.Control>
																											</Form.Group>
																										</Row>

																										{order.additionalDetails ? (
																											<>
																												<Row>
																													<Form.Group className="col-md-11 mb-3">
																														<Form.Label>
                                                            Nombre cliente
																														</Form.Label>
																														<Form.Control
																															type="text"
																															required
																															disabled
																															value={
																																order
																																	.additionalDetails
																																	.clientName
																															}
																														></Form.Control>
																													</Form.Group>
																												</Row>
																												<Row>
																													<Form.Group
																														className="col-md-11 mb-3"
																														controlid="name"
																													>
																														<Form.Label>
                                                            Teléfono de Contacto{' '}
																														</Form.Label>
																														<Form.Control
																															type="text"
																															required
																															disabled
																															value={
																																order
																																	.additionalDetails
																																	.clientPhone
																															}
																														></Form.Control>
																													</Form.Group>
																													<Form.Group className="col-md-11 mb-3">
																														<Form.Label>
                                                            Cantidad de bultos{' '}
																														</Form.Label>
																														<Form.Control
																															type="text"
																															required
																															disabled
																															value={
																																order
																																	.additionalDetails
																																	.itemQuantity
																															}
																														></Form.Control>
																													</Form.Group>
																												</Row>
																												<Row>
																													<Form.Group
																														className="mb-3 col-md-10"
																														controlId="order-comments"
																													>
																														<Form.Label>
                                                            Comentarios{' '}
																														</Form.Label>
																														<Form.Control
																															as="textarea"
																															type="text"
																															required
																															disabled
																															value={
																																order
																																	.additionalDetails
																																	.comments
																															}
																														></Form.Control>
																													</Form.Group>
																												</Row>
																											</>
																										) : null}
																									</React.Fragment>
																								) : null}
																							</Form>
																						</Accordion.Body>
																					</Accordion.Item>
																				);
																			})}
																		</Card.Body>
																		<Card.Footer>
																			{shipmentList.length > 0 ? (
																				<React.Fragment>
																					<Stack direction="horizontal">
																						<Button
																							className="btn m-1 btnNewShipment"
																							id="createShipments"
																							onClick={() => createShipments()}
																						>
                                            Crear Envios
																						</Button>
																					</Stack>
																				</React.Fragment>
																			) : null}
																		</Card.Footer>
																	</Card>
																</Accordion>
															)}
														</Container>
													</Col>
												</Row>
											)}
										<div className="borderLine"></div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NewShipment;
