import axios from 'axios';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import React, {
	useContext,
	useLayoutEffect,
	useReducer,
	useState,
} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import LoadingBox from '../components/LoadingBox';
import { getStatus } from '../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_TEXT_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_TEXT_SUCCESS':
		return { ...state, text: action.payload, loading: false };
	case 'FETCH_TEXT_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

const ShippingPageEditor = () => {
	const navigate = useNavigate();
	const [{loading}, dispatch] = useReducer(reducer, {loading:true});

	const { state: ctxState, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = ctxState;
	const [editorState, setEditorState] = useState(null);
	const [convertedContent, setConvertedContent] = useState(null);
	const [htmlText, setHtmlText] = useState();

	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/texts/envios', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				if (!data.htmlText) {
					setHtmlText('<div></div>');
				} else {
					setHtmlText(data.htmlText);
				}
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(error.message);
				}
			}
		};
		fetchData();
	}, []);
	useLayoutEffect(() => {
		if (htmlText && convertedContent == null) {
			const content = stateFromHTML(htmlText);
			setEditorState(() => EditorState.createWithContent(content));
		}
	}, [htmlText]);

	//inline styles
	let options = {
		inlineStyleFn: (styles) => {
			let key = 'color-';
			let color = styles.filter((value) => value.startsWith(key)).first();

			if (color) {
				return {
					element: 'span',
					style: {
						color: color.replace(key, ''),
					},
				};
			}
		},
	};

	const convertToHtml = () => {
		const convertedContentOne = stateToHTML(
			editorState.getCurrentContent(),
			options
		);
		setConvertedContent(convertedContentOne);
		setHtmlText(convertedContent);
	};

	const handleEditorChange = (state) => {
		setEditorState(state);
		convertToHtml();
	};

	const saveHtml = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await axios.put(
				'/api/texts/modifyEnvios',
				{
					htmlText,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'UPDATE_SUCCESS', payload: data });
			toast.success('html actualizado Correctamente');
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	};

	return (
		<div className="richText">
			{loading && <LoadingBox></LoadingBox>}
			<div className="container  ps-0 mt-2">
				{editorState && (
					<Editor
						editorState={editorState}
						onEditorStateChange={handleEditorChange}
						wrapperClassName="wrapper-class"
						editorClassName="editor-class"
						toolbarClassName="toolbar-class"
					/>
				)}

				<Row>
					<Col>
						<Button md={2} onClick={saveHtml}>
              Actualizar
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
};
export default ShippingPageEditor;
