import React from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { FaCogs } from 'react-icons/fa';
import { FaWindowClose } from 'react-icons/fa';
// import PropTypes from 'prop-types';

const FilterForm = ({
	filterOptions,
	handleInputChange,
	clearFilter,
	handleApplyFilter,
	isOpen,
	handleDropdownToggle,
	ZoneNamesList,
	vehiclesNamesList,
	accounts,
	zoneListFetched,
}) => {
	const handleSubmit = (e) => {
		e.preventDefault();
		handleApplyFilter(filterOptions.formType);
	};
	const statList = ['Confirmado', 'En Camino', 'Cancelado', 'Entregado', 'Ingresado', 'Asignado'];
	const zones = ZoneNamesList;
	const vehicles = vehiclesNamesList;
	const accountsList = accounts;
	const { pathname } = useLocation();

	return (
		<div className={pathname.includes('/reports') ? '' : 'sidebar-dropdown'}>
			{!pathname.includes('/reports') ? (
				<Row>
					<Col className='d-flex justify-content-end'>
						<Button className='btn  m-2' onClick={handleDropdownToggle} aria-expanded={isOpen}>
							<FaWindowClose />
						</Button>
					</Col>
				</Row>
			) : (
				''
			)}
			<Form onSubmit={handleSubmit}>
				{filterOptions.formType === 'Envios por Zona' ? (
					<>
						{!pathname.includes('/reports') ? (
							<h4 className='text-center p-2'>
								<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
							</h4>
						) : (
							''
						)}
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={1} size='sm'>
        Cuenta:
							</Form.Label>
							<Col md={3}>
								<Form.Select
									name='account'
									value={filterOptions.account}
									onChange={handleInputChange}
									size='sm'
								>
									<option value=''>Seleccione una Cuenta</option>
									{accountsList
										? accountsList.map((account) => (
											<option key={account._id} value={account._id}>
												{account.name}
											</option>
										))
										: ''}
								</Form.Select>
							</Col>
							<Form.Label column md={1} size='sm'>
        Estado:
							</Form.Label>
							<Col md={3}>
								<Form.Select
									name='status'
									value={filterOptions.status}
									onChange={handleInputChange}
									size='sm'
								>
									<option value=''>Seleccione un Estado</option>
									{statList.map((status) => (
										<option key={status} value={status}>
											{status}
										</option>
									))}
								</Form.Select>
							</Col>
							<Form.Label column md={1} size='sm'>
        Zona:
							</Form.Label>
							<Col md={3}>
								<Form.Select
									name='zone'
									value={filterOptions.zone}
									onChange={handleInputChange}
									size='sm'
									disabled={!filterOptions.account && !zoneListFetched}
								>
									<option value=''>Seleccione una Zona</option>
									{zones
										? JSON.parse(zones).map((zone) => (
											<option key={zone._id} value={zone._id}>
												{zone.name}
											</option>
										))
										: ''}
								</Form.Select>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
        Fecha de Envío:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateFrom'
									size='sm'
									value={filterOptions.dateFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateTo'
									size='sm'
									value={filterOptions.dateTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
					</>
				) : (
					filterOptions.formType === 'Envios por Movil' && (
						<>
							{!pathname.includes('/reports') ? (
								<h4 className='text-center p-2'>
									<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
								</h4>
							) : (
								''
							)}
							<Form.Group as={Row} className='mb-3 align-items-center'>
								<Form.Label column md={2} size='sm'>
         Fecha:
								</Form.Label>
								<Col md={5}>
									<Form.Control
										type='date'
										name='dateFrom'
										size='sm'
										value={filterOptions.dateFrom}
										onChange={handleInputChange}
									/>
								</Col>
								<Col md={5}>
									<Form.Control
										type='date'
										name='dateTo'
										size='sm'
										value={filterOptions.dateTo}
										onChange={handleInputChange}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='mb-3 align-items-center'>
								<Form.Label column md={1} size='sm'>
         Vehiculo:
								</Form.Label>
								<Col md={10}>
									<Form.Select
										name='vehicle'
										value={filterOptions.vehicle}
										onChange={handleInputChange}
										size='sm'
									>
										<option value=''>Seleccione un Vehiculo</option>
										{vehicles
											? JSON.parse(vehicles).map((vehicle) => (
												<option key={vehicle._id} value={vehicle._id}>
													{vehicle.name}
												</option>
											))
											: ''}
									</Form.Select>
								</Col>
							</Form.Group>
						</>
					)
				)}
				{!pathname.includes('/reports') ? (
					<Row className='justify-content-center mt-2 mb-2'>
						<Col className='d-flex justify-content-end'>
							<Button variant='secondary' size='sm' className='mr-2' onClick={clearFilter}>
        Limpiar Filtro
							</Button>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button variant='primary' size='sm' type='submit'>
        Aplicar Filtro
							</Button>
						</Col>
					</Row>
				) : (
					<Row className='d-flex justify-content-end align-items-center mt-3'>
						<Col className='d-flex justify-content-end col-10'>
							<Button variant='primary' size='lg' type='submit'>
        Generar Reporte
							</Button>
						</Col>
						<Col className='d-flex justify-content-start col-2'>
							<Button variant='secondary' size='sm' className='mr-2' onClick={clearFilter}>
        Limpiar Filtro
							</Button>
						</Col>
					</Row>
				)}
			</Form>
		</div>
	);
};
// TODO: fix prop-type error => define component parameters
// FilterForm.PropTypes = {
// 	filterOptions: PropTypes.string,
// 	handleInputChange: PropTypes.string,
// 	clearFilter: PropTypes.string,
// 	handleApplyFilter: PropTypes.string,
// 	isOpen: PropTypes.string,
// 	handleDropdownToggle: PropTypes.string,
// 	ZoneNamesList: PropTypes.string,
// 	vehiclesNamesList: PropTypes.string,
// 	accounts: PropTypes.string,
// 	zoneListFetched,
// };

export default FilterForm;
