import React, { useState, useRef } from 'react';
import LoadingTableBox from '../components/LoadingTableBox';
import MessageBox from '../components/MessageBox';
import { Row, Col, Table, Form, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { showCurrency } from '../utils';
import { downloadExcel } from 'react-export-table-to-excel';
import { FaFileExcel } from 'react-icons/fa';

const DynamicTable = ({ data, loading, error, dataName, allData }) => {
	const tableRef = useRef(null);
	const [show, setShow] = useState(false);
	const [selectedFields, setSelectedFields] = useState(['_id']);
	const [exportName, setExportName] = useState('');
	const todayDate = moment().format('DD-MM-YYYY');

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	//columnMap to show table
	const columnMap = {
		shipments: {
			deliveredDate: 'Fecha de Envio',
			'account.name': 'Cuenta',
			status: 'Estado',
			'route.destinationAddress.zone.name': 'Zona de entrega',
		},
		dailyRoutes: {
			date: 'fecha',
			'vehicle.registrationNumber': 'Matricula',
			'vehicle.brand': 'Marca',
			'vehicle.model': 'Modelo',
			timeMinutes: 'Tiempo en Minutos',
			distanceMeters: 'Distancia en Metros',
		},
	};
	//columnMap to export table
	const columnMapToExport = {
		shipments: {
			_id: 'ID',
			deliveredDate: 'Fecha de Envio',
			'account.name': 'Cuenta',
			status: 'Estado',
			'route.destinationAddress.zone.name': 'Zona de entrega',
		},
		dailyRoutes: {
			_id: 'ID',
			date: 'fecha',
			'vehicle.registrationNumber': 'Matricula',
			'vehicle.brand': 'Marca',
			'vehicle.model': 'Modelo',
			timeMinutes: 'Tiempo en Minutos',
			distanceMeters: 'Distancia en Metros',
		},
	};

	const columnsToExport = Object.keys(columnMapToExport[dataName] || {});
	const columns = Object.keys(columnMap[dataName] || {});

	//to show with currency UYU:USD
	const currencyColumns = ['amount', 'capital'];

	//to show values like owner.name
	function getNestedValue(item, column) {
		const keys = column.split('.');
		let nestedValue = item;
		for (const key of keys) {
			if (nestedValue && nestedValue[key] !== 'undefined') {
				nestedValue = nestedValue[key];
			} else {
				return null;
			}
		}
		return nestedValue;
	}

	//to show date in format (DD-MM-YYYY)
	const formatDate = (value) => {
		const isValidDateFormat = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
		if (isValidDateFormat) {
			return moment(value).format('DD-MM-YYYY');
		} else {
			return value;
		}
	};

	//download xls with selected fields
	const handleDownloadExcel = () => {
		const selectedColumnMap = columnMapToExport[dataName];
		const header = selectedFields.map((field) => selectedColumnMap[field]);
		const body = allData.map((item) =>
			selectedFields.map((field) =>
				currencyColumns.includes(field)
					? showCurrency(getNestedValue(item, 'currency'), getNestedValue(item, field), dataName) +
       ' ' +
       getNestedValue(item, field)
					: formatDate(getNestedValue(item, field)),
			),
		);
		downloadExcel({
			fileName: exportName ? `${exportName}` : `${todayDate}- ${dataName}`,
			sheet: 'react-export-table-to-excel',
			tablePayload: {
				header,
				body,
			},
		});
		handleClose();
	};

	//show two colums of checkboxes in modal
	const columnsPerRow = 2;

	const rows = Array.from(
		{ length: Math.ceil(columnsToExport.length / columnsPerRow) },
		(_, rowIndex) => columnsToExport.slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow),
	);

	return (
		<div className='container m-3'>
			<Row>
				<Col>
					<Button size={'sm'} className='mb-3' onClick={handleShow} style={{ backgroundColor: 'Green' }}>
						<FaFileExcel></FaFileExcel> Exportar
					</Button>
				</Col>
			</Row>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Exportar datos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} className='mb-3 align-items-center'>
						<Form.Label column md={6}>
       Nombre del archivo:
						</Form.Label>
						<Col>
							<Form.Control
								type='text'
								placeholder='Ingrese el nombre'
								value={exportName}
								onChange={(e) => setExportName(e.target.value)}
							/>
						</Col>
					</Form.Group>
					<>
						<p>Marque los campos que desea incluir</p>
						{rows.map((row, rowIndex) => (
							<Row key={rowIndex}>
								{row.map((column) => (
									<Col key={column} md={6}>
										<Form.Group
											as={Row}
											className='mb-3 align-items-center'
											controlId={`fieldsToInclude_${column}`}
										>
											<Form.Check
												className='col-8'
												type='checkbox'
												value={column}
												label={columnMapToExport[dataName][column]}
												defaultChecked={column === '_id'}
												disabled={column === '_id'}
												onChange={(e) => {
													const selected = e.target.checked;
													setSelectedFields((prevSelectedFields) =>
														selected
															? [...prevSelectedFields, column]
															: prevSelectedFields.filter((field) => field !== column),
													);
												}}
											/>
										</Form.Group>
									</Col>
								))}
							</Row>
						))}
					</>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
      Cancelar
					</Button>
					<Button variant='primary' onClick={handleDownloadExcel}>
      Exportar XLS
					</Button>
				</Modal.Footer>
			</Modal>
			<Row>
				<p>Total Encontrados:{allData.length}</p>
			</Row>
			<Table bordered hover responsive size='sm' ref={tableRef}>
				<thead>
					<tr>
						{columns.map((column) => (
							<th key={column}>{columnMap[dataName][column]}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan={columns.length}>
								<LoadingTableBox loading={loading} />
							</td>
						</tr>
					) : error ? (
						<tr>
							<td colSpan={columns.length}>
								<MessageBox variant='danger'>{error}</MessageBox>
							</td>
						</tr>
					) : !data ? (
						<tr colSpan={columns.length}>
							<td colSpan={columns.length}>
								<MessageBox variant='light'>No hay resultados...</MessageBox>
							</td>
						</tr>
					) : (
						data.map((item) => (
							<tr key={item}>
								{columns.map((column) => (
									<td key={column}>
										{currencyColumns.includes(column)
											? showCurrency(
												getNestedValue(item, 'currency'),
												getNestedValue(item, column),
												dataName,
											) +
             ' ' +
             getNestedValue(item, column)
											: formatDate(getNestedValue(item, column))}
									</td>
								))}
							</tr>
						))
					)}
				</tbody>
			</Table>
		</div>
	);
};

export default DynamicTable;
