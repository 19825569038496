import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
	Card,
	Form,
	Table
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingBox from '../LoadingBox';
import './TablePendingShipments.css';

const TablePendingShipments = ({
	pendingShipments,
	onUpdateVehicle,
	onSelectedShipmentsChange,
	vehicles,
}) => {

	const [selectedShipments, setSelectedShipments] = useState([]);
	const [listedShipments, setListedShipments] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleSelectAll = () => {
		if (
			selectedShipments &&
      selectedShipments.length === listedShipments.length
		) {
			// If all shipments are already selected, deselect all
			setSelectedShipments([]);
		} else {
			// If not all shipments are selected, select all
			setSelectedShipments(listedShipments);
		}
	};

	// Function to handle shipment selection
	const handleShipmentSelect = (selectedShipment) => {
		// Check if the shipment is already selected
		const isSelected = selectedShipments.includes(selectedShipment);

		if (isSelected) {
			// If selected, remove it from the array
			setSelectedShipments(
				selectedShipments.filter(
					(shipment) => shipment._id !== selectedShipment._id
				)
			);
		} else {
			// If not selected, add it to the array
			setSelectedShipments([...selectedShipments, selectedShipment]);
		}
	};

	const changeVehicle = async (vehicleRegNum, editableShipment) => {
		
		const newVehicle = vehicles.find(
			(vehicle) => vehicleRegNum === vehicle.registrationNumber
		);
		const shipmentIndex = listedShipments.findIndex(
			(shipment) => shipment._id === editableShipment._id
		  );

		  
		if(newVehicle){
	
			
			editableShipment.vehicle = newVehicle;
			editableShipment.checkable = true;
		}else{
			editableShipment.checkable = false;
		}

		if (shipmentIndex !== -1) {
			const updatedPendingShipments = [...listedShipments];
			updatedPendingShipments[shipmentIndex] = editableShipment;
			
			setListedShipments(updatedPendingShipments);
		  }

		if (selectedShipments.includes(editableShipment)) {
			editableShipment.vehicle = newVehicle;
		}
	

		await onUpdateVehicle(editableShipment, newVehicle);


	};


	// Notify the parent component when selected shipments change
	useEffect(() => {
		const loadData = async() => {
			setLoading(true);
			setListedShipments(pendingShipments);
			await onSelectedShipmentsChange(selectedShipments);
			setLoading(false);
		};
		loadData();
	}, [selectedShipments, onSelectedShipmentsChange]);

	return (
		<div>
			<Card>
				<div className="tableShipments-container">
					<Table responsive>
						{loading && <LoadingBox></LoadingBox>}

						<thead>
							<tr>
								<th>
									<input
										type="checkbox"
										checked={
											selectedShipments &&
                      listedShipments &&
                      selectedShipments.length === listedShipments.length
										}
										onChange={handleSelectAll}
										disabled={
											listedShipments &&
											!listedShipments.every((shipment) => shipment.checkable === true)
										  }
									/>
								</th>
								<th>Account</th>
								<th>Shipment Date</th>
								<th>Order Date</th>
								<th>Vehicle</th>
								{/* Add other table headers */}
							</tr>
						</thead>
						<tbody>
							{listedShipments.map((shipment) => {
								return shipment ? (
									<tr key={shipment._id}>
										<td>
											<input
												type="checkbox"
												checked={selectedShipments.includes(shipment)}
												onChange={() => handleShipmentSelect(shipment)}
												disabled={!shipment.checkable}

											/>
										</td>
										<td>
											<Link
												to={`/AdminScreen/shipments/details/${shipment._id}`}
											>
												{shipment.account ? shipment.account.name : null}
											</Link>
										</td>
										<td>
											{shipment.shipmentDate
												? moment(new Date(shipment.shipmentDate)).format(
													'DD/MM/YY'
												)
												: 'Antes Posible'}
										</td>
										<td>
											{shipment.orderDate
												? moment(new Date(shipment.orderDate)).format(
													'DD/MM/YY'
												)
												: null}
										</td>
										<>
																			
											<td>
												<Form.Select
													value={
														shipment.vehicle ? (vehicles.map((v)=> v._id).includes(shipment.vehicle._id)
															? shipment.vehicle.registrationNumber
															: 'Sin Asignar') : 'Sin Asignar'
													}
													className="border border-2 rounded mb-0"
													onChange={async (e) => {
														changeVehicle(e.target.value, shipment);
									
													}}
												>
													<option key={null} value={''}>
                              Sin Asignar
													</option>
													{vehicles.map((vehicle) => (
														<option
															key={vehicle.registrationNumber}
															value={vehicle.registrationNumber}
														>
															{vehicle.brand +
                                  ' ' +
                                  vehicle.model +
                                  ' - ' +
                                  vehicle.registrationNumber}
														</option>
													))}
												</Form.Select>
											</td>
										
										</>
										
									</tr>
								) : null;
							})}
						</tbody>
					</Table>
				</div>
			</Card>
			{/* Add buttons or actions for selected items */}
		</div>
	);
};

export default TablePendingShipments;
