import axios from 'axios';
import React, {
	useContext,
	useLayoutEffect,
	useReducer
} from 'react';
import { BsFacebook, BsInstagram, BsWhatsapp } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css';
import { Store } from '../Store';

const Footerr = () => {
	const reducer = (state, action) => {
		switch (action.type) {
		case 'FETCH_COMPANY_REQUEST':
			return { ...state, loading: true };
		case 'FETCH_COMPANY_SUCCESS':
			return { ...state, GotaSettings: action.payload, loading: false };
		case 'FETCH_COMPANY_FAIL':
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
		}
	};
	const [{ GotaSettings }, dispatch] = useReducer(reducer, {
		GotaSettings: {},
		loading: true,
		error: '',
	});

	const { state } = useContext(Store);
	const { userInfo } = state;


	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_COMPANY_REQUEST' });
				const { data } = await axios.get('/api/config/public');

				dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
			}
		};
		fetchData();
	}, []);

	const location = useLocation();
	const route = location.pathname;
	if (route.includes('AdminScreen')) return null;

	return (
		<footer className="footer-bs ">
			{GotaSettings && GotaSettings.image && GotaSettings.image.fileLink && (

				<div className="row">
					<div className="col-12 footerbrand-img col-lg-1">
						<img src={GotaSettings && GotaSettings.image && GotaSettings.image.fileLink ? GotaSettings.image.fileLink : null} alt={`${GotaSettings.companyName} Logo`} />
					</div>
					<div className="col-12 footerbrand col-lg-3">
						<h5>{GotaSettings.companyName}</h5>
						<p className="footerbrand-sub">{GotaSettings.companysubName}</p>
						<p className="footerbrand-info">
							{GotaSettings.companyAddress}
							<br></br>
							{GotaSettings.companyCity}
							<br></br>Tel:
							{GotaSettings.companyPhone}
						</p>
					</div>
					{/* <div className="col-lg-2 col-12">
						<div className="row">
							<p className="footer-sub">Nosotros</p>
							<ul>
								<li>
									{' '}
									<Link className="nav-link" to="/Contacto">
                  Contacto
									</Link>
								</li>
								<li>
									<Link className="nav-link" to="/Envios">
                  Politica de Envios
									</Link>
								</li>
							</ul>
						</div>
					</div> */}

					<div className="col-lg-2 col-12">
						<div className="row">
							{/* <p className="footer-sub">Links Utiles</p> */}
							<ul>
								<ul className="socialNavbarLinks-top social-links mb-2">

									<li>
										{' '}
										<Link className="nav-link" to="/">
                  Inicio
										</Link>
									</li>
								</ul>

								<ul className="socialNavbarLinks-top social-links mb-2">
									<li>
										{' '}
										<a className="nav-link" href={GotaSettings.facebookUrl}>
											<BsFacebook color="var(--IconsColor)" fontSize="1.5rem" />
										</a>
									</li>
									<li>
										{' '}
										<a className="nav-link" href={GotaSettings.instagramUrl}>
											<BsInstagram color="var(--IconsColor)" fontSize="1.5rem" />
										</a>
									</li>
									<li>
										<a
											className="nav-link"
											target="_blank"
											rel="noopener noreferrer"
											href={GotaSettings.whatsappUrl}
										>
											<BsWhatsapp color="var(--IconsColor)" fontSize="1.5rem" />
										</a>
									</li>
								</ul>
							</ul>
						</div>
					</div>
				</div>
			)}
			{userInfo && !userInfo.isAdmin ? (
				<a
					href={GotaSettings.whatsappUrl}
					className="whatsapp_float"
					target="_blank"
					rel="noopener noreferrer"
				>
					<BsWhatsapp color="white" />
				</a>
			) : null}
		</footer>
	);
};

export default Footerr;
