import axios from 'axios';
import React, {
	useContext,
	useLayoutEffect,
	useReducer,
	useState,
} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import { getError, getStatus } from '../../utils';
import LoadingBox from '../LoadingBox';
import './ColorConfig.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, colors: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

const ColorConfig = () => {
	const navigate = useNavigate();
	const [{loading}, dispatch] = useReducer(reducer, {
		colors: {},
		loading: true,
		error: '',
	});

	const { state: ctxState, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = ctxState;
	const [primaryColor, setPrimaryColor] = useState();
	const [shadowNavColor, setShadowNavColor] = useState();
	const [secondaryColor, setSecondaryColor] = useState();
	const [backgroundColor, setBackgroundColor] = useState();
	const [utilsColor, setUtilsColor] = useState();
	const [navFontColor, setNavFontColor] = useState();
	const [iconsColor, setIconsColor] = useState();
	const [titlesColor, setTitlesColor] = useState();
	const [btnFontColor, setBtnFontColor] = useState();

	//getting theme colors-to do: load before page

	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/colors');
				setPrimaryColor(data.primaryColor);
				setShadowNavColor(data.shadowNavColor);
				setSecondaryColor(data.secondaryColor);
				setBackgroundColor(data.backgroundColor);
				setUtilsColor(data.utilsColor);
				setNavFontColor(data.navFontColor);
				setIconsColor(data.iconsColor);
				setTitlesColor(data.titlesColor);
				setBtnFontColor(data.btnFontColor);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	function ColorsPreview() {
		document.documentElement.style.setProperty('--primaryColor', primaryColor);
		document.documentElement.style.setProperty(
			'--shadowNavColor',
			shadowNavColor
		);
		document.documentElement.style.setProperty(
			'--secondaryColor',
			secondaryColor
		);
		document.documentElement.style.setProperty(
			'--background-color',
			backgroundColor
		);
		document.documentElement.style.setProperty('--utilsColor', utilsColor);
		document.documentElement.style.setProperty('--navFontColor', navFontColor);
		document.documentElement.style.setProperty('--titlesColor', titlesColor);
		document.documentElement.style.setProperty('--btnFontColor', btnFontColor);
	}

	const setColors = async () => {
		ColorsPreview();
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await axios.put(
				'/api/colors',
				{
					primaryColor,
					shadowNavColor,
					secondaryColor,
					backgroundColor,
					utilsColor,
					navFontColor,
					iconsColor,
					titlesColor,
					btnFontColor,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'UPDATE_SUCCESS', payload: data });
			toast.success('Tema actualizado Correctamente');
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
			}
		}
	};

	return (
		<div className="color-config">
			{loading && <LoadingBox></LoadingBox>}

			<div className="container  ps-0 mt-2">
				<Form onChange={ColorsPreview()}>
					<Row>
						<Col>
							<Form.Label>Color Principal</Form.Label>
						</Col>
						<Col>
							<InputGroup controlId="primaryColor">
								<Form.Control
									Value={primaryColor}
									type="color"
									onChange={(e) => setPrimaryColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Sombreado NavBar</Form.Label>
						</Col>
						<Col>
							<InputGroup controlId="shadowColor">
								<Form.Control
									type="color"
									value={shadowNavColor}
									onChange={(e) => setShadowNavColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color Secundario</Form.Label>
						</Col>
						<Col>
							<InputGroup controlId="secondaryColor">
								<Form.Control
									type="color"
									value={secondaryColor}
									onChange={(e) => setSecondaryColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color Fondo</Form.Label>
						</Col>
						<Col>
							<InputGroup controlId="backgroundColor">
								<Form.Control
									type="color"
									value={backgroundColor}
									onChange={(e) => setBackgroundColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color utilidades</Form.Label>
						</Col>
						<Col>
							<InputGroup controlId="utilsColor">
								<Form.Control
									type="color"
									value={utilsColor}
									onChange={(e) => setUtilsColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color de Fuente</Form.Label>
						</Col>
						<Col>
							<InputGroup controlId="fontColor">
								<Form.Control
									type="color"
									value={navFontColor}
									onChange={(e) => setNavFontColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color de Iconos</Form.Label>
						</Col>
						<Col>
							<InputGroup controlId="iconsColor">
								<Form.Control
									type="color"
									value={iconsColor}
									onChange={(e) => setIconsColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color de Titulos</Form.Label>
						</Col>
						<Col>
							<InputGroup controlId="titlesColor">
								<Form.Control
									type="color"
									value={titlesColor}
									onChange={(e) => setTitlesColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Color de fuente Botones</Form.Label>
						</Col>
						<Col>
							<InputGroup controlId="btnFontColor">
								<Form.Control
									type="color"
									value={btnFontColor}
									onChange={(e) => setBtnFontColor(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button md={2} onClick={setColors}>
                Actualizar
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};
export default ColorConfig;
