import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Accordion,
	Button,
	Card,
	Col,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { BsEye, BsEyeSlash, BsPencilSquare, BsTrash } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store.js';
import LoadingBox from '../../components/LoadingBox';
import { getStatus } from '../../utils';
import './AccordionRoutes.css';
import './GenerateRoutes.css';
import './TablePendingShipments.css';

// ...
const reducer = (state, action) => {
	switch (action.type) {
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };
	}
};

const AccordionRoutes = ({
	routesArray,
	toggleVisibility,
	isList,
	onRouteToEdit,
}) => {
	const [{ loadingDelete }, dispatch] = useReducer(
		reducer,
		{
			loadingDelete: false,
			error: '',
			successDelete: false,
		}
	);


	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });

	const [checkedRoute, setChekedRoute] = useState(routesArray.map(() => true));

	//MODAL
	const [routeToDelete, setRouteToDelete] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		setChekedRoute(routesArray.map(() => true));
	}, [routesArray]);

	const updateChecked = async (i) => {
		const updatedCheckedRoute = [...checkedRoute];
		updatedCheckedRoute[i] = !updatedCheckedRoute[i];
		 setChekedRoute(updatedCheckedRoute);
		await toggleVisibility(i);
	};

	async function handleDelete(route) {
		 setRouteToDelete(route);
		handleShow();
	}

	async function handleEditRoute(route, i) {
		onRouteToEdit(route, i);
	}

	async function deleteRouteHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.delete(
				`/api/dailyRoutes/byId/${routeToDelete._id}`,
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({ type: 'DELETE_SUCCESS' });
			toast.success('Ruta Eliminada');
			handleClose();
			window.location.reload();
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	}

	return (
		<div>
			{loadingDelete && <LoadingBox/>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Ruta</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar la ruta?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deleteRouteHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<Accordion className="bg-transparent ml-1" alwaysOpen>
				<Card className="mb-2 bg-light ">
					<Card.Body>
						{routesArray.length > 0 ? (
							routesArray.map((route, i) => {
								return (
									<Row key={i}>
										<Col
											xs={isSmallScreen ? 12 : 10}
											md={isSmallScreen ? 12 : 10}
											className="m-0"
										>
											<Accordion.Item eventKey={i} xs={isSmallScreen ? 12 : 10}>
												<Accordion.Header
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'space-between',
														justifyItems: 'center',
													}}
												>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															width: '100%',
															alignItems: 'center',
														}}
													>
														<label>
															<input
																type="checkbox"
																checked={checkedRoute[i]}
																onChange={() => updateChecked(i)}
																style={{ display: 'none' }}
															/>
															{checkedRoute[i] ? (
																<BsEye
																	style={{
																		marginLeft: '4',
																		fontSize: '18px',
																		cursor: 'pointer',
																		color: 'grey',
																	}}
																/>
															) : (
																<BsEyeSlash
																	style={{
																		marginLeft: '4',
																		color: 'grey',
																		fontSize: '18px',
																		cursor: 'pointer',
																	}}
																/>
															)}

															<span
																className="mx-2"
																style={{
																	color: isList
																		? 'black'
																		: route.vehicle && route.vehicle.color
																			? `${route.vehicle.color}`
																			: '#419de8',
																}}
															>
																{'  | Ruta # ' + Number(i + 1)}
															</span>
															{'      '}
														</label>
													</div>
												</Accordion.Header>

												<Accordion.Body className="p-0 m-2 scrollable-item">
													<Row>
														<Col xs={11} md={12}>
															<Form.Group className="mb-1" controlid="name">
																<Form.Label>Vehiculo</Form.Label>
																{isList ? (
																	<Form.Control
																		type="text"
																		className='mb-2'
																		required
																		disabled
																		value={
																			route &&
                                      route &&
                                      route.vehicle &&
                                      route.vehicle
                                      	.registrationNumber
																				? route.vehicle.brand +
                                          ' ' +
                                          route.vehicle.model +
                                          ' - ' +
                                          route.vehicle
                                          	.registrationNumber
																				: 'Sin Asignar'
																		}
																	></Form.Control>
																) : (
																	<Form.Control
																		type="text"
																		className='mb-2'
																		required
																		disabled
																		value={
																			route.vehicle
																				? route.vehicle.brand +
                                          ' ' +
                                          route.vehicle.model +
                                          ' - ' +
                                          route.vehicle.registrationNumber
																				: 'Sin Asignar'
																		}
																	></Form.Control>
																)}
																<Form.Label>Delivery</Form.Label>
																{isList ? (
																	<Form.Control
																		type="text"
																		className='mb-2'
																		required
																		disabled
																		value={
																			route &&
                                    
                                      route.asignedDelivery ?
																				route.asignedDelivery
                                      	.name
																				: 'Sin Asignar'
																		}
																	></Form.Control>
																) : null}


																<Form.Label>Distancia</Form.Label>
																{isList ? (
																	<Form.Control
																		type="text"
																		className='mb-2'
																		required
																		disabled
																		value={
																			route && route
																				? route.totalDistance /
                                            1000 +
                                          ' kms'
																				: null
																		}
																	></Form.Control>
																) : (
																	<Form.Control
																		type="text"
																		required
																		disabled
																		value={
																			(route.totalDistance
																				? route.totalDistance / 1000
																				: route.distanceMeters / 1000) + ' kms'
																		}
																	></Form.Control>
																)}
															</Form.Group>
														</Col>
														<Col>
															<Form.Group className="mb-1" controlid="name">
																<Form.Label>Inicio:</Form.Label>
																{isList ? (
																	<Form.Control
																		type="text"
																		required
																		disabled
																		value={route ? (
																			route.vehicle &&
                                      route.vehicle.shiftStart
																				? moment(
																					new Date(
																						route.vehicle.shiftStart
																					)
																				)
																					.utc()
																					.format('hh:mm')
																				: null

																		) : (
																			route.vehicle &&
                                      route.vehicle.shiftStart
																				? moment(
																					new Date(
																						route.vehicle.shiftStart
																					)
																				)
																					.utc()
																					.format('hh:mm')
																				: null
																		)
																		}
																	></Form.Control>
																) : (
																	<Form.Control
																		type="text"
																		required
																		disabled
																		value={
																			route.vehicle
																				? route.vehicle.shiftStart
																					? moment(
																						new Date(route.vehicle.shiftStart)
																					)
																						.utc()
																						.format('hh:mm')
																					: null
																				: null
																		}
																	></Form.Control>
																)}
															</Form.Group>
														</Col>
														<>
															<Col>
																<Form.Group className="mb-1" controlid="amount">
																	<Form.Label>Fin:</Form.Label>
																	{isList ? (
																		<Form.Control
																			type="text"
																			required
																			disabled
																			value={
																				route &&
                                        route &&
                                        route.sortedOrderInfo
																					? moment(
																						new Date(
																							route.sortedOrderInfo[
																								route
																									.sortedOrderInfo.length - 1
																							].estimatedTime
																						)
																					)
																						.utc()
																						.format('hh:mm')
																					: null
																			}
																		></Form.Control>
																	) : (
																		<Form.Control
																			type="text"
																			required
																			disabled
																			value={
																				isList &&
                                        route.sortedOrderInfo
																					? moment(
																						new Date(
																							route.sortedOrderInfo[
																								route
																									.sortedOrderInfo.length - 1
																							].estimatedTime
																						)
																					)
																						.utc()
																						.format('hh:mm')
																					: route.sortedOrderInfo &&
                                              route.vehicle
																						? moment(
																							new Date(
																								route.sortedOrderInfo[
																									route.sortedOrderInfo
																										.length - 1
																								].estimatedTime
																							)
																						)
																							.utc()
																							.format('hh:mm')
																						: null
																			}
																		></Form.Control>
																	)}
																</Form.Group>
															</Col>
														</>
													</Row>
												</Accordion.Body>
											</Accordion.Item>
										</Col>
										{isList ? (
											<Col
												xs={1}
												md={1}
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Button onClick={() => handleDelete(route)}>
													<BsTrash style={{ color: '#f2716b' }} />
												</Button>
											</Col>
										) : (
											<Col
												xs={isSmallScreen ? 12 : 1}
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Button
													onClick={() => handleEditRoute(route, i)}
													className={isSmallScreen ? 'mt-1 mb-1' : 'm-0'}
												>
													<BsPencilSquare style={{ color: 'black' }} />{' '}
													{isSmallScreen ? 'Editar ' : null}
												</Button>
											</Col>
										)}
									</Row>
								);
							})
						) : (
							<div>
								{`No hay rutas para ${
									!userInfo.isSuperAdmin ? 'este vehiculo para ' : ' '
								} esta fecha`}
							</div>
						)}
					</Card.Body>
				</Card>
			</Accordion>
		</div>
	);
};

export default AccordionRoutes;
