import React, { useEffect, useState } from 'react';
import './PaymentMethodConfig.css';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { FaEye, FaTrash, FaPen } from 'react-icons/fa';
import BtnPlus from '../BtnPlus/BtnPlus';
import TextFloatingLabel from '../TextFloatingLabel/TextFloatingLabel';
import ConfirmDeleteMethodModal from './ConfirmDeleteMethodModal/ConfirmDeleteMethodModal';
import NewPaymentMethodModal from './NewPaymentMethodModal/NewPaymentMethodModal';

export default function PaymentMethodConfig() {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [selectedMethod, setSelectedMethod] = useState('');
	const [loadMethods, setLoadMethods] = useState(true);
	const [edit, setEdit] = useState(false);
	const [show, setShow] = useState(false);
	const [deleteShow, setDeleteShow] = useState(false);

	useEffect(() => {
		const fetchMethods = async () => {
			if (loadMethods) {
				try {
					const methods = await axios.get('/api/utils/paymentmethods');
					setPaymentMethods(methods.data);
				} catch (ex) {
					console.error(ex);
				}
				setLoadMethods(false);
			}
		};
		fetchMethods();
	}, [loadMethods]);
	const handleClose = () => {
		setShow(false);
		setDeleteShow(false);
		setEdit(false);
		setLoadMethods(true);
		setSelectedMethod('');
	};
	const handleOpen = () => setShow(true);
	const handleEdit = () => {
		setEdit(true);
		setShow(true);
	};
	const handleSelectDeletion = (pm) => {
		setSelectedMethod(pm);
		setDeleteShow(true);
	};
	return (
		<>
			<div id="payment-config">
				<NewPaymentMethodModal
					show={show}
					handleClose={handleClose}
					edit={edit}
					editMethod={selectedMethod}
				></NewPaymentMethodModal>
				<ConfirmDeleteMethodModal
					deleteShow={deleteShow}
					handleClose={handleClose}
					method={selectedMethod}
					onHide={() => setDeleteShow(false)}
				></ConfirmDeleteMethodModal>
				<Row>
					<Col xs={10}>
						<div className="d-block ms-1">
							<p id="label-boton" className="d-inline-block">
                Agregar nuevo metodo.
							</p>
							<BtnPlus
								className="col-1 d-inline-block mb-1 ms-2"
								id="new-method-btn"
								onClick={handleOpen}
							></BtnPlus>
						</div>
						<Row>
							<Col xs={10}>
								<ListGroup id="lista-metodos">
									{paymentMethods.length &&
                    paymentMethods.map((pm, index) => {
                    	return (
                    		<ListGroup.Item key={index} id={pm.id}>
                    			<Row className="justify-content-between px-2">
                    				<Col xs={8}>{pm.method}</Col>
                    				<Col
                    					xs={2}
                    					className="d-flex justify-content-around p-0"
                    				>
                    					<button
                    						onClick={() => setSelectedMethod(pm)}
                    						className="action-button"
                    					>
                    						<FaEye></FaEye>
                    					</button>
                    					<button
                    						onClick={() => handleSelectDeletion(pm)}
                    						className="action-button"
                    					>
                    						<FaTrash></FaTrash>
                    					</button>
                    				</Col>
                    			</Row>
                    		</ListGroup.Item>
                    	);
                    })}
								</ListGroup>
							</Col>
						</Row>
					</Col>
					{selectedMethod && (
						<Col xs={6}>
							<Row
								id="method-data"
								className=" justify-content-start align-items-center mb-1"
							>
								<TextFloatingLabel
									className="mb-2"
									label="Nombre del método"
									data={selectedMethod && selectedMethod.method}
									edit={selectedMethod && handleEdit}
								></TextFloatingLabel>
								<TextFloatingLabel
									edit={selectedMethod && handleEdit}
									label="Detalles"
									data={
										selectedMethod &&
                    (selectedMethod.details ? selectedMethod.details : '')
									}
								></TextFloatingLabel>
							</Row>
							<Row className="align-items-center">
								<span id="label-activo" className="col-1">
                  Activo
								</span>
								<Form.Check
									className="col-1 pe-0"
									checked={selectedMethod ? selectedMethod.active : false}
								></Form.Check>
								{selectedMethod && (
									<button
										id="pen-checkbox"
										className="pen-edit-method"
										onClick={handleEdit}
									>
										<FaPen></FaPen>
									</button>
								)}
							</Row>
						</Col>
					)}
				</Row>
			</div>
		</>
	);
}
