import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import '../../App.css';
import ClientCard from '../../components/Card';
import '../Screens.css';

const containerStyle = {
	width: '400px',
	height: '400px',
};

const center = {
	lat: -3.745,
	lng: -38.523,
};

function ShippingCalculator() {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'YOUR_API_KEY',
	});

	const onLoad = React.useCallback(function callback(map) {
		const bounds = new window.google.maps.LatLngBounds(center);
		map.fitBounds(bounds);
	}, []);

	return (
		<div>
			<Helmet>
				<title>Shipping Calculator</title>
			</Helmet>
			<Container fluid className="d-grid gap-4 m-4 p-4">
				{isLoaded ? (
					<GoogleMap
						mapContainerStyle={containerStyle}
						center={center}
						zoom={10}
						onLoad={onLoad}
					>
						<></>
					</GoogleMap>
				) : (
					<></>
				)}
			</Container>
			<Container fluid>
				<Row className="justify-content-center">
					<Col className="m-5">
						<ClientCard></ClientCard>
					</Col>
					<Col className="m-5">
						<ClientCard></ClientCard>
					</Col>
					<Col className="m-5">
						<ClientCard></ClientCard>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
export default ShippingCalculator;
