import axios from 'axios';
import classnames from 'classnames';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Dropdown,
	DropdownButton,
	Modal,
	Table,
} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { BsPencilSquare, BsPlusCircle, BsTrash } from 'react-icons/bs';
import { FaBuilding, FaTruck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, getStatus, maxitemsPerPage } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			accounts: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'EDIT_REQUEST':
		return { ...state, loading: true };
	case 'EDIT_FAIL':
		return { ...state, loading: false };
	case 'EDIT_SUCCESS':
		return {
			...state,
			loading: false,
		};
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AccountList() {
	const [{ loading, error, accounts, successDelete, itemQuantity }, dispatch] =
    useReducer(reducer, {
    	accounts: [],
    	loading: true,
    	error: '',
    	itemQuantity: 0,
    });

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//MODAL
	const [accountToDelete, setAccountToDelete] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = accounts.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = accounts.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/accounts', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(error.message);
				}
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function deleteAccountHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.delete(
				`/api/accounts/${accountToDelete._id}`,
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({ type: 'DELETE_SUCCESS' });
			toast.success('Cuenta Eliminada');
			handleClose();
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	}

	async function handleDelete(account) {
		 setAccountToDelete(account);
		handleShow();	}


	async function editAccountHandler(account) {
		navigate(`/AdminScreen/editAccount/${account._id}`);
	}

	async function detailsAccountHandler(account) {
		navigate(`/AdminScreen/accountDetails/${account._id}`);
	}

	async function newAccountHandler() {
		navigate('/AdminScreen/newAccount');
	}

	return (
		<div>
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Cuenta</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar la cuenta?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deleteAccountHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<Helmet>
				<title>Lista de Cuentas</title>
			</Helmet>

			{loading ? (
				<Row
					className="d-flex justify-content-center align-items-center spinner-row"
					style={{ minHeight: '100vh' }}
				>
					<LoadingBox className="col-1" />
				</Row>
			) : error ? (
				<MessageBox variant="danger">{error}</MessageBox>
			) : (
				<div
					className={classnames('container', {
						['admin-con']: userInfo && userInfo.isSuperAdmin,
					})}
				>
					<div className="w-100" style={{ overflow: 'visible' }}>
						<h3 className="text-right mt-3 mb-3" style={{ margin: '2.5% 0' }}>
							<FaBuilding /> Cuentas
						</h3>
						<div
							style={{
								display: 'flex',
								justifyContent: 'right',
								alignItems: 'center',
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Crear Cuenta"
								onClick={() => newAccountHandler()}
							>
								<span>
									<BsPlusCircle /> Crear Cuenta
								</span>
							</Button>
						</div>
						{loading ? (
							<LoadingBox></LoadingBox>
						) : error ? (
							<MessageBox variant="danger">{error}</MessageBox>
						) : (
							<Card>
								<Table responsive size="sm">
									<thead className="align-items-center table-order tHead">
										<th className="col-lg-2 tableHeader">Nombre</th>
										<th className="col-lg-1 tableHeader">Acciones</th>
									</thead>
									<tbody className="align-items-center table-order tableBodyHover">
										{currentPosts.map((account, i) => (
											<tr className="tHover" key={i}>
												<td
													className="col-lg-2 "
													onClick={() => detailsAccountHandler(account)}
													style={{ cursor: 'pointer' }}
												>
													{account.name}
												</td>
												<td className="col-lg-1 tableBody ">
													<DropdownButton drop="start" title="" style={{ position: 'static' }}>
														<Dropdown.Item
															onClick={() => editAccountHandler(account)}
														>
															<BsPencilSquare /> Editar
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() =>
																navigate(
																	`/AdminScreen/newShipment/${account._id}`
																)
															}
														>
															<FaTruck /> Nuevo Envio
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() => handleDelete(account)}
														>
															<BsTrash /> Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Card>
						)}
					</div>
					<Pagination
						className="pagination-bar"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={maxitemsPerPage}
					></Pagination>
				</div>
			)}
		</div>
	);
}

export default AccountList;
