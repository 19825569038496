import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FaCog } from 'react-icons/fa';
import AdminInfo from '../../components/AdminInfoConfig/AdminInfo.jsx';
import ColorConfig from '../../components/ColorConfig/ColorConfig.jsx';
import EmailConfig from '../../components/EmailConfig/EmailConfig.jsx';
import FaqQuestionsConfig from '../../components/FaqQuestionsConfig/FaqQuestionsConfig/FaqQuestionsConfig.jsx';
import MercadoPagoConfig from '../../components/MercadoPagoConfig/MercadoPagoConfig';
import PaymentMethodConfig from '../../components/PaymentMethodConfig/PaymentMethodConfig';
import AdminBanners from '../Banners/AdminBanners';
import AdminRegions from '../Regions/AdminRegions.jsx';
import ContactPageEditor from '../contactPageEditor.jsx';
import ShippingPageEditor from '../shippingPageEditor.jsx';
import './AdminConfig.css';

export default function AdminConfigScreen() {
	return (
		<div className="container admin-con">
			<div className="w-100">
				<h3 className="text-right mt-3 mb-3" style={{ margin: '2.5% 0' }}>
					<FaCog /> Configuración
				</h3>
				<div className="container config-items">
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header>Cambiar Datos de la Empresa</Accordion.Header>
							<Accordion.Body>
								<AdminInfo></AdminInfo>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header>
                Correos Electrónicos del Sistema
							</Accordion.Header>
							<Accordion.Body>
								<EmailConfig></EmailConfig>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header>Cambiar Tema</Accordion.Header>
							<Accordion.Body>
								<ColorConfig></ColorConfig>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header>Preguntas Frecuentes</Accordion.Header>
							<Accordion.Body>
								<FaqQuestionsConfig></FaqQuestionsConfig>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="4">
							<Accordion.Header>MercadoPago</Accordion.Header>
							<Accordion.Body>
								<MercadoPagoConfig></MercadoPagoConfig>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="5">
							<Accordion.Header>Pagina de Contacto</Accordion.Header>
							<Accordion.Body>
								<ContactPageEditor></ContactPageEditor>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="6">
							<Accordion.Header>Pagina Politica de Envios</Accordion.Header>
							<Accordion.Body>
								<ShippingPageEditor></ShippingPageEditor>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="7">
							<Accordion.Header>Metodos de pago</Accordion.Header>
							<Accordion.Body>
								<PaymentMethodConfig></PaymentMethodConfig>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="8">
							<Accordion.Header>Banners</Accordion.Header>
							<Accordion.Body>
								<AdminBanners></AdminBanners>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="9">
							<Accordion.Header>Regiones</Accordion.Header>
							<Accordion.Body>
								<AdminRegions></AdminRegions>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
			</div>
		</div>
	);
}
