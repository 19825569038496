import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';

import {
	Button,
	Card,
	Container,
	Dropdown,
	DropdownButton,
	Modal,
	Table,
} from 'react-bootstrap';

import classnames from 'classnames';
import { Helmet } from 'react-helmet-async';
import {
	BsFileEarmarkRichtext,
	BsPencilSquare,
	BsPlusCircle,
	BsTrash,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, getStatus, maxitemsPerPage } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			debts: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function DebtList() {
	const [{ loading, error, debts, successDelete, itemQuantity }, dispatch] =
    useReducer(reducer, {
    	debts: [],
    	loading: true,
    	error: '',
    	itemQuantity: 0,
    });

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//MODAL
	const [debtToDelete, setDebtToDelete] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	const fetchData = async () => {
		if (userInfo.isAdmin) {
			await fetchAllDebts();
		} else {
			await fetchUser();
		}
	};

	const fetchAllDebts = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const { data } = await axios.get('/api/debts', {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	const fetchUser = async () => {
		try {
			const { data } = await axios.get(`/api/users/userById/${userInfo._id}`);
			getDebts(data.account._id);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const getDebts = async (accountId) => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const response = await axios.get(
				`/api/debts/getDebtByAccount/${accountId}`
			);
			dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	async function deleteDebtHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.delete(`/api/debts/${debtToDelete._id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'DELETE_SUCCESS' });
			handleClose();
			toast.success('Deuda Eliminada');
			window.location.reload();
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	}

	async function handleDelete(debt) {
		await setDebtToDelete(debt);
		handleShow();	}

	async function editDebtHandler(debt) {
		navigate(`/AdminScreen/editDebt/${debt._id}`);
	}

	async function detailsDebtHandler(debt) {
		navigate(`/debt/${debt._id}`);
	}

	async function newDebtHandler() {
		navigate('/newDebt');
	}

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Deuda</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar la deuda?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deleteDebtHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<Container className="large-container"></Container>
			<Helmet>
				<title>Lista de Deudas</title>
			</Helmet>
			<div
				className={classnames('container', {
					['admin-con']: userInfo && userInfo.isSuperAdmin,
				})}
			>
				<Container fluid>
					<h1 className="text-center mt-5">Lista de Deudas</h1>
					<div
						style={{
							display: 'flex',
							justifyContent: 'right',
							alignItems: 'center',
						}}
					>
						<Button
							className="btn btn-dark m-1 fixed-right"
							value="Crear Contacto"
							onClick={() => newDebtHandler()}
						>
							<span>
								<BsPlusCircle /> Crear Deuda
							</span>
						</Button>
					</div>

					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Card>
							<Table responsive size="sm">
								<thead className="align-items-center table-order tHead">
									<th className="col-lg-2 tableHeader">Deudor</th>
									<th className="col-lg-1 tableHeader">Monto</th>
									<th className="col-lg-1 tableHeader">Saldo</th>
									<th className="col-lg-2 tableHeader">Tipo</th>
									<th className="col-lg-2 tableHeader">Acciones</th>
								</thead>

								{currentPosts.map((debt, i) => (
									<tbody
										key={i}
										className="align-items-center table-order tableBodyHover"
									>
										<tr id="data" key={debt._id}>
											<td className="col-lg-2 tableBody">
												{debt.owner ? debt.owner.name : null}
											</td>
											<td className="col-lg-1 tableBody">
												{Math.round(debt.amount)}
											</td>
											<td className="col-lg-1 tableBody">
												{Math.round(debt.balance)}
											</td>
											<td className="col-lg-2 tableBody">
												{debt.shipment.type ? debt.shipment.type.name : null}
											</td>
											<td className="col-lg-2 tableBody">
												<DropdownButton drop="start" title="">
													<Dropdown.Item
														onClick={() => detailsDebtHandler(debt)}
													>
														<BsFileEarmarkRichtext />
                            Ver Detalles
													</Dropdown.Item>
													<Dropdown.Item onClick={() => editDebtHandler(debt)}>
														<BsPencilSquare />
                            Editar
													</Dropdown.Item>
													<Dropdown.Item onClick={() => handleDelete(debt)}>
														<BsTrash> </BsTrash>Eliminar
													</Dropdown.Item>
												</DropdownButton>
											</td>
										</tr>
									</tbody>
								))}
							</Table>
						</Card>
					)}
				</Container>
			</div>
			<Pagination
				className="pagination-bar"
				totalCount={itemQuantity}
				onPageChange={onPageChange}
				currentPage={currentPage}
				pageSize={maxitemsPerPage}
			></Pagination>
		</div>
	);
}

export default DebtList;
