import Button from 'react-bootstrap/Button';
import React from 'react';
import './BtnPlus.css';

export default function BtnPlus({ type, onClick, className }) {
	return (
		<Button className={className + ' btn-plus'} type={type} onClick={onClick}>
      +
		</Button>
	);
}
