import { Modal, Nav, Navbar, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import React, { useContext, useLayoutEffect, useReducer, useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaTimes } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import '../App.css';
import { Store } from '../Store';
import './AdminNavbar.css';
import NavbarOptions from './NavbarOptions/NavbarOptions';
import NavbarToggler from './NavbarToggler/NavbarToggler';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_COMPANY_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_COMPANY_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_COMPANY_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function Navbarr() {
	const [, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
	});
	const [imageURL, setImageURL] = useState();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const [width, setWidth] = useState('');

	useLayoutEffect(() => {
		window.addEventListener('resize', () => {
			if (window.innerWidth > 992) {
				setActiveCollapse(true);
			}
		});
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_COMPANY_REQUEST' });
				const { data } = await axios.get('/api/config/public');
				setImageURL(data.image.fileLink);
				setWidth(window.innerWidth);
				dispatch({ type: 'FETCH_COMPANY_SUCCESS' });
			} catch (err) {
				dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
			}
		};
		fetchData();
	}, [width]);

	const signoutHandler = async () => {
		await ctxDispatch({ type: 'USER_SIGNOUT' });
		localStorage.removeItem('userInfo');
		await navigate('/');
	};

	//#region NAVBAR COLLAPSE METHODS
	const [isActiveCollapse, setActiveCollapse] = useState(true);

	const handleToggleCollapse = () => {
		setActiveCollapse(!isActiveCollapse);
	};
	const collapse = () => {
		setActiveCollapse(true);
	};
	//#endregion

	const location = useLocation();
	const route = location.pathname;

	if (route.includes('AdminScreen') && userInfo && userInfo.isSuperAdmin) return null;

	return (
		<div>
			<ToastContainer position='bottom-center' limit={1}></ToastContainer>
			<header className='header-admin site-header'>
				<Navbar className='container-fluid admin-navbar' expand='d-none '>
					<NavbarToggler onClick={handleToggleCollapse} className='admin-toggler' />
					{imageURL && (
						<Navbar.Brand className='mx-auto'>
							<Link className='' to='/'>
								<img src={imageURL} alt='Company logo' />
							</Link>
						</Navbar.Brand>
					)}
					{userInfo ? (
						<Navbar className='navbar-collapse-Admin'>
							{isActiveCollapse ? (
								<NavbarOptions handleCollapse={collapse} userInfo={userInfo} />
							) : (
								<Modal
									backdropClassName='collapsable-navbar-backdrop'
									show={!isActiveCollapse}
									dialogClassName='collapsable-navbar-extended'
								>
									<Row className='justify-content-end mt-3 w-100'>
										<button className='col-md-1 col-sm-2 col-3 exit-menu' onClick={collapse}>
											<FaTimes />
										</button>
									</Row>
									<NavbarOptions handleCollapse={collapse} userInfo={userInfo} />
								</Modal>
							)}
						</Navbar>
					) : null}

					{userInfo ? (
						<NavDropdown title={userInfo.name} id='basic-nav-drospdown' className='mx-2'>
							<LinkContainer to={userInfo.isAdmin ? ('/AdminScreen/profile') : ('/profile')}>
								<NavDropdown.Item>Mi Perfil</NavDropdown.Item>
							</LinkContainer>
							<NavDropdown.Divider />
							<li className='rightlinks'>
								{' '}
								<Link className='dropdown-item' to='#signout' onClick={signoutHandler}>
									<i className='fa fa-sign-out ' aria-hidden='true'></i>
									Salir
								</Link>
							</li>
						</NavDropdown>
					) : (
						<Nav fill>
							<Nav.Link eventKey={2} href='/signin'>
								<i className='fa fa-user' aria-hidden='true'></i>
								<span className='d-none d-lg-block mx-3'>Ingresar</span>
							</Nav.Link>
						</Nav>
					)}
				</Navbar>
			</header>
			<div></div>
		</div>
	);
}

export default Navbarr;
