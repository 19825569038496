import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getError } from '../utils';

export default function ResetPassword() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');

	const handleChange = (event) => {
		setEmail(event.target.value.toLowerCase());
		console.error('email is:', event.target.value);
	};

	async function resertPasswordHandler() {
		try {
			await axios.post('/api/users/forgotPassword', {
				email: email,
			});
			toast.success('Email Enviado');
			navigate('/');
		} catch (err) {
			toast.error(getError(err));
		}
	}

	return (
		<Container className='small-container'>
			<Helmet>
				<title>Resetear Contraseña</title>
			</Helmet>
			<h1 className='my-3'>Resetear Contraseña</h1>
			<Form onSubmit={(e) => e.preventDefault()}>
				<Form.Group className='mb-3' controlId='email'>
					<Form.Label>E-mail</Form.Label>
					<Form.Control type='email' required onChange={handleChange} value={email}></Form.Control>
				</Form.Group>
				<div className='mb-3'>
					<Button type='submit' onClick={resertPasswordHandler}>
            Resetear
					</Button>
				</div>
			</Form>
		</Container>
	);
}
