import { SkeletonText } from '@chakra-ui/react';
import {
	GoogleMap,
	Marker,
	Polygon,
	useJsApiLoader,
} from '@react-google-maps/api';
import axios from 'axios';
import React, {
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import { Accordion, Button, Form, Modal, Row } from 'react-bootstrap';
import { BsPlusCircle, BsSave } from 'react-icons/bs';
import { FaRoute, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeAhead/CustomTypeahead';
import LoadingBox from '../../components/LoadingBox';
import {
	GoogleMapsApiKey,
	getError,
	getStatus,
	initialPolygonPath,
} from '../../utils';
import EditVehicleModal from './EditVehicleModal/EditVehicleModal.jsx';
import './FleetManagement.css';

//#region
const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNT_SUCCESS':
		return {
			...state,
			account: action.payload,
			loadingAccounts: false,
			loadingMap: true,
		};
	case 'FETCH_ACCOUNT_FAIL':
		return { ...state, loadingAccounts: false, error: true };
	case 'FETCH_MAP_SUCCESS':
		return {
			...state,
			map: action.payload.map,
			center: action.payload.center,
			originMarker: action.payload.originMarker,
			loadingMap: false,
			loadingZones: true,
		};
	case 'FETCH_MAP_FAIL':
		return { ...state, loadingMap: false };
	case 'FETCH_ZONES_REQUEST':
		return { ...state, loadingZones: true };
	case 'FETCH_ZONES_SUCCESS':
		return {
			...state,
			zones: action.payload,
			loadingZones: false,
			loadingVehicles: true,
		};
	case 'FETCH_ZONES_FAIL':
		return { ...state, loadingZones: false, error: true };
	case 'FETCH_VEHICLES_REQUEST':
		return { ...state, loadingVehicles: true };
	case 'FETCH_VEHICLES_SUCCESS':
		return { ...state, loadingVehicles: false, vehicles: action.payload };
	case 'FETCH_VEHICLES_FAIL':
		return { ...state, loadingVehicles: false, error: true };
	case 'NEW_ZONE_SUCCESS':
		return { ...state, loadingZones: true };
    //#endregion
	}
};

//#endregion
export default function FleetManagement() {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: GoogleMapsApiKey,
		libraries: ['places', 'geometry'],
	});

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	//#region REDUCER STATES
	const [
		{
			error,
			zones,
			loadingAccounts,
			loadingMap,
			loadingZones,
			loadingVehicles,
			map,
			account,
			vehicles,
			center,
			originMarker,
		},
		dispatch,
	] = useReducer(reducer, {
		zones: [],
		vehicles: [],
		account: {},
		//#region MAP STATES
		center: null,
		originMarker: null,
		map: null,
		//#endregion
		error: false,
		//#region LOADING STATES
		loadingAccounts: true,
		loadingMap: false,
		loadingZones: false,
		loadingVehicles: false,
		//#endregion
	});
	//#endregion

	//#region POLYGON STATES
	const polygonRef = useRef(null);
	const listenersRef = useRef([]);
	const selectedListenersRef = useRef([]);
	const [path, setPath] = useState(initialPolygonPath);
	//#endregion

	//#region NEW ZONE STATES
	const [addNewZone, setAddNewZone] = useState(false);
	const [color, setColor] = useState(
		'#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')
	);
	const [name, setName] = useState('');
	const [price, setPrice] = useState('');
	const [vehicle, setVehicle] = useState(null);
	//#endregion
	//#region SELECT ZONES
	const [editZone, setEditZone] = useState(false);
	const [selectedZone, setSelectedZone] = useState(null);
	const selectedPolygonRef = useRef(null);
	const [zoneToDelete, setZoneToDelete] = useState('');
	const [activeKey, setActiveKey] = useState(0);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showVehicleModal, setShowVehicleModal] = useState('');
	//#endregion
	const [vehicleIsInvalid, setVehicleIsInvalid] = useState(false);
	const [selectedVehicleIsInvalid, setSelectedVehicleIsInvalid] =
    useState(false);

	const resetZoneData = () => {
		setSelectedZone({});
		setName('');
		setColor(
			'#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')
		);
		setPrice('');
		setVehicle(null);
	};
	const activeKeyHandler = (zone) => {
		const newKey = zone._id;
		if (!addNewZone && !editZone) {
			if (newKey != activeKey) {
				selectZoneHandler(zone);
				setActiveKey(newKey);
			} else {
				resetZoneData();
				setActiveKey('');
			}
		}
	};
	//#region EDIT ZONE HANDLERS
	const selectedVehicleChangeHandler = (selected) => {
		const selectedVehicle = selected[0];
		const isValid = selectedVehicle
			? vehicles.includes(selectedVehicle)
			: false;
		setSelectedVehicleIsInvalid(!isValid);
		setSelectedZone({
			...selectedZone,
			vehicle: selected[0] ? [selected[0]] : [],
		});
	};
	const selectZoneHandler = (zone) => {
		setSelectedZone({});
		setSelectedVehicleIsInvalid(false);
		setEditZone(false);
		const zoneVehicle = vehicles.find((v) => v.zone && v.zone._id === zone._id);
		setSelectedZone({ ...zone, vehicle: zoneVehicle ? [zoneVehicle] : null });
	};

	const toggleEditZone = () => {
		if (editZone) {
			setSelectedZone({});
			setActiveKey('');
		}
		setEditZone(!editZone);
	};
	//#endregion
	//region ADD ZONE HANDLERS
	const toggleAddZone = () => {
		setActiveKey(activeKey != 1 ? 1 : '');
		setAddNewZone(activeKey != 1);
		setPath(initialPolygonPath);
		setVehicle(null);
	};
	const openAddZone = () => {
		setActiveKey(1);
		setVehicle(null);
		setPath(initialPolygonPath);
		setAddNewZone(true);
	};
	const vehicleChangeHandler = (selected) => {
		const selectedVehicle = selected[0];
		const isValid = selectedVehicle
			? vehicles.includes(selectedVehicle)
			: false;
		setVehicleIsInvalid(!isValid);
		setVehicle(selected[0] ? [selected[0]] : []);
	};
	//endregion
	const toggleDeleteZone = (zone) => {
		setZoneToDelete(zone);
		setShowDeleteModal(true);
	};
	//#region REQUESTS
	const fetchAccounts = async () => {
		try {
			const { data: defaultAccount } = await axios.get(
				'/api/accounts/defaultAccount/get'
			);
			dispatch({
				type: 'FETCH_ACCOUNT_SUCCESS',
				payload: defaultAccount,
			});
		} catch (err) {
			console.error(err);
			dispatch({ type: 'FETCH_ACCOUNT_FAIL' });
		}
	};
	const fetchVehicles = async () => {
		try {
			const { data: vehicles } = await axios.get('/api/vehicles', {
				headers: {
					authorization: `Bearer ${userInfo.token}`,
				},
			});
			dispatch({
				type: 'FETCH_VEHICLES_SUCCESS',
				payload: vehicles,
			});
		} catch (err) {
			console.error(err);
			dispatch({ type: 'FETCH_VEHICLES_FAIL' });
		}
	};
	const fetchMap = async () => {
		try {
			const { data: map } = await axios.get(
				`/api/maps/mapAccount/${account._id}`
			);
			const { latCentral: lat, lngCentral: lng } = map;
			dispatch({
				type: 'FETCH_MAP_SUCCESS',
				payload: {
					map,
					center: { lat, lng },
					originMarker: { lat, lng, show: true },
				},
			});
		} catch (err) {
			console.error(err);
			dispatch({ type: 'FETCH_MAP_FAIL' });
		}
	};
	const fetchZones = async () => {
		try {
			const { data } = await axios.get(`/api/zones/delivery-map/${map._id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'FETCH_ZONES_SUCCESS', payload: data });
		} catch (err) {
			console.error(err);
			dispatch({ type: 'FETCH_ZONES_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};
	//SAVE ZONE
	async function addZoneHandler() {
		if (name && path && color && price && map._id && vehicle[0]) {
			const zoneData = {
				name,
				location: path,
				color,
				price,
				map: map._id,
				zoneType: 'reparto',
				vehicle: vehicle[0]._id,
			};
			try {
				await axios.post('/api/zones/createZone', zoneData);
				dispatch({ type: 'FETCH_ZONES_REQUEST' });
				toggleAddZone();
				resetZoneData();
				toast.success('Zona creada');
			} catch (ex) {
				console.error(ex);
				toast.error(getError(ex));
			}
		} else {
			toast.error('Error. Nombre, precio, zona y color son requeridos.');
		}
	}
	async function deleteZoneHandler() {
		try {
		 await axios.delete(`/api/zones/${zoneToDelete._id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'FETCH_ZONES_REQUEST' });
			setZoneToDelete(null);
			setShowDeleteModal(false);
			toast.success('Zona eliminada');
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	}

	const addVehicle = async (vehicleData) => {
		const {
			brand,
			registrationNumber,
			model,
			color,
			shiftStart,
			shiftEnd,
			deliveryTime,
		} = vehicleData;

		try {
			await axios.post(
				'/api/vehicles',
				{
					brand,
					model,
					registrationNumber,
					color,
					shiftStart,
					shiftEnd,
					deliveryTime,
				},
				{
					headers: {
						authorization: `Bearer ${userInfo.token}`,
					},
				}
			);
			dispatch({ type: 'FETCH_VEHICLES_REQUEST' });
			await fetchVehicles();
			toast.success('Vehículo creado');
		} catch (ex) {
			console.error(ex);
			toast.error(getError(ex));
		}
	};

	const editZoneHandler = async () => {
		try {
			const { name, price, color, location } = selectedZone;
			const vehicle = selectedZone.vehicle[0];
			if (name && price && color && location && vehicle) {
				const zoneData = {
					name,
					price,
					color,
					vehicle: vehicle._id,
				};
				await axios.put(`/api/zones/${selectedZone._id}`, zoneData, {
					headers: {
						authorization: `Bearer ${userInfo.token}`,
					},
				});
				dispatch({ type: 'FETCH_ZONES_REQUEST' });
				toggleEditZone();
				toast.success('Zona actualizada.');
			} else {
				toast.error('Error. Todos los datos son requeridos.');
			}
		} catch (ex) {
			console.error(ex);
			toast.error(getError(ex));
		}
	};
	//#endregion
	useEffect(() => {
		if (loadingAccounts) {
			fetchAccounts();
		}
		if (loadingMap) {
			fetchMap();
		}
		if (loadingZones) {
			fetchZones();
		}
		if (loadingVehicles) {
			fetchVehicles();
		}
	}, [loadingAccounts, loadingMap, loadingZones, loadingVehicles]);
	//#region POLYGON HANDLING
	const onEdit = useCallback(() => {
		if (polygonRef.current) {
			const nextPath = polygonRef.current
				.getPath()
				.getArray()
				.map((latLng) => {
					return { lat: latLng.lat(), lng: latLng.lng() };
				});

			setPath(nextPath);
		}
	}, [setPath]);

	// Bind refs to current Polygon and listeners
	const onLoad = useCallback(
		(polygon) => {
			polygonRef.current = polygon;
			const path = polygon.getPath();
			listenersRef.current.push(
				path.addListener('set_at', onEdit),
				path.addListener('insert_at', onEdit),
				path.addListener('remove_at', onEdit)
			);
		},
		[onEdit]
	);
	// Clean up refs
	const onUnmount = useCallback(() => {
		listenersRef.current.forEach((lis) => lis.remove());
		polygonRef.current = null;
	}, []);

	//#endregion
	//#region SELECTED ZONE HANDLING
	const onEditSelected = useCallback(() => {
		if (selectedPolygonRef.current) {
			selectedPolygonRef.current
				.getPath()
				.getArray()
				.map((latLng) => {
					return { lat: latLng.lat(), lng: latLng.lng() };
				});
		}
	}, []);

	const onLoadSelected = useCallback(
		(polygon) => {
			selectedPolygonRef.current = polygon;
			const path = polygon.getPath();
			selectedListenersRef.current.push(
				path.addListener('set_at', onEdit),
				path.addListener('insert_at', onEdit),
				path.addListener('remove_at', onEdit)
			);
		},
		[onEditSelected]
	);
	const onUnmountSelected = useCallback(() => {
		selectedListenersRef.current.forEach((lis) => lis.remove());
		selectedPolygonRef.current = null;
	}, []);

	//#endregion

	if (!isLoaded) {
		return <SkeletonText />;
	}
	return (
		<>
			<EditVehicleModal
				handleClose={() => setShowVehicleModal(false)}
				show={showVehicleModal}
				vehicleColor={color}
				submitHandler={addVehicle}
			/>
			<Modal
				size="lg"
				show={showDeleteModal}
				onHide={() => setShowDeleteModal(false)}
				animation={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Zona</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar la zona?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancelar
					</Button>
					<Button onClick={deleteZoneHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<div
				id="fleet-management-container"
				className="container admin-con d-flex bg-white"
			>
				<header className="d-flex centered">
					<h2 className="fw-bold text-uppercase px-5 text-center">
						<FaRoute className="mb-1" /> Mapa de reparto
					</h2>
				</header>
				<Row id="fleet-content-container" className='map-cont'>
					<section id="write-zone-section" className="col-lg-9">
						{loadingMap ? (
							<LoadingBox></LoadingBox>
						) : (
							<section id="fleet-map-container">
								<GoogleMap
									className="px-0"
									center={center}
									zoom={12}
									mapContainerStyle={{
										width: '100%',
										height: '100%',
									}}
									options={{
										zoomControl: true,
										streetViewControl: false,
										mapTypeControl: false,
										fullscreenControl: false,
										scaleControl: true,
									}}
								>
									<>
										{originMarker && (
											<Marker
												label={'O'}
												position={{
													lat: originMarker.lat,
													lng: originMarker.lng,
												}}
											/>
										)}
										{addNewZone && (
											<Polygon
												draggable
												editable
												path={path}
												options={{
													fillColor: color,
													fillOpacity: 0.6,
													strokeWeight: 1,
													strokeColor: color,
												}}
												onMouseUp={onEdit}
												onDragEnd={onEdit}
												onLoad={onLoad}
												onUnmount={onUnmount}
											/>
										)}
										{zones.map(
											(zone, index) => (
												(zone['options'] = {
													fillColor: zone.color,
													fillOpacity: 0.6,
													strokeWeight: 1,
													strokeColor: zone.color,
												}),
												(
													<Polygon
														onClick={() => activeKeyHandler(zone)}
														key={index}
														options={zone.options}
														onLoad={onLoadSelected}
														onUnmount={onUnmountSelected}
														path={zone.location}
													/>
												)
											)
										)}
										<></>
									</>
								</GoogleMap>
							</section>
						)}
					</section>
					<aside id="zone-info-aside" className="pt-2 col-md-3">
						<div className="d-flex">
							<h2 className="col">Zonas de reparto</h2>
						</div>

						<Accordion activeKey={activeKey}>
							<Accordion.Item id="new-zone-accordion-item" eventKey={1}>
								<Accordion.Header onClick={openAddZone}>
									<span>
										<BsPlusCircle className="" />
                    Nueva zona
									</span>
								</Accordion.Header>
								<Accordion.Body className="pb-1" onClick={() => {}}>
									<Form className="w-100">
										<Form.Label>Nombre</Form.Label>
										<Form.Control
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
										<Form.Label>Precio</Form.Label>
										<Form.Control
											value={price}
											onChange={(e) => setPrice(e.target.value)}
											type="number"
										/>
										<Form.Label>Color</Form.Label>
										<Form.Control
											value={color}
											className="mb-3"
											onChange={(e) => setColor(e.target.value)}
											type="color"
										/>
										<Form.Label>Vehículo</Form.Label>
										<CustomTypeahead
											onCreateNew={() => setShowVehicleModal(true)}
											id="select-vehicle-typeahead"
											options={vehicles.filter((v) => v.active)}
											labelKey={(v) =>
												`${v.brand} ${v.model} - ${v.registrationNumber}`
											}
											onChange={vehicleChangeHandler}
											selected={vehicle}
											placeholder="Buscar vehículo..."
											isInvalid={vehicleIsInvalid}
											linkToCreate="/AdminScreen/newAccount"
											newOption="Nuevo vehiculo"
										/>
									</Form>

									<div className="mt-2 d-flex justify-content-between">
										<Button
											className="btn m-1"
											value="Cancelar"
											onClick={toggleAddZone}
										>
											<FaTimes /> Cancelar
										</Button>
										<Button
											className="btn m-1"
											value="Crear Zona"
											onClick={addZoneHandler}
											disabled={
												!name ||
                        !price ||
                        !color ||
                        !path ||
                        !vehicle ||
                        vehicleIsInvalid
											}
										>
											<BsSave /> Guardar Zona
										</Button>
									</div>
								</Accordion.Body>
							</Accordion.Item>
							{zones &&
                zones.map((zone) => (
                	<Accordion.Item key={zone._id} eventKey={zone._id}>
                		<Accordion.Header onClick={() => activeKeyHandler(zone)}>
                			<Form.Control
                				value={zone.name}
                				disabled
                				className="accordion-header-input"
                			/>
                		</Accordion.Header>
                		<Accordion.Body>
                			<Form className="w-100">
                				<Form.Label>Nombre</Form.Label>
                				<Form.Control
                					value={editZone ? selectedZone.name : zone.name}
                					disabled={!editZone}
                					onChange={(e) =>
                						setSelectedZone({
                							...selectedZone,
                							name: e.target.value,
                						})
                					}
                				/>
                				<Form.Label>Precio</Form.Label>
                				<Form.Control
                					value={editZone ? selectedZone.price : zone.price}
                					disabled={!editZone}
                					onChange={(e) =>
                						setSelectedZone({
                							...selectedZone,
                							price: e.target.value,
                						})
                					}
                					type="number"
                				/>
                				<Form.Label>Color</Form.Label>
                				<Form.Control
                					value={editZone ? selectedZone.color : zone.color}
                					disabled={!editZone}
                					onChange={(e) =>
                						setSelectedZone({
                							...selectedZone,
                							color: e.target.value,
                						})
                					}
                					type="color"
                				/>

                				<Form.Label>Vehiculo</Form.Label>
                				{selectedZone ? (
                					editZone ? (
                						<CustomTypeahead
                							onCreateNew={async () => {
                								await setColor(zone.color);
                								setShowVehicleModal(true);
                							}}
                							id="selected-zone-vehicle-typeahead"
                							options={vehicles.filter(
                								(v) => !v.zone || v.zone === zone._id
                							)}
                							labelKey={(v) =>
                								`${v.brand} ${v.model} - ${v.registrationNumber}`
                							}
                							onChange={selectedVehicleChangeHandler}
                							selected={selectedZone.vehicle}
                							disabled={!editZone}
                							placeholder="Buscar vehículo..."
                							linkToCreate="/AdminScreen/newAccount"
                							isInvalid={selectedVehicleIsInvalid}
                							newOption="Nuevo vehiculo"
                						/>
                					) : (
                						<Form.Control
                							value={
                								selectedZone.vehicle && selectedZone.vehicle[0]
                									? `${selectedZone.vehicle[0].brand} ${selectedZone.vehicle[0].model} - ${selectedZone.vehicle[0].registrationNumber}`
                									: '-'
                							}
                							disabled
                							type="text"
                						/>
                					)
                				) : (
                					<></>
                				)}

                				{
                					<div className="d-flex mt-2 justify-content-between">
                						{!editZone && (
                							<>
                								<Button
                									className="btn btn-dark m-1 fixed-right"
                									onClick={() => toggleDeleteZone(zone)}
                								>
                									<span>Eliminar</span>
                								</Button>
                								<Button
                									className="btn btn-dark m-1 fixed-right"
                									onClick={toggleEditZone}
                								>
                									<span>Editar</span>
                								</Button>
                							</>
                						)}
                						{editZone && (
                							<>
                								<Button
                									className="btn btn-dark m-1 fixed-right"
                									onClick={toggleEditZone}
                								>
                									<span>
                										<FaTimes />
                                    Cancelar
                									</span>
                								</Button>
                								<Button
                									onClick={editZoneHandler}
                									className="btn btn-da/rk m-1 fixed-right"
                									disabled={
                										!selectedZone.name ||
                                    !selectedZone.price ||
                                    !selectedZone.color ||
                                    !selectedZone.vehicle ||
                                    selectedVehicleIsInvalid
                									}
                								>
                									<span>
                										<BsSave />
                                    Guardar cambios
                									</span>
                								</Button>
                							</>
                						)}
                					</div>
                				}
                			</Form>
                		</Accordion.Body>
                	</Accordion.Item>
                ))}
						</Accordion>
					</aside>
				</Row>
			</div>
		</>
	);
}
