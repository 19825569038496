import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	FloatingLabel,
	Modal,
	Row,
	Stack,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError, getStatus } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, vehicle: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false };
	default:
		return state;
	}
};

function EditVehicle() {
	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;
	const [{ loading, vehicle, loadingUpdate }, dispatch] = useReducer(
		reducer,
		{
			loadingUpdate: false,
			vehicle: {},
			loading: true,
			error: '',
		}
	);
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [registrationNumber, setRegistrationNumber] = useState();
	const [brand, setBrand] = useState();
	const [model, setModel] = useState();
	const [shiftStart, setShiftStart] = useState();
	const [shiftEnd, setShiftEnd] = useState();
	const [deliveryTime, setDeliveryTime] = useState();

	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get(`/api/vehicles/byId/${id}`, {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				setRegistrationNumber(result.data.registrationNumber);
				setBrand(result.data.brand);
				setModel(result.data.model);
				setDeliveryTime(result.data.deliveryTime);
				setShiftStart(
					new Date(result.data.shiftStart).toISOString().slice(11, 16)
				);
				if (result.data.shiftEnd) {
					setShiftEnd(
						new Date(result.data.shiftEnd).toISOString().slice(11, 16)
					);
				}
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};

		fetchData();
	}, [id]);

	const editVehicleHandler = async () => {
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
	 await axios.put(
				`/api/vehicles/edit/${vehicle._id}`,
				{
					registrationNumber,
					brand,
					model,
					deliveryTime,
					shiftStart,
					shiftEnd,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);

			dispatch({
				type: 'UPDATE_SUCCESS',
			});

			toast.success('Vehiculo actualizado Correctamente');
			navigate('/AdminScreen/vehicles');
		} catch (error) {
			dispatch({ type: 'UPDATE_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	};

	return (
		<div>
			{loading || (loadingUpdate && <LoadingBox></LoadingBox>)}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={editVehicleHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<div className="container admin-con align-items-center justify-content-center">
				<Helmet>
					<title>Editar Vehiculo</title>
				</Helmet>
				<Container fluid>
					<Row className="d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Editar Vehiculo
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Row>
													<Col>
														<Form.Group className="mb-3" controlid="name">
															<Form.Label>Matricula</Form.Label>
															<Form.Control
																type="text"
																required
																defaultValue={vehicle.registrationNumber}
																onChange={(e) =>
																	setRegistrationNumber(e.target.value)
																}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group className="mb-3" controlid="name">
															<Form.Label>Marca</Form.Label>
															<Form.Control
																type="text"
																required
																defaultValue={vehicle.brand}
																onChange={(e) => setBrand(e.target.value)}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group className="mb-3" controlid="name">
															<Form.Label>Modelo</Form.Label>
															<Form.Control
																type="text"
																required
																defaultValue={vehicle.model}
																onChange={(e) => setModel(e.target.value)}
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Tiempo de Entrega(minutos)</Form.Label>
													<Form.Control
														type="number"
														required
														defaultValue={vehicle.deliveryTime}
														onChange={(e) => setDeliveryTime(e.target.value)}
													></Form.Control>
												</Form.Group>

												<Row className="m-0 p-0">
													<Col className="m-0 p-0">
														<FloatingLabel
															className="col-md-6 mb-2"
															label="Inicio de turno"
															style={{ width: '100%' }}
														>
															<Form.Control
																defaultValue={shiftStart}
																onChange={(e) => setShiftStart(e.target.value)}
																required
																type="time"
																placeholder="Comienzo de turno"
															/>
														</FloatingLabel>
													</Col>
													<Col
														className="p-0"
														style={{ marginLeft: '0.25rem' }}
													>
														<FloatingLabel
															className="col-md-6 mb-2"
															label="Fin de turno"
															style={{ width: '100%' }}
														>
															<Form.Control
																defaultValue={shiftEnd}
																onChange={(e) => setShiftEnd(e.target.value)}
																required
																type="time"
																placeholder="Fin de turno"
															/>
														</FloatingLabel>
													</Col>
												</Row>

												<Stack direction="horizontal" gap={10} className="mt-2">
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
													>
                            Guardar Cambios
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
export default EditVehicle;
