import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa';
import BtnPlus from '../../BtnPlus/BtnPlus';
import TextFloatingLabel from '../../TextFloatingLabel/TextFloatingLabel';
import ModalDeleteFaqQuestion from '../DeleteFaqQuestionModal/DeleteFaqQuestionModal';
import NewFaqQuestionModal from '../NewFaqQuestionModal/NewFaqQuestionModal';
import './FaqQuestionsConfig.css';

export default function FaqQuestionsConfig() {
	const [questions, setQuestions] = useState([]);
	const [selectedQuestion, setSelectedQuestion] = useState('');
	const [loadQuestions, setLoadQuestions] = useState(true);
	const [deleteShow, setDeleteShow] = useState(false);
	const [show, setShow] = useState(false);
	const [edit, setEdit] = useState(false);

	useEffect(() => {
		const fetchQuestions = async () => {
			if (loadQuestions) {
				try {
					const fetchedQuestions = await axios.get('/api/faq');
					setQuestions(fetchedQuestions.data);
				} catch (ex) {
					console.error(ex);
				}
				setLoadQuestions(false);
			}
		};
		fetchQuestions();
	}, [loadQuestions]);

	const handleOpen = () => setShow(true);
	const handleEdit = () => {
		setEdit(true);
		setShow(true);
	};
	const handleClose = () => {
		setShow(false);
		setDeleteShow(false);
		setEdit(false);
		setLoadQuestions(true);
		setSelectedQuestion('');
	};
	const handleSelectDeletion = (q) => {
		setSelectedQuestion(q);
		setDeleteShow(true);
	};

	return (
		<div id='faq-config'>
			<ModalDeleteFaqQuestion
				deleteShow={deleteShow}
				handleClose={handleClose}
				question={selectedQuestion}
				onHide={() => setDeleteShow(false)}
			></ModalDeleteFaqQuestion>
			<NewFaqQuestionModal
				show={show}
				handleClose={handleClose}
				edit={edit}
				editQuestion={selectedQuestion}
			></NewFaqQuestionModal>
			<Row className='align-items-center'>
				<Col xs={10}>
					<div className=' ms-1'>
						<p id='label-boton-faq'>Agregar nueva pregunta.</p>
						<BtnPlus
							className='col-1 mb-1 ms-1 mt-2'
							id='new-question-btn'
							onClick={handleOpen}
						></BtnPlus>
					</div>
					<Row>
						<Col xs={10}>
							<ListGroup id='lista-preguntas'>
								{questions.length &&
									questions.map((q, index) => {
										return (
											<ListGroup.Item key={index} id={q._id}>
												<Row className='justify-content-between px-2'>
													<Col lg={6}>{q.question}</Col>
													<Col sm={8} lg={2} className='d-flex justify-content-around p-0'>
														<button
															onClick={() => setSelectedQuestion(q)}
															className='action-button'
														>
															<FaEye></FaEye>
														</button>
														<button
															className='action-button'
															onClick={() => handleSelectDeletion(q)}
														>
															<FaTrash></FaTrash>
														</button>
													</Col>
												</Row>
											</ListGroup.Item>
										);
									})}
							</ListGroup>
						</Col>
					</Row>
				</Col>
				{selectedQuestion && (
					<Col xs={5}>
						<Row id='faq-data' className=' justify-content-start align-items-center mb-1'>
							<TextFloatingLabel
								className='mb-2'
								label='Pregunta'
								data={selectedQuestion && selectedQuestion.question}
								edit={selectedQuestion && handleEdit}
							></TextFloatingLabel>
							<TextFloatingLabel
								edit={selectedQuestion && handleEdit}
								label='Respuesta'
								data={selectedQuestion && selectedQuestion.answer}
							></TextFloatingLabel>
						</Row>
						<Row className='align-items-center'>
							<span id='label-activo' className='inline-block col-1 me-1'>
								Activo
							</span>
							<Form.Check
								readOnly
								className='col-1 pe-0 me-1'
								checked={selectedQuestion ? selectedQuestion.active : false}
							></Form.Check>
							<button id='pen-checkbox-faq' className='col-1 pen-edit-method' onClick={handleEdit}>
								<FaPen></FaPen>
							</button>
						</Row>
					</Col>
				)}
			</Row>
		</div>
	);
}
