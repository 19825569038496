import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	FloatingLabel,
	Row,
	Stack,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, vehicle: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function VehicleDetails() {
	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;
	const [{ loading, vehicle }, dispatch] = useReducer(reducer, {
		vehicle: {},
		loading: true,
		error: '',
	});
	const { state} = useContext(Store);
	const { userInfo } = state;

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get(`/api/vehicles/byId/${id}`, {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});

				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};

		fetchData();
	}, [id]);


	const formatTime = (date) => {
		if (date) {
			const ret = new Date(date).toISOString().slice(11, 16);
			return ret;
		} else {
			return;
		}
	};

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}

			<div className="container admin-con align-items-center justify-content-center">
				<Helmet>
					<title>Detalles del Vehiculo</title>
				</Helmet>
				<Container fluid>
					<Row className="d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Detalles del Vehiculo
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Row>
													<Col>
														<Form.Group className="mb-3" controlid="name">
															<Form.Label>Matricula</Form.Label>
															<Form.Control
																readOnly
																type="text"
																defaultValue={vehicle.registrationNumber}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group className="mb-3" controlid="name">
															<Form.Label>Marca</Form.Label>
															<Form.Control
																type="text"
																required
																readOnly
																defaultValue={vehicle.brand}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group className="mb-3" controlid="name">
															<Form.Label>Modelo</Form.Label>
															<Form.Control
																type="text"
																required
																defaultValue={vehicle.model}
																readOnly
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Tiempo de Entrega(minutos)</Form.Label>
													<Form.Control
														type="number"
														required
														defaultValue={vehicle.deliveryTime}
														readOnly
													></Form.Control>
												</Form.Group>

												<Row className="m-0 p-0">
													<Col className="m-0 p-0">
														<FloatingLabel
															className="col-md-6 mb-2"
															label="Inicio de turno"
															style={{ width: '100%' }}
														>
															<Form.Control
																defaultValue={formatTime(vehicle.shiftStart)}
																readOnly
																required
																type="time"
																placeholder="Comienzo de turno"
															/>
														</FloatingLabel>
													</Col>
													<Col
														className="p-0"
														style={{ marginLeft: '0.25rem' }}
													>
														<FloatingLabel
															className="col-md-6 mb-2"
															label="Fin de turno"
															style={{ width: '100%' }}
														>
															<Form.Control
																defaultValue={formatTime(vehicle.shiftEnd)}
																readOnly
																required
																type="time"
																placeholder="Fin de turno"
															/>
														</FloatingLabel>
													</Col>
												</Row>

												<Stack direction="horizontal" gap={10} className="mt-2">
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={() =>
															navigate(
																`/AdminScreen/editVehicle/${vehicle._id}`
															)
														}
													>
                            Editar Vehiculo
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
export default VehicleDetails;
