import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Row,
	Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTS_REQUEST':
		return { ...state, loadingDebts: true };
	case 'FETCH_DEBTS_SUCCESS':
		return { ...state, debt: action.payload, loadingDebts: false };

	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, payment: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false };
	default:
		return state;
	}
};

function PaymentDetails() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;

	const [{ loading, payment, loadingDebts, debt }, dispatch] = useReducer(
		reducer,
		{
			debt: {},
			loadingDebts: true,
			payment: {},
			loading: true,
		}
	);
	const [date, setDate] = useState(new Date());

	useEffect(() => {
		fetchData();
	}, [id]);

	const fetchData = async () => {
		await fetchPayment();
		await fetchDebts();
	};

	const fetchPayment = async () => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const result = await axios.get(`/api/payments/${id}`);
			dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			setDate(result.data.date);
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
		}
	};

	const fetchDebts = async () => {
		dispatch({ type: 'FETCH_DEBTS_REQUEST' });
		try {
			const result = await axios.get(`/api/payments/debt/${id}`);
			dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: result.data });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
		}
	};

	const getDebtName = () => {
		let debtName = '';
		const owner = debt.owner;
		if (owner) {
			const ownerName = owner.name;
			debtName = `${ownerName} : ${Math.round(debt.amount)}`;
		}
		return debtName;
	};

	return (
		<div>
			{(loading || loadingDebts) && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Container className="large-container">
				<Helmet>
					<title>Detalles del Pago</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Detalles del Pago
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlId="amount">
													<Form.Label>Monto</Form.Label>
													<Form.Control
														type="number"
														disabled
														defaultValue={payment.amount}
													></Form.Control>
												</Form.Group>

												<Form.Group className="mb-3" controlId="date">
													<Form.Label>Fecha</Form.Label>
													<Form.Control
														type="text"
														disabled
														defaultValue={moment(new Date(date)).format(
															'DD/MM/YY'
														)}
													></Form.Control>
												</Form.Group>

												<Form.Group className="mb-3" controlId="date">
													<Form.Label>Deuda</Form.Label>
													<Form.Control
														type="text"
														disabled
														defaultValue={getDebtName()}
													></Form.Control>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default PaymentDetails;
