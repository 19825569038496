import { SkeletonText } from '@chakra-ui/react';
import {
	Autocomplete,
	GoogleMap,
	Marker,
	useJsApiLoader,
} from '@react-google-maps/api';
import axios from 'axios';
import React, {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react';
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Geocode from 'react-geocode';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import Stepper from '../../components/Stepper/Stepper';

import { GoogleMapsApiKey, getAddress, getError, getStatus } from '../../utils';
import './EditContact.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_ACCOUNTS_FAIL':
		return { ...state, loadingAccounts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, contact: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
		//#region GOOGLE API ACTIONS
	case 'SET_GMAP':
		return { ...state, gMap: action.payload };

		//#endregion
	default:
		return state;
	}
};

function EditContact({ contactId, show, onHide, onSuccess }) {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: GoogleMapsApiKey,
		libraries: ['places', 'geometry'],
	});
	Geocode.setApiKey(GoogleMapsApiKey);
	const navigate = useNavigate();

	const [
		{  accounts, loadingAccounts },
		dispatch,
	] = useReducer(reducer, {
		accounts: [],
		loadingAccounts: true,
		contact: {},
		loading: true,
		error: '',
		//#region GOOGLE API STATES
		gMap: null,
		center: {},
		map: null,
		//#endregion
	});
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	//#region USER DATA
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState();
	const [account, setAccount] = useState('');
	const [destination, setDestination] = useState(null);
	const [destinationValue, setDestinationValue] = useState('');
	//#endregion

	const [autocomplete, setAutocomplete] = useState(null);
	useEffect(() => {
		if (!userInfo || (userInfo.isAdmin && !userInfo.isSuperAdmin)) {
			navigate('/');
			return;
		}
		if (show) {
			if (userInfo.isSuperAdmin) {
				fetchAccounts();
			}
			if (contactId) {
				fetchData();
			} else if (!userInfo.isSuperAdmin) {
				setAccount(userInfo.account);
			}
		}
	}, [show]);
	//#region
	const hideModal = () => {
		onHide();
		resetInfo();
	};
	const resetInfo = () => {
		setActiveStep(1);
		setName('');
		setEmail('');
		setPhone('');
		setAccount('');
		setDestinationValue('');
		setDestination(null);
	};
	//#region REQUESTS
	const fetchAccounts = async () => {
		dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
		try {
			const result = await axios.get('/api/accounts', {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};
	const fetchData = async () => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const result = await axios.get(`/api/contacts/${contactId}`);
			dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			if (
				userInfo.isSuperAdmin ||
        result.data.account._id == userInfo.account
			) {
				setName(result.data.name);
				setEmail(result.data.email);
				setPhone(result.data.phone);
				setAccount(result.data.account._id || '');
				if (result.data.destination) {
					const addresses = await getAddress(result.data.destination);
					setDestination(result.data.destination);
					setDestinationValue(addresses[0].formatted_address);
				}
			} else {
				navigate('/');
				return;
			}
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
		}
	};
	const submitContactHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const requestBody = {
				name,
				email,
				phone,
				account,
				destination,
				createdBy: userInfo._id,
			};
			const requestConfig = {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			};
			contactId
				? axios.put(`/api/contacts/${contactId}`, requestBody, requestConfig)
				: axios.post('/api/contacts', requestBody, requestConfig);

			toast.success(
				`Contacto ${contactId ? 'actualizado' : 'creado'} correctamente.`
			);
			onSuccess();
			resetInfo();
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};
	//#endregion
	//#region map handlers
	const onPlaceChanged = () => {
		if (autocomplete) {
			const originPlace = autocomplete.getPlace();
			if (originPlace.geometry) {
				setDestination({
					lat: originPlace.geometry.location.lat(),
					lng: originPlace.geometry.location.lng(),
					show: true,
				});
				setDestinationValue(originPlace.formatted_address);
			} else {
				setDestination(null);
			}
		} else {
			console.warning(
				'El servicio de mapas no ha terminado de cargar. Espere por favor.'
			);
		}
	};
	const onEditDestination = async (evt) => {
		const lat = evt.latLng.lat();
		const lng = evt.latLng.lng();
		setDestination({
			lat,
			lng,
		});
		try {
			const results = await getAddress({ lat, lng });
			setDestinationValue(results[0].formatted_address);
		} catch (ex) {
			console.error(ex);
		}
	};
	//#endregion
	//#region Stepper
	const [activeStep, setActiveStep] = useState(1);
	const steps = [
		{
			counter: 1,
			name: 'Ingresar datos',
		},
		{
			counter: 2,
			name: 'Confirmar ubicación',
		},
		{
			counter: 3,
			name: 'Resumen',
		},
	];
	function nextStep() {
		if (activeStep < steps.length) {
			setActiveStep(activeStep + 1);
		}
	}
	function previousStep() {
		if (activeStep > 1) {
			setActiveStep(activeStep - 1);
		}
	}
	//#endregion
	if (!isLoaded) {
		return <SkeletonText />;
	}
	return (
		<div>
			{loadingAccounts && <LoadingBox></LoadingBox>}

			<Modal
				dialogClassName="contact-details-modal edit"
				size="xl"
				show={show}
				onHide={hideModal}
				animation={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span className="contact-details-title">{`${
							contactId ? 'Editar' : 'Crear'
						} contacto`}</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wizard-wrapper">
					<div className="mb-3 mt-md-4">
						<Stepper steps={steps} activeStep={activeStep} />
						<Carousel
							activeIndex={activeStep - 1}
							controls={false}
							indicators={false}
						>
							<Carousel.Item>
								<div className="mb-3">
									<Form onSubmit={(e) => e.preventDefault()}>
										<Form.Group className="mb-3" controlid="name">
											<Form.Label>Nombre:</Form.Label>
											<Form.Control
												type="text"
												required
												value={name}
												onChange={(e) => setName(e.target.value)}
											></Form.Control>
										</Form.Group>
										<Form.Group className="mb-3" controlid="email">
											<Form.Label>E-mail</Form.Label>
											<Form.Control
												type="email"
												required
												value={email}
												onChange={(e) => setEmail(e.target.value.toLowerCase())}
											></Form.Control>
										</Form.Group>
										<Form.Group
											className="form-group-select mb-2"
											controlId="location-autocomplete"
										>
											<Form.Label>Selecciona un destino</Form.Label>
											<Autocomplete
												className="autocomplete-container"
												onLoad={(instance) => setAutocomplete(instance)}
												onPlaceChanged={onPlaceChanged}
												restrictions={{
													country: ['uy'],
												}}
											>
												<Form.Control
													value={destinationValue}
													onChange={(e) => setDestinationValue(e.target.value)}
													type="text"
													placeholder="Destino"
													className="w-100"
													required
												/>
											</Autocomplete>
										</Form.Group>

										<Form.Group className="mb-3" controlid="phone">
											<Form.Label>Telefono</Form.Label>
											<Form.Control
												type="text"
												value={phone}
												onChange={(e) => setPhone(e.target.value)}
											></Form.Control>
										</Form.Group>

										{userInfo && userInfo.isSuperAdmin && (
											<>
												<Form.Label>Selecciona la cuenta del contacto</Form.Label>
												<Form.Group className="mb-3 border" controlid="account">
													<Form.Control
														as="select"
														value={account}
														onChange={(e) => setAccount(e.target.value)}
													>
														<option key={0} value={''}>
                            ...
														</option>
														{accounts.map((account) => {
															return (
																<option key={account._id} value={account._id}>
																	{account.name}
																</option>
															);
														})}
													</Form.Control>
												</Form.Group>
											</>
										)}
									</Form>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								{destination && (
									<div className="google-map-container">
										<GoogleMap
											center={destination}
											zoom={15}
											mapContainerStyle={{
												width: '100%',
												height: '100%',
											}}
											options={{
												zoomControl: true,
												streetViewControl: false,
												mapTypeControl: false,
												fullscreenControl: false,
												scaleControl: true,
											}}
											onLoad={(map) =>
												dispatch({ type: 'SET_GMAP', payload: map })
											}
										>
											<Marker
												draggable
												onDragEnd={onEditDestination}
												position={{
													lat: destination.lat,
													lng: destination.lng,
												}}
											/>
										</GoogleMap>
									</div>
								)}
							</Carousel.Item>
							<Carousel.Item>
								<Form>
									<Form.Group className="mb-3">
										<Form.Label>Nombre:</Form.Label>
										<Form.Control
											readOnly
											type="text"
											value={name}
										></Form.Control>
									</Form.Group>
									<Form.Group className="mb-3" controlid="email">
										<Form.Label>E-mail :</Form.Label>
										<Form.Control type="email" required readOnly value={email} />
									</Form.Group>
									<Form.Group
										className="form-group-select mb-2"
										controlId="location-autocomplete"
									>
										<Form.Label>Destino</Form.Label>
										<Form.Control
											type="text"
											placeholder="Destino"
											className="w-100"
											readOnly
											value={destinationValue}
											required
										/>
									</Form.Group>

									<Form.Group className="mb-3" controlid="phone">
										<Form.Label>Telefono</Form.Label>
										<Form.Control type="text" value={phone} readOnly />
									</Form.Group>

									{userInfo && userInfo.isSuperAdmin && (
										<>
											<Form.Label>Cuenta del contacto</Form.Label>
											<Form.Group className="mb-3 border" controlid="account">
												<Form.Control
													as="select"
													value={account}
													readOnly
													disabled
												>
													{accounts.map((account) => {
														return (
															<option key={account._id} value={account._id}>
																{account.name}
															</option>
														);
													})}
												</Form.Control>
											</Form.Group>
										</>
									)}
								</Form>
							</Carousel.Item>
						</Carousel>

						<Stack
							className="mt-2 wizard-contact-button"
							direction="horizontal"
							gap={10}
						>
							<Button
								className="mx-auto"
								onClick={previousStep}
								disabled={activeStep === 1}
							>
              Atras
							</Button>

							<Button
								className="mx-auto wizard-contact-button"
								onClick={
									activeStep === steps.length ? submitContactHandler : nextStep
								}
								disabled={
									!name ||
                !email ||
                !destination ||
                !phone ||
                !account ||
                !destinationValue ||
                account === ''
								}
							>
								{activeStep === steps.length ? 'Guardar' : 'Siguiente'}
							</Button>
						</Stack>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
export default EditContact;
