import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Accordion,
	Button,
	Card,
	Col,
	Dropdown,
	DropdownButton,
	Modal,
	OverlayTrigger,
	Row,
	Table,
	Tooltip,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsFileEarmarkRichtext, BsXCircle } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, maxitemsPerPage } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			shipments: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_PENDING_SUCCESS':
		return {
			...state,
			pendingShipments: action.payload,
			loading: false,
			pendingItemQuantity: action.payload.length,
		};
	case 'FETCH_COMPLETED_SUCCESS':
		return {
			...state,
			completedShipments: action.payload,
			loading: false,
			completedItemQuantity: action.payload.length,
		};
	case 'FETCH_CANCELLED_SUCCESS':
		return {
			...state,
			cancelledShipments: action.payload,
			loading: false,
			cancelledItemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'FETCH_COMPANY_REQUEST':
		return { ...state, loadingCompany: true };
	case 'FETCH_COMPANY_SUCCESS':
		return { ...state, GotaSettings: action.payload, loadingCompany: false };
	case 'FETCH_COMPANY_FAIL':
		return { ...state, loadingCompany: false, error: action.payload };
	default:
		return state;
	}
};

function ShipmentList() {
	const [
		{
			loading,
			loadingCompany,
			error,
			successDelete,
			pendingShipments,
			completedShipments,
			cancelledShipments,
			pendingItemQuantity,
			completedItemQuantity,
			cancelledItemQuantity,
		},
		dispatch,
	] = useReducer(reducer, {
		pendingShipments: [],
		completedShipments: [],
		cancelledShipments: [],
		loading: true,
		loadingCompany: true,
		error: '',
		pendingItemQuantity: 0,
		completedItemQuantity: 0,
		cancelledItemQuantity: 0,
	});

	const params = useParams();
	const navigate = useNavigate();
	const { month } = params;
	const { state } = useContext(Store);
	const { userInfo } = state;

	//modal
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	//pagination

	//pagination PENDING
	const [currentPagePending, setCurrentPagePending] = useState(1);
	const indexOfLastPostPending = currentPagePending * maxitemsPerPage;
	const indexOfFirstPostPending = indexOfLastPostPending - maxitemsPerPage;
	let currentPostsPending = pendingShipments.slice(
		indexOfFirstPostPending,
		indexOfLastPostPending
	);

	//change page PENDNG
	const onPageChangePending = (pageNumber) => {
		setCurrentPagePending(pageNumber);
		currentPostsPending = pendingShipments.slice(
			indexOfFirstPostPending,
			indexOfLastPostPending
		);
	};

	//pagination COMPLETED
	const [currentPageCompleted, setCurrentPageCompleted] = useState(1);
	const indexOfLastPostCompleted = currentPageCompleted * maxitemsPerPage;
	const indexOfFirstPostCompleted = indexOfLastPostCompleted - maxitemsPerPage;
	let currentPostsCompleted = completedShipments.slice(
		indexOfFirstPostCompleted,
		indexOfLastPostCompleted
	);

	//change page COMPLETED
	const onPageChangeCompleted = (pageNumber) => {
		setCurrentPageCompleted(pageNumber);
		currentPostsCompleted = completedShipments.slice(
			indexOfFirstPostCompleted,
			indexOfLastPostCompleted
		);
	};

	//pagination CANCELLED
	const [currentPageCancelled, setCurrentPageCancelled] = useState(1);
	const indexOfLastPostCancelled = currentPageCancelled * maxitemsPerPage;
	const indexOfFirstPostCancelled = indexOfLastPostCancelled - maxitemsPerPage;
	let currentPostsCancelled = cancelledShipments.slice(
		indexOfFirstPostCancelled,
		indexOfLastPostCancelled
	);

	//change page CANCELLED
	const onPageChangeCancelled = (pageNumber) => {
		setCurrentPageCancelled(pageNumber);

		currentPostsCancelled = cancelledShipments.slice(
			indexOfFirstPostCancelled,
			indexOfLastPostCancelled
		);
	};

	//state variables
	const [shipmentToCancel, setShipmentToCancel] = useState({});
	const [filtroEnvios, setFiltroEnvios] = useState('Pending');
	const [title, setTitle] = useState('Envios Pendientes ');
	const myTitle = <h5 className="text-right d-inline-block">{title}</h5>;
	const [companyNumber, setCompanyNumber] = useState('');

	//GET ACCOUNT OF USER
	const getAccount = async () => {
		try {
			const { data } = await axios.get(`/api/accounts/${userInfo.account}`);
			getDebts(data._id);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//GET DEBTS OF ACCOUNT or USER BY MONTH
	const getDebts = async (accountId) => {
		if (month) {
			if (userInfo.userType === 'Gerente') {
				try {
					dispatch({ type: 'FETCH_REQUEST' });
					const response = await axios.get(
						`/api/debts/getDebtByAccountAndMonthCompleted/${accountId}/${month}`
					);
					dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: response.data });
					//getCompletedShipments()
				} catch (err) {
					dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				}
			} else {
				try {
					dispatch({ type: 'FETCH_REQUEST' });
					const response = await axios.get(
						`/api/debts/getDebtByUserCompleted/${userInfo._id}/${month}`
					);
					dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: response.data });
				} catch (err) {
					dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				}
			}
		} else {
			if (userInfo.userType === 'Gerente') {
				await getShipmentsByAccountAndStatus(accountId, 'Pending');
				await getShipmentsByAccountAndStatus(accountId, 'Completed');
				await getShipmentsByAccountAndStatus(accountId, 'Cancelled');
			} else {
				await getShipmentsByUserAndStatus('Pending');
				await getShipmentsByUserAndStatus('Completed');
				await getShipmentsByUserAndStatus('Cancelled');
			}
		}
	};

	const getShipmentsByAccountAndStatus = async (accountId, status) => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const response = await axios.get(
				`/api/debts/shipment/byAccountAndStatus/${accountId}/${status}`
			);
			if (status === 'Pending') {
				dispatch({ type: 'FETCH_PENDING_SUCCESS', payload: response.data });
			} else if (status === 'Completed') {
				dispatch({ type: 'FETCH_COMPLETED_SUCCESS', payload: response.data });
			} else if (status === 'Cancelled') {
				dispatch({ type: 'FETCH_CANCELLED_SUCCESS', payload: response.data });
			}
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const getShipmentsByUserAndStatus = async (status) => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const response = await axios.get(
				`/api/debts/shipment/byUserAndStatus/${userInfo._id}/${status}`
			);
			if (status === 'Pending') {
				dispatch({ type: 'FETCH_PENDING_SUCCESS', payload: response.data });
			} else if (status === 'Completed') {
				dispatch({ type: 'FETCH_COMPLETED_SUCCESS', payload: response.data });
			} else if (status === 'Cancelled') {
				dispatch({ type: 'FETCH_CANCELLED_SUCCESS', payload: response.data });
			}
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			getAccount();
			getCompanyData();
		}
	}, [successDelete]);

	const getCompanyData = async () => {
		try {
			dispatch({ type: 'FETCH_COMPANY_REQUEST' });
			const { data } = await axios.get('/api/config/public');
			setCompanyNumber(data.companyPhone);
			dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
		}
	};

	async function detailsShipmentHandler(shipment) {
		navigate(`/shipments/details/${shipment._id}`);
	}

	async function cancelShipmentModal(shipment) {
		await setShipmentToCancel(shipment);
		handleShow();
	}

	async function cancelShipment() {
		try {
			await axios.put(`/api/orders/changeStatus/${shipmentToCancel._id}`, {
				status: 'Cancelado',
			});
			toast.success('Envio Cancelado');
			window.location.reload();
		} catch (err) {
			toast.error(getError(err));
		}
	}

	return loading || loadingCompany ? (
		<Row
			className="d-flex justify-content-center align-items-center spinner-row"
			style={{ minHeight: '100vh' }}
		>
			<LoadingBox className="col-1" />
		</Row>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<div>
				<Modal
					size="lg"
					show={showModal}
					onHide={handleClose}
					aria-labelledby="example-modal-sizes-title-lg"
					animation={true}
				>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-lg">
              Cancelar Envio
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h2 className="mb-3">
              Confirme la cancelacion del siguiente envio:
						</h2>
						<Table responsive bordered size="sm">
							{shipmentToCancel ? (
								shipmentToCancel.deliveredBy ? (
									<p className="m-4">
                    El envio ya fue asignado para su entrega. Por favor
                    comuníquese al {companyNumber} para solicitar la
                    cancelación.
									</p>
								) : (
									<React.Fragment>
										<thead className="align-items-center table-order tHead">
											<tr className="align-items-center table-order text-center">
												<th className="tableHeader">Fecha Solicitada</th>
												<th className="tableHeader">Fecha Orden</th>
												<th className="tableHeader">Usuario Responsable</th>
												<th className="tableHeader">Tipo</th>
												<th className="tableHeader">Zona Destino</th>
												<th className="tableHeader">Estado</th>
											</tr>
										</thead>
										<tbody className="align-items-center table-order tableBodyHover">
											<tr>
												<td className="tableBody">
													{shipmentToCancel.shipmentDate
														? moment(
															new Date(shipmentToCancel.shipmentDate)
														).format('DD/MM/YY')
														: null}
												</td>
												<td className="tableBody">
													{shipmentToCancel.orderDate
														? moment(
															new Date(shipmentToCancel.orderDate)
														).format('DD/MM/YY')
														: null}
												</td>
												<td className="tableBody">
													{shipmentToCancel.createdBy
														? shipmentToCancel.createdBy.name
														: ''}
												</td>
												<td className="tableBody">
													{shipmentToCancel.type
														? shipmentToCancel.type.name
														: ''}
												</td>

												<td className="tableBody">
													{shipmentToCancel.route
														? shipmentToCancel.route.destinationAddress
															? shipmentToCancel.route.destinationAddress.zone
																? shipmentToCancel.route.destinationAddress.zone
																	.name
																: null
															: null
														: null}
												</td>

												<td className="tableBody">{shipmentToCancel.status}</td>
											</tr>
										</tbody>
									</React.Fragment>
								)
							) : null}
						</Table>
					</Modal.Body>
					{!shipmentToCancel.deliveredBy ? (
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
                Cerrar
							</Button>
							<Button className="cancelButton" onClick={cancelShipment}>
                Cancelar Envio
							</Button>
						</Modal.Footer>
					) : null}
				</Modal>
			</div>

			<div>
				<Helmet>
					<title>Lista de Envios</title>
				</Helmet>

				<div className="container admin-con">

					<div className="w-100">
						{/* <h1 className="text-center mt-5 mb-3">Lista de Envios</h1> */}

						<h2 className="text-center mb-3">
							{month ? moment(month).format('YYYY') : null}
						</h2>

						<Row className="mb-4">
							<Col className="d-flex justify-content-center align-items-center mt-3">
								<DropdownButton
									className="btn title-dropdown"
									align="end"
									title={myTitle}
								>
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Pending');
											setTitle('Envios Pendientes ');
										}}
									>
                    Envios Pendientes
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Completed');
											setTitle('Envios Completados ');
										}}
									>
                    Envios Completados
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Cancelled');
											setTitle('Envios Cancelados ');
										}}
									>
                    Envios Cancelados
									</Dropdown.Item>
								</DropdownButton>
							</Col>
						</Row>

						<React.Fragment>
							{filtroEnvios === 'Pending' ? (
								<div>
									<h6 className="m-3">
										<b>Envios Pendientes ({pendingItemQuantity})</b>
									</h6>
									<Card className="mb-3">
										{loading ? (
											<Row className="justify-content-center spinner-row">
												<LoadingBox className="col-1" />
											</Row>
										) : error ? (
											<MessageBox variant="danger">{error}</MessageBox>
										) : (
											<Accordion
												className="bg-transparent"
												defaultActiveKey={currentPostsPending.map(
													(debt, i) => i
												)}
												alwaysOpen
											>
												{currentPostsPending.map((debt, i) => {
													return debt.shipment ? (
														<Card className="mb-2 bg-black">
															<Accordion.Item eventKey={i}>
																<Accordion.Header className="table-container">
																	<Link
																		to={`/shipments/details/${debt.shipment._id}`}
																	>
																		{(debt.owner ? debt.owner.name : null) +
                                      ': ' +
                                      (debt.shipment.shipmentDate
                                      	? moment(
                                      		new Date(debt.shipment.shipmentDate)
                                      	).format('DD/MM/YY')
                                      	: 'Antes Posible')}
																	</Link>
																</Accordion.Header>

																<Accordion.Body>
																	<p className="mb-1">
																		<b>Fecha de Orden: </b>{' '}
																		{debt.shipment.orderDate
																			? moment(
																				new Date(debt.shipment.orderDate)
																			).format('DD/MM/YY')
																			: null}
																	</p>
																	<p className="mb-1">
																		<b>Fecha Solicitada: </b>{' '}
																		{debt.shipment.shipmentDate
																			? moment(
																				new Date(debt.shipment.shipmentDate)
																			).format('DD/MM/YY')
																			: null}
																	</p>
																	<p className="mb-1">
																		<b>Usuario Responsable: </b>{' '}
																		{debt.shipment.createdBy
																			? debt.shipment.createdBy.name
																			: null}
																	</p>
																	<p className="mb-1">
																		<b>Tipo de Envio: </b>{' '}
																		{debt.shipment.type
																			? debt.shipment.type.name
																			: ''}
																	</p>
																	<p className="mb-1">
																		<b>Zona de Destino: </b>{' '}
																		{debt.shipment.route.destinationAddress
																			? debt.shipment.route.destinationAddress
																				.zone
																				? debt.shipment.route.destinationAddress
																					.zone.name
																				: null
																			: null}
																	</p>
																	<p className="mb-1">
																		<b>Repartidor: </b>{' '}
																		{debt.shipment.deliveredBy
																			? debt.shipment.deliveredBy.name
																			: 'Sin Asignar'}
																	</p>
																	<p className="mb-1">
																		<b>Estado: </b> {debt.shipment.status}
																	</p>
																	{debt.shipment.additionalDetails && (
																		<>
																			<p className="mb-1">
																				<b>Cliente:</b>{' '}
																				{
																					debt.shipment.additionalDetails
																						.clientName
																				}{' '}
																			</p>
																			<p className="mb-1">
																				<b>Teléfono de Contacto: </b>{' '}
																				{
																					debt.shipment.additionalDetails
																						.clientPhone
																				}
																			</p>
																			<p className="mb-1">
																				<b>Cantidad de Bultos:</b>
																				{
																					debt.shipment.additionalDetails
																						.itemQuantity
																				}{' '}
																			</p>
																			<p className="mb-1">
																				<b>Comentarios</b>{' '}
																				{debt.shipment.additionalDetails
																					.comments || '-'}
																			</p>
																		</>
																	)}
																	{userInfo ? (
																		<React.Fragment>
																			<div>
																				<Button
																					className="cancelButton mt-2  "
																					onClick={() =>
																						cancelShipmentModal(debt.shipment)
																					}
																				>
																					<BsXCircle /> Cancelar
																				</Button>
																			</div>
																		</React.Fragment>
																	) : null}
																</Accordion.Body>
															</Accordion.Item>
														</Card>
													) : null;
												})}
											</Accordion>
										)}
									</Card>
									<Pagination
										className="pagination-bar"
										totalCount={pendingItemQuantity}
										onPageChange={onPageChangePending}
										currentPage={currentPagePending}
										pageSize={maxitemsPerPage}
									></Pagination>
								</div>
							) : null}

							{filtroEnvios === 'Completed' ? (
								<div>
									<h6 className="m-3">
										<b>Envios Completados ({completedItemQuantity})</b>
									</h6>
									<Card className="mb-3">
										<Table
											striped
											bordered
											hover
											responsive
											size="sm"
											className="mb-0"
										>
											<thead className="align-items-center table-order tHead">
												<tr className="align-items-center table-order text-center">
													<th className="tableHeader">Fecha de Entrega</th>
													<th className="tableHeader">Responsable Orden</th>
													<th className="tableHeader">Tipo</th>
													<th className="tableHeader">Recepcion</th>
													<th className="tableHeader">Zona Destino</th>
													<th className="tableHeader">Costo</th>
													<th className="tableHeader"></th>
												</tr>
											</thead>

											{loading ? (
												<Row className="justify-content-center spinner-row">
													<LoadingBox className="col-1" />
												</Row>
											) : error ? (
												<MessageBox variant="danger">{error}</MessageBox>
											) : (
												currentPostsCompleted.map((debt, i) => {
													return debt.shipment ? (
														<tbody className="align-items-center table-order tableBodyHover">
															<tr id="data" key={debt._id}>
																<td className="tableBody">
																	{debt.shipment.deliveredDate
																		? moment(
																			new Date(debt.shipment.deliveredDate)
																		).format('DD/MM/YY')
																		: null}
																</td>

																<td className="tableBody">
																	{debt.shipment.createdBy
																		? debt.shipment.createdBy.name
																		: ''}
																</td>

																<td className="tableBody">
																	{debt.shipment.type
																		? debt.shipment.type.name
																		: ''}
																</td>

																<td className="tableBody">
																	{debt.shipment.recipientName
																		? debt.shipment.recipientName
																		: ''}
																</td>

																<td className="tableBody">
																	{debt.shipment.route.destinationAddress
																		? debt.shipment.route.destinationAddress
																			.zone
																			? debt.shipment.route.destinationAddress
																				.zone.name
																			: null
																		: null}
																</td>

																<td className="tableBody">
																	{Math.round(debt.amount)}
																</td>

																<td className="tableBody">
																	<OverlayTrigger
																		key="top"
																		placement={i === 0 ? 'left' : 'top'}
																		overlay={
																			<Tooltip id={'tooltip-top'}>
                                        Ver Detalles
																			</Tooltip>
																		}
																	>
																		<Button
																			className="seeDetailsButton"
																			onClick={() =>
																				detailsShipmentHandler(debt.shipment)
																			}
																		>
																			<BsFileEarmarkRichtext />
																		</Button>
																	</OverlayTrigger>
																</td>
															</tr>
														</tbody>
													) : null;
												})
											)}
										</Table>
									</Card>
									<Pagination
										className="pagination-bar"
										totalCount={completedItemQuantity}
										onPageChange={onPageChangeCompleted}
										currentPage={currentPageCompleted}
										pageSize={maxitemsPerPage}
									></Pagination>
								</div>
							) : null}

							{filtroEnvios === 'Cancelled' ? (
								<div>
									<h6 className="m-3">
										<b>Envios Cancelados ({cancelledItemQuantity})</b>
									</h6>
									<Card className="mb-3">
										<Table
											striped
											bordered
											hover
											responsive
											size="sm"
											className="mb-0"
										>
											<thead className="align-items-center table-order tHead">
												<tr className="align-items-center table-order text-center">
													<th className="tableHeader">Fecha Cancelacion</th>
													<th className="tableHeader">Responsable Orden</th>
													<th className="tableHeader">
                            Responsable Cancelacion
													</th>
													<th className="tableHeader">Tipo</th>
													<th className="tableHeader">Zona Destino</th>
													<th className="tableHeader"></th>
												</tr>
											</thead>

											{loading ? (
												<Row className="justify-content-center spinner-row">
													<LoadingBox className="col-1" />
												</Row>
											) : error ? (
												<MessageBox variant="danger">{error}</MessageBox>
											) : (
												currentPostsCancelled.map((shipment, i) => {
													return shipment ? (
														<tbody className="align-items-center table-order tableBodyHover">
															<tr id="data" key={shipment._id}>
																<td className="tableBody">
																	{shipment.cancelledDate
																		? moment(
																			new Date(shipment.cancelledDate)
																		).format('DD/MM/YY')
																		: null}
																</td>

																<td className="tableBody">
																	{shipment.createdBy
																		? shipment.createdBy.name
																		: ''}
																</td>

																<td className="col-lg-1 tableBody">
																	{shipment.cancelledBy
																		? shipment.cancelledBy.name
																		: ''}
																</td>

																<td className="tableBody">
																	{shipment.type ? shipment.type.name : ''}
																</td>

																<td className="tableBody">
																	{shipment.route.destinationAddress
																		? shipment.route.destinationAddress.zone
																			? shipment.route.destinationAddress.zone
																				.name
																			: null
																		: null}
																</td>

																<td className="tableBody">
																	<OverlayTrigger
																		key="top"
																		placement={i === 0 ? 'left' : 'top'}
																		overlay={
																			<Tooltip id={'tooltip-top'}>
                                        Ver Detalles
																			</Tooltip>
																		}
																	>
																		<Button
																			className="seeDetailsButton"
																			onClick={() =>
																				detailsShipmentHandler(shipment)
																			}
																		>
																			<BsFileEarmarkRichtext />
																		</Button>
																	</OverlayTrigger>
																</td>
															</tr>
														</tbody>
													) : null;
												})
											)}
										</Table>
									</Card>
									<Pagination
										className="pagination-bar"
										totalCount={cancelledItemQuantity}
										onPageChange={onPageChangeCancelled}
										currentPage={currentPageCancelled}
										pageSize={maxitemsPerPage}
									></Pagination>
								</div>
							) : null}
							{/* )} */}
						</React.Fragment>
					</div>
					<div></div>
				</div>
			</div>
		</>
	);
}

export default ShipmentList;
