import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError, getStatus } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTS_REQUEST':
		return { ...state, loadingDebts: true };
	case 'FETCH_DEBTS_SUCCESS':
		return { ...state, debt: action.payload, loadingDebts: false };
	case 'FETCH_DEBTS_FAIL':
		return { ...state, loadingDebts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, payment: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditPayment() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;

	const [{ loading, error, payment, loadingDebts, debt }, dispatch] =
    useReducer(reducer, {
    	debt: {},
    	loadingDebts: true,
    	payment: {},
    	loading: true,
    	error: '',
    });
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [amount, setAmount] = useState(null);
	const [debtId, setDebtId] = useState('');
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		fetchData();
	}, [id]);

	const fetchData = async () => {
		await fetchPayment();
		await fetchDebts();
	};

	const fetchPayment = async () => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const result = await axios.get(`/api/payments/${id}`);
			dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			setAmount(result.data.amount);
			setDebtId(result.data.debt._id);
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
		}
	};

	const fetchDebts = async () => {
		dispatch({ type: 'FETCH_DEBTS_REQUEST' });
		try {
			const result = await axios.get(`/api/payments/debt/${id}`);
			dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: result.data });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
		}
	};

	const getDebtName = () => {
		let debtName = '';
		const owner = debt.owner;
		if (owner) {
			const ownerName = owner.name;
			debtName = `${ownerName} : ${debt.amount}`;
		}
		return debtName;
	};

	const editPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await axios.put(
				`/api/payments/${payment._id}`,
				{
					amount,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			localStorage.setItem('payment', JSON.stringify(data));

			toast.success('Pago actualizado Correctamente');
			navigate('/payments');
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	return (
		<div>
			{(loading || loadingDebts) && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={editPaymentHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>Editar Pago</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Editar Pago de Deuda {debtId}
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlId="amount">
													<Form.Label>Monto</Form.Label>
													<Form.Control
														type="number"
														defaultValue={payment.amount}
														onChange={(e) => setAmount(e.target.value)}
													></Form.Control>
												</Form.Group>

												<Form.Group className="mb-3" controlId="date">
													<Form.Label>Fecha</Form.Label>
													<Form.Control
														type="text"
														disabled
														defaultValue={payment.date}
													></Form.Control>
												</Form.Group>

												<Form.Group className="mb-3" controlId="date">
													<Form.Label>Deuda</Form.Label>
													<Form.Control
														type="text"
														disabled
														defaultValue={getDebtName()}
													></Form.Control>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
													>
                            Guardar Cambios
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default EditPayment;
