import axios from 'axios';
import moment from 'moment';
import React, {
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';

import {
	Accordion,
	Button,
	Card,
	Col,
	Dropdown,
	DropdownButton,
	OverlayTrigger,
	Row,
	Table,
	Tooltip,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsFileEarmarkRichtext } from 'react-icons/bs';
import { FaRoute } from 'react-icons/fa';
import { FcCancel, FcShipped } from 'react-icons/fc';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenerateRoutes from '../../components/ModalGenerateRoutes/GenerateRoutes';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { calculateDirectionsResponseUTILS } from '../../mapsUtils.js';
import { getError, maxitemsPerPage, sendNotification } from '../../utils';
import ConfirmShipmentModal from './ModalConfirmShipment/ModalConfirmShipment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };

	case 'FETCH_CANCEL_REQUEST':
		return { ...state, loadingCancelation: true };
	case 'FETCH_CANCEL_SUCCESS':
		return {
			...state,
			loadingCancelation: false,
	
		};

	case 'FETCH_PENDING_SUCCESS':
		return {
			...state,
			loading: false,
			pendingShipments: action.payload,
			pendingItemQuantity: action.payload.length,
		};
	case 'FETCH_ASIGNED_SUCCESS':
		return {
			...state,
			loading: false,

			asignedShipments: action.payload,
			asignedItemQuantity: action.payload.length,
		};
	case 'FETCH_COMPLETED_SUCCESS':
		return {
			...state,
			loading: false,

			completedShipments: action.payload,
			completedItemQuantity: action.payload.length,
		};
	case 'FETCH_CANCELLED_SUCCESS':
		return {
			...state,
			loading: false,

			cancelledShipments: action.payload,
			cancelledItemQuantity: action.payload.length,
		};

	case 'UPDATE_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false };
	case 'SHOW_GENERATE_ROUTES':
		return { ...state, generateRoutesModal: true };
	case 'HIDE_GENERATE_ROUTES':
		return { ...state, generateRoutesModal: false };
	default:
		return state;
	}
};

const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	transition: 'border .3s ease-in-out',
};

function AdminShipmentList() {
	const [
		{
			loading,
			loadingDeliveries,
			loadingCancelation,
			error,
			pendingShipments,
			pendingItemQuantity,
			asignedShipments,
			asignedItemQuantity,
			completedShipments,
			completedItemQuantity,
			cancelledShipments,
			cancelledItemQuantity,
			generateRoutesModal,
		},
		dispatch
	] = useReducer(reducer, {
		pendingShipments: [],
		asignedShipments: [],
		completedShipments: [],
		cancelledShipments: [],
		loading: true,
		loadingDeliveries: false,
		loadingCancelation: false,
		generateRoutesModal: false,
		error: '',
		asignedItemQuantity: 0,
		pendingItemQuantity: 0,
		completedItemQuantity: 0,
		cancelledItemQuantity: 0,
	});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    	navigator.userAgent
    );

	//state variables
	const [shipmentToConfirm, setShipmentToConfirm] = useState({});
	const [name, setName] = useState('');
	const [document, setDocument] = useState('');
	const [file, setFile] = useState(null);
	const [filtroEnvios, setFiltroEnvios] = useState('Pending');
	const [title, setTitle] = useState('Envios Pendientes ');

	const myTitle = <h5 className="text-right d-inline-block">{title}</h5>;

	//confirmation modal
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const handleCloseConfirm = () => setShowConfirmModal(false);
	const handleShowConfirm = () => setShowConfirmModal(true);

	//pagination PENDING
	const [currentPagePending, setCurrentPagePending] = useState(1);
	const indexOfLastPostPending = currentPagePending * maxitemsPerPage;
	const indexOfFirstPostPending = indexOfLastPostPending - maxitemsPerPage;
	let currentPostsPending = pendingShipments.slice(
		indexOfFirstPostPending,
		indexOfLastPostPending
	);

	//change page PENDNG
	const onPageChangePending = (pageNumber) => {
		setCurrentPagePending(pageNumber);

		currentPostsPending = pendingShipments.slice(
			indexOfFirstPostPending,
			indexOfLastPostPending
		);
	};
	//pagination ASIGNED
	const [currentPageAsigned, setCurrentPageAsigned] = useState(1);
	const indexOfLastPostAsigned = currentPageAsigned * maxitemsPerPage;
	const indexOfFirstPostAsigned = indexOfLastPostAsigned - maxitemsPerPage;
	let currentPostsAsigned = asignedShipments.slice(
		indexOfFirstPostAsigned,
		indexOfLastPostAsigned
	);

	//change page ASIGNED
	const onPageChangeAsigned = (pageNumber) => {
		setCurrentPageAsigned(pageNumber);

		currentPostsAsigned = asignedShipments.slice(
			indexOfFirstPostAsigned,
			indexOfLastPostAsigned
		);
	};

	//pagination COMPLETED
	const [currentPageCompleted, setCurrentPageCompleted] = useState(1);
	const indexOfLastPostCompleted = currentPageCompleted * maxitemsPerPage;
	const indexOfFirstPostCompleted = indexOfLastPostCompleted - maxitemsPerPage;
	let currentPostsCompleted = completedShipments.slice(
		indexOfFirstPostCompleted,
		indexOfLastPostCompleted
	);

	//change page COMPLETED
	const onPageChangeCompleted = (pageNumber) => {
		setCurrentPageCompleted(pageNumber);
		currentPostsCompleted = completedShipments.slice(
			indexOfFirstPostCompleted,
			indexOfLastPostCompleted
		);
	};

	//pagination CANCELLED
	const [currentPageCancelled, setCurrentPageCancelled] = useState(1);
	const indexOfLastPostCancelled = currentPageCancelled * maxitemsPerPage;
	const indexOfFirstPostCancelled = indexOfLastPostCancelled - maxitemsPerPage;
	let currentPostsCancelled = cancelledShipments.slice(
		indexOfFirstPostCancelled,
		indexOfLastPostCancelled
	);

	//change page CANCELLED
	const onPageChangeCancelled = (pageNumber) => {
		setCurrentPageCancelled(pageNumber);

		currentPostsCancelled = cancelledShipments.slice(
			indexOfFirstPostCancelled,
			indexOfLastPostCancelled
		);
	};

	useMemo(() => ({ ...baseStyle }), []);

	useEffect( () => {
		const fetchData = async () => {
			if (userInfo && userInfo.isAdmin) {
				await getDebts();
			} else {
				navigate('/');
			}
		  }; 
		fetchData();
	}, [userInfo, loadingCancelation]);

	//GET ALL DEBTS
	const getDebts = async () => {
		try {
			await getPendingShipments();
			await getAsignedShipments();
			await getCompletedShipments();
			await getCancelledShipments();
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//PENDING SHIPMENTS
	const getPendingShipments = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const status = 'Pending';
			const response = await axios.post(
				`/api/debts/shipment/byStatus/${status}`,
				{ user: userInfo }
			);

			dispatch({ type: 'FETCH_PENDING_SUCCESS', payload: response.data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//PENDING SHIPMENTS
	const getAsignedShipments = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const status = 'Asigned';
			const response = await axios.post(
				`/api/debts/shipment/byStatus/${status}`,
				{ user: userInfo }
			);
			dispatch({ type: 'FETCH_ASIGNED_SUCCESS', payload: response.data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//COMPLETED SHIPMENTS
	const getCompletedShipments = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const status = 'Completed';
			const response = await axios.post(
				`/api/debts/shipment/byStatus/${status}`,
				{ user: userInfo }
			);

			dispatch({ type: 'FETCH_COMPLETED_SUCCESS', payload: response.data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//CANCELLED SHIPMENTS
	const getCancelledShipments = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const status = 'Cancelled';
			const response = await axios.post(
				`/api/debts/shipment/byStatus/${status}`,
				{ user: userInfo }
			);
			dispatch({ type: 'FETCH_CANCELLED_SUCCESS', payload: response.data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//COMPLETAR ENVIO
	const completeShipmentHandler = async (shipment) => {
		 setShipmentToConfirm(shipment);
		await handleShowConfirm();
	};

	const completeShipment = async () => {
		if (name !== '' && document !== '') {
			let formData = null;
			let multiPart = null;
			if (file) {
				formData = new FormData();
				let r = Math.random().toString(36).substring(7);
				formData.append('file', file, file.name.replaceAll(' ') + r);
				formData.append('name', name);
				formData.append('document', document);
				multiPart = { 'content-type': 'multipart/form-data' };
			}
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				await axios.post(
					`/api/orders/completeShipment/${shipmentToConfirm._id}`,
					formData,
					{
						...multiPart,
						headers: {
							Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
						},
					}
				);
				dispatch({ type: 'UPDATE_SUCCESS' });
				toast.success('Entrega confirmada');
				handleCloseConfirm();
				window.location.reload();
			} catch (err) {
				toast.error(getError(err));
			}
		} else {
			if (name === '') {
				toast.error('Ingrese nombre del receptor');
			} else if (document === '') {
				toast.error('Ingrese documento del receptor');
			}
		}
	};

	async function detailsShipmentHandler(shipment) {
		navigate(`/AdminScreen/shipments/details/${shipment._id}`);
	}

	async function editStatusHandler(order, status) {
		const posibleStatus = [
			'Ingresado',
			'Confirmado',
			'Asignado',
			'Entregado',
			'En Camino',
			'Cancelacion Pendiente',
			'Cancelado',
		];
		if (posibleStatus.includes(status)) {
			try {
				await axios.put(`/api/orders/changeStatus/${order._id}`, {
					status,
					userInfo,
				});
				window.location.reload();
			} catch (err) {
				toast.error(getError(err));
			}
		}
	}

	async function cancelAsignedOrder(order) {
		
		
		if(order.status === 'Asignado'){
			//ENCONTRAR RUTA EN LA QUE SE ENCUENTRA
			dispatch({ type: 'FETCH_CANCEL_REQUEST' });
			try {
				//DIVIDO FUNCION
				//#1: ME DEVUELVE NUEVO ARRAY DE ORDERS, OLD DAILYROUTE Y ORIGIN
				const response = await axios.get(`/api/orderDailyRouteRelation/removeOrderFromArray/${order._id}`, {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
				);
				const newArray = response.data.newArray;
				const oldDailyRoute = response.data.oldDailyRoute;
				const origin = response.data.origin;

				//#2: ELIMINO RUTA VIEJA
				await axios.delete(
					`/api/dailyRoutes/byId/${oldDailyRoute._id}`,
					{
										  headers: {
							Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
										  },
					}
				);
				//#3: IF NEW ARRAY 
				
				if(newArray.length > 0){
					//#3.1: CALCULO NUEVA RUTA CON MAPUTILS --> manteniendo orden (optimizeWaypoints = false)
					const newRoute = await calculateDirectionsResponseUTILS(
						newArray,
						origin,
						false
					);


					//#3.2: CREO NUEVA RUTA, 
					
					const newDailyRoute = {
								  routeDate: oldDailyRoute.date,
								  createdBy: userInfo,
								  sortedOrders: newRoute.sortedOrders,
								  sortedOrderInfo: newRoute.sortedOrderInfo,
								  vehicle: newRoute.vehicle,
								  time: newRoute.totalTime,
								  distance: newRoute.totalDistance,
								  originMarker: newRoute.originMarker
					};

					await axios.post(
								  '/api/dailyRoutes/createDailyRoute',
								  {
							dailyRoute: newDailyRoute,
								  }
								  );
				}
				
				//#4: CAMBIO DE ESTADO 
				await axios.put(`/api/orders/changeStatus/${order._id}`, {
					status: 'Cancelado',
					userInfo,
						  });
				  

				//#5 ENVIAR NOTIFICACION:

				await sendNotification(order._id, userInfo.phoneNumber);



				dispatch({ type: 'FETCH_CANCEL_SUCCESS' });
				//window.location.reload();
					
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}}
	}
	const onDrop = (acceptedFiles) => {
		setFile(acceptedFiles);
	};

	//#region MODALS

	async function generateRoutes() {
		dispatch({ type: 'SHOW_GENERATE_ROUTES' });
	}
	//#endregion

	return loading || loadingDeliveries ? (
		<Row
			className="d-flex justify-content-center align-items-center spinner-row"
			style={{ minHeight: '100vh' }}
		>
			<LoadingBox className="col-1" />
		</Row>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<div>
				<ConfirmShipmentModal
					show={showConfirmModal}
					onHide={handleCloseConfirm}
					onConfirm={completeShipment}
					isMobile={isMobile}
					name={name}
					setName={setName}
					document={document}
					setDocument={setDocument}
					file={file}
					setFile={setFile}
					onDrop={onDrop}
				/>
			</div>

			<div>
				<Helmet>
					<title>Lista de Envios</title>
				</Helmet>
				{userInfo && userInfo.isSuperAdmin ? (
					<GenerateRoutes
						show={generateRoutesModal}
						onHide={() => dispatch({ type: 'HIDE_GENERATE_ROUTES' })}
						onSuccess={() => dispatch({ type: 'RELOAD_CONTACTS' })}
					/>
				) : null}
				
				<div className="container admin-con">
					<div className="w-100">
						<div
							style={{
								display: 'flex',
								justifyContent: 'right',
								alignItems: 'center',
							}}
						>
							<Button
								className="btn btn-dark m-1 mt-2 fixed-right"
								value="Generar Ruteo"
								onClick={() => generateRoutes()}
							>
								<span>
									<FaRoute /> Generar Rutas
								</span>
							</Button>
						</div>
						<Row className="mb-4">
							<Col className="d-flex justify-content-center align-items-center ">
								<DropdownButton
									className="btn title-dropdown"
									align="end"
									title={myTitle}
								>
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Pending');
											setTitle('Envios Pendientes ');
										}}
									>
                    Envios Pendientes
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Asigned');
											setTitle('Envios Asignados ');
										}}
									>
                    Envios Asignados
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Completed');
											setTitle('Envios Completados ');
										}}
									>
                    Envios Completados
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Cancelled');
											setTitle('Envios Cancelados ');
										}}
									>
                    Envios Cancelados
									</Dropdown.Item>
								</DropdownButton>
							</Col>
						</Row>

						<React.Fragment>
							{filtroEnvios === 'Pending' ? (
								<div className="card-container">
									<h6 className="m-3">
										<b>Envios Pendientes ({pendingItemQuantity})</b>
									</h6>
									<Card className="acc-container">
										{loading ? (
											<Row className="justify-content-center spinner-row">
												<LoadingBox className="col-1" />
											</Row>
										) : error ? (
											<MessageBox variant="danger">{error}</MessageBox>
										) : (
											<Accordion
												className="bg-transparent ml-5"
												defaultActiveKey={currentPostsPending.map(
													(debt, i) => i
												)}
												alwaysOpen
											>
												{currentPostsPending.map((debt, i) => {
													return debt.shipment ? (
														<Card className="mb-2 bg-black" key={i}>
															<Accordion.Item eventKey={i}>
																<Accordion.Header className="table-container">
																	<Link
																		to={`/AdminScreen/shipments/details/${debt.shipment._id}`}
																	>
																		{'Cuenta: ' +
                                      (debt.owner ? debt.owner.name : null) +
                                      ' | Fecha de entrega: ' +
                                      (debt.shipment.shipmentDate
                                      	? moment(
                                      		new Date(debt.shipment.shipmentDate)
                                      	).format('DD/MM/YY')
                                      	: 'Antes Posible')}
																	</Link>
																</Accordion.Header>

																<Accordion.Body>
																	<p className="mb-1">
																		<b>Usuario Responsable: </b>{' '}
																		{debt.shipment.createdBy
																			? debt.shipment.createdBy.name
																			: null}
																	</p>
																	<p className="mb-1">
																		<b>Tipo de Envio: </b>{' '}
																		{debt.shipment.type
																			? debt.shipment.type.name
																			: ''}
																	</p>
																	<p className="mb-1">
																		<b>Zona de Destino: </b>{' '}
																		{debt.shipment.route.destinationAddress
																			? debt.shipment.route.destinationAddress
																				.zone
																				? debt.shipment.route.destinationAddress
																					.zone.name
																				: null
																			: null}
																	</p>
																	<p className="mb-1">
																		<b>Vehiculo Sugerido: </b>{' '}
																		{debt.shipment.suggestedVehicle
																			? debt.shipment.suggestedVehicle.brand +
                                        ' ' +
                                        debt.shipment.suggestedVehicle.model +
                                        ' - ' +
                                        debt.shipment.suggestedVehicle
                                        	.registrationNumber
																			: 'Sin Asignar'}
																	</p>

																	{userInfo ? (
																		<div className="text-end">
																			{debt.shipment.status === 'Confirmado' ? (
																				<Button
																					drop="start"
																					title=""
																					onClick={() =>
																						completeShipmentHandler(
																							debt.shipment
																						)
																					}
																				>
																					<FcShipped /> Completar
																				</Button>
																			) : null}

																			<Button
																				disabled={
																					debt.shipment.status ===
                                            'Cancelado' ||
                                          !userInfo.isSuperAdmin
																				}
																				onClick={() =>
																					editStatusHandler(
																						debt.shipment,
																						'Cancelado'
																					)
																				}
																			>
																				<FcCancel /> Cancelar
																			</Button>
																		</div>
																	) : null}
																</Accordion.Body>
															</Accordion.Item>
														</Card>
													) : null;
												})}
											</Accordion>
										)}
									</Card>
									<Pagination
										className="pagination-bar"
										totalCount={pendingItemQuantity}
										onPageChange={onPageChangePending}
										currentPage={currentPagePending}
										pageSize={maxitemsPerPage}
									></Pagination>
								</div>
							) : null}

							{filtroEnvios === 'Asigned' && !loadingCancelation ? (
								<div className="card-container">
									<h6 className="m-3">
										<b>Envios Asignados ({asignedItemQuantity})</b>
									</h6>
									<Card className="acc-container">
										{loading ? (
											<Row className="justify-content-center spinner-row">
												<LoadingBox className="col-1" />
											</Row>
										) : error ? (
											<MessageBox variant="danger">{error}</MessageBox>
										) : (
											<Accordion
												className="bg-transparent ml-5"
												defaultActiveKey={currentPostsAsigned.map(
													(debt, i) => i
												)}
												alwaysOpen
											>
												{currentPostsAsigned.map((debt, i) => {
													return debt.shipment ? (
														<Card className="mb-2 bg-black" key={i}>
															<Accordion.Item eventKey={i}>
																<Accordion.Header className="table-container">
																	<Link
																		to={`/AdminScreen/shipments/details/${debt.shipment._id}`}
																	>
																		{'Cuenta: ' +
                                      (debt.owner ? debt.owner.name : null) +
                                      ' | Fecha de entrega: ' +
                                      (debt.shipment.shipmentDate
                                      	? moment(
                                      		new Date(debt.shipment.shipmentDate)
                                      	).format('DD/MM/YY')
                                      	: 'Antes Posible')}
																	</Link>
																</Accordion.Header>

																<Accordion.Body>
																	<p className="mb-1">
																		<b>Usuario Responsable: </b>{' '}
																		{debt.shipment.createdBy
																			? debt.shipment.createdBy.name
																			: null}
																	</p>
																	<p className="mb-1">
																		<b>Tipo de Envio: </b>{' '}
																		{debt.shipment.type
																			? debt.shipment.type.name
																			: ''}
																	</p>
																	<p className="mb-1">
																		<b>Zona de Destino: </b>{' '}
																		{debt.shipment.route.destinationAddress
																			? debt.shipment.route.destinationAddress
																				.zone
																				? debt.shipment.route.destinationAddress
																					.zone.name
																				: null
																			: null}
																	</p>
																	<p className="mb-1">
																		<b>Vehiculo: </b>{' '}
																		{debt.shipment.deliveryVehicle
																			? debt.shipment.deliveryVehicle.brand +
                                        ' ' +
                                        debt.shipment.deliveryVehicle.model +
                                        ' - ' +
                                        debt.shipment.deliveryVehicle
                                        	.registrationNumber
																			: 'Sin Asignar'}
																	</p>

																	{userInfo ? (
																		<div className="text-end">
																			{debt.shipment.status === 'Asignado' ? (
																				<Button
																					drop="start"
																					title=""
																					onClick={() =>
																						completeShipmentHandler(
																							debt.shipment
																						)
																					}
																				>
																					<FcShipped /> Completar
																				</Button>
																			) : null}

																			<Button
																				className='mx-1'
																				disabled={
																					debt.shipment.status ===
                                            'Cancelado' ||
                                          !userInfo.isSuperAdmin
																				}
																				onClick={() =>
																					cancelAsignedOrder(
																						debt.shipment
																					)
																				}
																			>
																				<FcCancel /> Cancelar
																			</Button>
																		</div>
																	) : null}
																</Accordion.Body>
															</Accordion.Item>
														</Card>
													) : null;
												})}
											</Accordion>
										)}
									</Card>
									<Pagination
										className="pagination-bar"
										totalCount={asignedItemQuantity}
										onPageChange={onPageChangeAsigned}
										currentPage={currentPageAsigned}
										pageSize={maxitemsPerPage}
									></Pagination>
								</div>
							) : null}

							{filtroEnvios === 'Completed' ? (
								<div>
									<h6 className="m-3">
										<b>Envios Completados ({completedItemQuantity})</b>
									</h6>
									<Card className="mb-3 table-container">
										<Table
											striped
											bordered
											hover
											responsive
											className="table-container"
										>
											<thead className="align-items-center table-order tHead">
												<tr className="align-items-center table-order text-center">
													<th className="col-lg-1 tableHeader">
                            Fecha de Entrega
													</th>
													<th className="col-lg-1 tableHeader">Cuenta</th>
													<th className="col-lg-1 tableHeader">Tipo</th>
													<th className="col-lg-1 tableHeader">Recibido</th>
													<th className="col-lg-1 tableHeader"></th>
												</tr>
											</thead>

											{loading ? (
												<Row className="justify-content-center spinner-row">
													<LoadingBox className="col-1" />
												</Row>
											) : error ? (
												<MessageBox variant="danger">{error}</MessageBox>
											) : (
												currentPostsCompleted.map((debt, i) => {
													return debt.shipment ? (
														<tbody className="align-items-center table-order tableBodyHover pointerCursor" key={i}>
															<tr
																id="data"
																key={debt._id}
																onClick={() =>
																	detailsShipmentHandler(debt.shipment)
																}
															>
																<td className="col-lg-1 tableBody">
																	{debt.shipment.deliveredDate
																		? moment(
																			new Date(debt.shipment.deliveredDate)
																		).format('DD/MM/YY')
																		: null}
																</td>
																<td className="col-lg-1 tableBody">
																	{debt.owner ? debt.owner.name : null}
																</td>

																<td className="col-lg-1 tableBody">
																	{debt.shipment.type
																		? debt.shipment.type.name
																		: ''}
																</td>
																<td className="col-lg-1 tableBody">
																	{debt.shipment.recipientName
																		? debt.shipment.recipientName
																		: ''}
																</td>

																<td className="col-lg-1 tableBody">
																	<OverlayTrigger
																		key="top"
																		placement={i === 0 ? 'left' : 'top'}
																		overlay={
																			<Tooltip id={'tooltip-top'}>
                                        Ver Detalles
																			</Tooltip>
																		}
																	>
																		<Button
																			className="seeDetailsButton"
																			onClick={() =>
																				detailsShipmentHandler(debt.shipment)
																			}
																		>
																			<BsFileEarmarkRichtext />
																		</Button>
																	</OverlayTrigger>
																</td>
															</tr>
														</tbody>
													) : null;
												})
											)}
										</Table>
									</Card>
									<Pagination
										className="pagination-bar"
										totalCount={completedItemQuantity}
										onPageChange={onPageChangeCompleted}
										currentPage={currentPageCompleted}
										pageSize={maxitemsPerPage}
									></Pagination>
								</div>
							) : null}

							{filtroEnvios === 'Cancelled' ? (
								<div>
									<h6 className="m-3">
										<b>Envios Cancelados ({cancelledItemQuantity})</b>
									</h6>
									<Card className="mb-3">
										<Table striped bordered hover responsive>
											<thead className="align-items-center table-order tHead">
												<tr className="align-items-center table-order text-center">
													<th className="col-lg-1 tableHeader">
                            Fecha Cancelacion
													</th>
													<th className="col-lg-1 tableHeader">Cuenta</th>
													<th className="col-lg-1 tableHeader">Tipo</th>
													<th className="col-lg-1 tableHeader">Responsable</th>
													<th className="col-lg-1 tableHeader"></th>
												</tr>
											</thead>

											{loading ? (
												<Row className="justify-content-center spinner-row">
													<LoadingBox className="col-1" />
												</Row>
											) : error ? (
												<MessageBox variant="danger">{error}</MessageBox>
											) : (
												currentPostsCancelled.map((shipment, i) => {
													return shipment.cancelledBy ? (
														<tbody className="align-items-center table-order tableBodyHover pointerCursor">
															<tr id="data" key={shipment._id}>
																<td className="col-lg-1 tableBody">
																	{shipment.cancelledDate
																		? moment(
																			new Date(shipment.cancelledDate)
																		).format('DD/MM/YY')
																		: null}
																</td>
																<td className="col-lg-1 tableBody">
																	{shipment.account
																		? shipment.account.name
																		: null}
																</td>

																<td className="col-lg-1 tableBody">
																	{shipment.type ? shipment.type.name : ''}
																</td>

																<td className="col-lg-1 tableBody">
																	{shipment.cancelledBy
																		? shipment.cancelledBy.name
																		: ''}
																</td>
																<td className="col-lg-1 tableBody">
																	<OverlayTrigger
																		key="top"
																		placement={i === 0 ? 'left' : 'top'}
																		overlay={
																			<Tooltip id={'tooltip-top'}>
                                        Ver Detalles
																			</Tooltip>
																		}
																	>
																		<Button
																			className="seeDetailsButton"
																			onClick={() =>
																				detailsShipmentHandler(shipment)
																			}
																		>
																			<BsFileEarmarkRichtext />
																		</Button>
																	</OverlayTrigger>
																</td>
															</tr>
														</tbody>
													) : null;
												})
											)}
										</Table>
									</Card>
									<Pagination
										className="pagination-bar"
										totalCount={cancelledItemQuantity}
										onPageChange={onPageChangeCancelled}
										currentPage={currentPageCancelled}
										pageSize={maxitemsPerPage}
									></Pagination>
								</div>
							) : null}
						</React.Fragment>
					</div>
				</div>
			</div>
		</>
	);
}

export default AdminShipmentList;
