import React from 'react';
import './Screens.css';
import axios from 'axios';
import { useLayoutEffect, useReducer } from 'react';
import { Card, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			text: action.payload.text,
			gotaSettings: action.payload.settings,
			loading: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

export default function ShippingInfo() {
	const [{ text }, dispatch] = useReducer(reducer, {
		text: {},
		loading: true,
		error: '',
		gotaSettings: {},
	});

	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/texts/envios');
				const { data: settings } = await axios.get('/api/config/public');
				dispatch({
					type: 'FETCH_SUCCESS',
					payload: { text: data, settings: settings },
				});
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: err.message });
			}
		};
		fetchData();
	}, []);

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<div className='container container-envios'>
			<Helmet>
				<title>Politica de EnviosEnvios</title>
			</Helmet>
			<Card>
				<Row className='envios-Row'>
					<div className='col-lg-12 col col-envios-contact'>
						<div dangerouslySetInnerHTML={createMarkup(text.htmlText)}></div>
					</div>
				</Row>
			</Card>
		</div>
	);
}
