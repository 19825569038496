import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';

import es from 'date-fns/locale/es'; // the locale you want
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet-async';
import { FaChartBar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, getStatus, maxitemsPerPage } from '../../utils';
registerLocale('es', es);
const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_SUMM_REQUEST':
		return { ...state, loadingSumm: true };
	case 'FETCH_SUMM_SUCCESS':
		return {
			...state,
			summDebts: action.payload,
			loadingSumm: false,
		};
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_DEBTS_SUCCESS':
		return {
			...state,
			debts: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AdminBalances() {
	const [
		{
			loading,
			error,
			successDelete,
			debts,
			itemQuantity,
			summDebts,

		},
		dispatch,
	] = useReducer(reducer, {
		debts: [],
		summDebts: [],
		loading: true,
		loadingSumm: true,
		error: '',
		itemQuantity: 0,
	});

	const { state, disparch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	const [month, setMonth] = useState(new Date());
	const [account, setAccount] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [minDate, setMinDate] = useState(null);



	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);

	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			if (userInfo && userInfo.isSuperAdmin) {
				getAccounts();
			} else {
				navigate('/');
			}
		}
	}, [successDelete]);

	//GET ALL ACCOUNTS
	const getAccounts = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const { data } = await axios.get('/api/accounts', {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			const dfAcc = data.find((d) => d.name === 'defaultAccount');
			setMinDate(new Date(dfAcc.createdAt));
			setAccounts(data);
			await groupDebts(null, new Date());
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	};

	//GET DEBTS BY ACCOUNT AND GROUP BY MONTH
	const groupDebts = async (accountId, selectedMonth) => {
		try {
			if (accountId) {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get(
					`/api/debts/getDebtByAccountAndMonth/${accountId}/${selectedMonth}`
				);
				dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: data });
				setMinDate(data[0] ? new Date(data[0].owner.createdAt) : null);
			} else {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get(
					`/api/debts/getDebtByMonth/${selectedMonth}`
				);
				dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: data });
			}
			getSumDebts(accountId, selectedMonth);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const getSumDebts = async (accId, selectedMonth) => {
		try {
			if (accId) {
				dispatch({ type: 'FETCH_SUMM_REQUEST' });
				const { data } = await axios.get(
					`/api/summDebts/getSummByAccountAndMonth/${accId}/${selectedMonth}`
				);
				dispatch({ type: 'FETCH_SUMM_SUCCESS', payload: data });
			} else {
				dispatch({ type: 'FETCH_SUMM_REQUEST' });
				const { data } = await axios.get(
					`/api/summDebts/getSummByMonth/${selectedMonth}`
				);
				dispatch({ type: 'FETCH_SUMM_SUCCESS', payload: data });
			}
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	function getTotal() {
		let total = 0;
		if (summDebts && summDebts.length > 0) {
			summDebts.forEach((s) => {
				if (!isNaN(s.amount)) {
					total += s.amount;
				}
			});
		}
		return total;
	}

	async function detailsDebtHandler(debt) {
		navigate(`/AdminScreen/debtDetails/${debt._id}`);
	}


	return loading ? (
		<Row
			className="d-flex justify-content-center align-items-center spinner-row"
			style={{ minHeight: '100vh' }}
		>
			<LoadingBox className="col-1" />
		</Row>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Balances</title>
			</Helmet>

			<div className="container admin-con">
				<div className="w-100">
					<h3 className="text-right mt-3 mb-3" style={{ margin: '2.5% 0' }}>
						<FaChartBar /> Balances
					</h3>

					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<>
							<Card className="mb-3 tHead">
								<Row className="mb-3">
									<Col className="tableHeader">
										<p className="dpLabel mb-2">Fecha</p>
										<DatePicker
											className="datepicker"
											locale="es"
											selected={month}
											onChange={async (e) => {
												 setMonth(e);
												await groupDebts(account ? account._id : null, e);
											}}
											dateFormat="MMM yyyy"
											showMonthYearPicker
											popperPlacement="bottom"
											popperModifiers={{
												flip: { behavior: ['bottom'] },
												preventOverflow: { enabled: false },
												hide: { enabled: false },
											}}
											maxDate={new Date()}
											minDate={minDate}
										></DatePicker>
									</Col>

									<Col className="tableHeader ml-1">
										<p className="dpLabel">Cuenta</p>
										<Form.Select
											defaultValue={account}
											className="border border-2 rounded mb-0"
											onChange={async (e) => {
												if (e.target.value === 'all') {
													await setAccount(null);
													groupDebts(null, month);
												} else {
													await setAccount(e.target.value);
													groupDebts(e.target.value, month);
												}
											}}
										>
											<option key={null} value={'all'}>
                        Todas
											</option>
											{accounts.map((account) => (
												<option key={account._id} value={account._id}>
													{account.name}
												</option>
											))}
										</Form.Select>
									</Col>
								</Row>
								<div>
									<Table responsive size="sm" className="table-container">
										<thead className="align-items-center table-order tHead">
											<th className="col-lg-2 tableHeader">Fecha</th>
											<th className="col-lg-2 tableHeader">Deudor</th>
											<th className="col-lg-1 tableHeader">Monto</th>
											<th className="col-lg-2 tableHeader">Tipo</th>
										</thead>

										{currentPosts.length === 0 ? (
											<tr className="tableBodyHover">
												<td colSpan="12" className="tableBody">
                          No hay deudas para el mes y año seleccionados
												</td>
											</tr>
										) : (
											currentPosts.map((debt, i) => (
												<tbody
													key={i}
													className="align-items-center table-order tableBodyHover"
												>
													<tr
														id="data"
														key={debt._id}
														onClick={() => detailsDebtHandler(debt)}
													>
														<td className="col-lg-2 tableBody">
															{debt.shipment
																? debt.shipment.orderDate
																	? moment(
																		new Date(debt.shipment.orderDate)
																	).format('DD/MM/YY')
																	: null
																: null}
														</td>
														<td className="col-lg-2 tableBody">
															{debt.owner ? debt.owner.name : null}
														</td>
														<td className="col-lg-1 tableBody">
															{Math.round(debt.amount)}
														</td>
														<td className="col-lg-2 tableBody">
															{debt.shipment
																? debt.shipment.type
																	? debt.shipment.type.name
																	: null
																: null}
														</td>
													</tr>
												</tbody>
											))
										)}
										<tfoot>
											<tr>
												<td>TOTAL (mensual)</td>
												<td className="tableBody">${getTotal()}</td>
											</tr>
										</tfoot>
									</Table>
								</div>
							</Card>

							<Pagination
								className="pagination-bar"
								totalCount={itemQuantity}
								onPageChange={onPageChange}
								currentPage={currentPage}
								pageSize={maxitemsPerPage}
							></Pagination>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default AdminBalances;
