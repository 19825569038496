import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Dropdown,
	DropdownButton,
	Modal,
	OverlayTrigger,
	Row,
	Stack,
	Table,
	Tooltip,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Geocode from 'react-geocode';
import { Helmet } from 'react-helmet-async';
import {
	BsArrowLeftCircle,
	BsFileEarmarkRichtext,
	BsPencilSquare,
	BsPlusCircle,
	BsTrash,
} from 'react-icons/bs';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { GoogleMapsApiKey, getError, getStatus } from '../../utils';
//#region REDUCER
const reducer = (state, action) => {
	switch (action.type) {
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	case 'FETCH_MAP_REQUEST':
		return { ...state, loadingMap: true };
	case 'FETCH_MAP_SUCCESS':
		return { ...state, map: action.payload, loadingMap: false };
	case 'FETCH_USERS_REQUEST':
		return { ...state, loadingUsers: true };
	case 'FETCH_USERS_SUCCESS':
		return { ...state, users: action.payload, loadingUsers: false };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, account: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, loadingMap:false, error: action.payload };
	default:
		return state;
	}
};
//#endregion
function AccountDetails() {
	Geocode.setApiKey(GoogleMapsApiKey);
	const params = useParams();
	const { id } = params;
	const navigate = useNavigate();
	const [
		{
			loading,
			error,
			account,
			users,
			loadingUsers,
			map,
			loadingMap,
			loadingCreate,
		},
		dispatch,
	] = useReducer(reducer, {
		map: [],
		users: [],
		loadingUsers: true,
		loadingMap: true,
		loadingCreate: false,
		account: {},
		loading: true,
		error: '',
	});
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [accountAddress, setAccountAddress] = useState();

	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);
	const [showUserModal, setShowUserModal] = useState(false);
	const handleUserClose = () => setShowUserModal(false);
	const handleUserShow = () => setShowUserModal(true);
	const [userToDelete, setUserToDelete] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get(`/api/accounts/account/${id}`);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		const fetchUsers = async () => {
			dispatch({ type: 'FETCH_USERS_REQUEST' });
			try {
				const result = await axios.get(`/api/users/account/${id}`);
				dispatch({ type: 'FETCH_USERS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};

		const fetchMap = async () => {
			dispatch({ type: 'FETCH_MAP_REQUEST' });
			try {
				const result = await axios.get(`/api/maps/mapAccount/${id}`, {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				getAddress(result.data);
				dispatch({ type: 'FETCH_MAP_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
				}
			}
		};

		fetchData();
		fetchUsers();
		fetchMap();
	}, [id, showUserModal]);

	const getAddress = async (accountMap) => {
		Geocode.fromLatLng(accountMap.latCentral, accountMap.lngCentral).then(
			(response) => {
				setAccountAddress(response.results[0].formatted_address);
			},
			(error) => {
				console.error(error);
			}
		);
	};

	const createNewMap = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await axios.post(
				'/api/maps/createMap',
				{
					account,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({
				type: 'CREATE_SUCCESS',
				payload: data,
			});
			toast.success('Mapa creado');
			handleClose();
			map.push(data);
			navigate(`/AdminScreen/mapScreen/${data._id}`);
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	};

	const handleMap = () => {
		if (map.length !== 0) {
			navigate(`/AdminScreen/mapScreen/${map._id}`);
		} else {
			handleShow();
		}
	};

	async function handleDelete(user) {
		setUserToDelete(user);
		handleUserShow();
	}

	async function deleteUserHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.delete(`/api/users/${userToDelete._id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'DELETE_SUCCESS' });
			handleUserClose();
			toast.success('Usuario Eliminado');
		} catch (err) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	}

	async function editUserHandler(user) {
		navigate(`/AdminScreen/editUser/${user._id}`);
	}

	async function detailsUserHandler(user) {
		navigate(`/AdminScreen/user/${user._id}`);
	}

	async function newUserHandler(accountId) {
		navigate(`/AdminScreen/newUser/${accountId}`);
	}

	return (
		<div>
			{(loading || loadingMap || loadingUsers || loadingCreate) && <>
				<LoadingBox/> 
			</>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal
				size="md"
				show={showModal}
				onHide={handleClose}
				aria-labelledby="example-modal-sizes-title-lg"
				animation={true}
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-modal-sizes-title-lg">
            Crear Mapa
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
          Esta cuenta no contiene mapas asociados, desea crear uno?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={createNewMap}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				size="md"
				show={showUserModal}
				onHide={handleUserClose}
				aria-labelledby="example-modal-sizes-title-lg"
				animation={true}
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-modal-sizes-title-lg">
            Eliminar Usuario
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar el usuario?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleUserClose}>
            Cancelar
					</Button>
					<Button onClick={deleteUserHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<div
				className="container align-items-center admin-con"
			>
				<Helmet>
					<title>Detalles de la Cuenta</title>
				</Helmet>
				<Container fluid className="my-3">
					<Row className="justify-content-center align-items-center">
						<Col md={10} lg={8} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<Row>
										<Col xs={8} className="mb-3 mt-md-4">
											<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                        Detalles de la Cuenta
											</h2>
										</Col>
										<Col xs={4} className="mb-3 mt-md-4">
											<Form.Group className="mb-3 " controlid="map">
												<OverlayTrigger
													key="top"
													placement="top"
													overlay={
														<Tooltip id={'tooltip-top'}>Ver Mapa</Tooltip>
													}
												>
													<Button className="xl" id="map" onClick={handleMap}>
														<FaMapMarkedAlt className="fa-lg mx-2" />
													</Button>
												</OverlayTrigger>
											</Form.Group>
										</Col>
									</Row>
									<div>
										<div className="mb-3 mx-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Row>
													<Col>
														<Form.Group className="mb-3" controlid="name">
															<Form.Label>Nombre</Form.Label>
															<Form.Control
																type="text"
																required
																disabled
																value={account.name}
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col>
														<Form.Group className="mb-3" controlid="user">
															<Form.Label>Dirección de la Empresa</Form.Label>
															<Form.Control
																value={accountAddress}
																disabled
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<div className="border border-1 border-light mt-3"></div>

												<div style={{ overflow: 'visible' }}>
													<Container fluid className="mt-4">
														<Row>
															<Col>
																<h6 className="mt-3 mb-3 text-uppercase text-align-center ">
                                  Usuarios de la cuenta
																</h6>
															</Col>
															<Col>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'right',
																		alignItems: 'center',
																	}}
																>
																	<Button
																		className="btn btn-dark m-1 fixed-right"
																		value="Crear Usero"
																		onClick={() => newUserHandler(id)}
																	>
																		<span>
																			<BsPlusCircle /> Crear Usuario
																		</span>
																	</Button>
																</div>
															</Col>
														</Row>
														{loading ? (
															<LoadingBox></LoadingBox>
														) : users.length > 0 ? (
															<Card>
																<Table responsive hover size="sm">
																	<thead className="align-items-center table-order">
																		<th className="col-lg-3 text-center">
                                      Nombre
																		</th>
																		<th className="col-lg-3 text-center">
                                      Email
																		</th>
																		<th className="col-lg-3 text-center">
                                      Tipo
																		</th>
																		<th className="col-lg-1 text-center">
                                      Acciones
																		</th>
																	</thead>
																	{users.map((user, i) => (
																		<tbody
																			key={i}
																			className="align-items-center table-order"
																		>
																			<tr key={user._id}>
																				<td className="col-lg-3 text-center">
																					{user.name}
																				</td>
																				<td className="col-lg-3 text-center">
																					{user.email}
																				</td>
																				<td className="col-lg-3 text-center">
																					{user.userType}
																				</td>

																				<td className="col-lg-1 text-center">
																					<DropdownButton drop="start" title="" style={{ position: 'static' }}>
																						<Dropdown.Item
																							onClick={() =>
																								detailsUserHandler(user)
																							}
																						>
																							<BsFileEarmarkRichtext />
                                              Ver Detalles
																						</Dropdown.Item>
																						<Dropdown.Item
																							onClick={() =>
																								editUserHandler(user)
																							}
																						>
																							<BsPencilSquare />
                                              Editar
																						</Dropdown.Item>
																						<Dropdown.Item
																							onClick={() => handleDelete(user)}
																						>
																							<BsTrash> </BsTrash>Eliminar
																						</Dropdown.Item>
																					</DropdownButton>
																				</td>
																			</tr>
																		</tbody>
																	))}
																</Table>
															</Card>
														) : (
															<MessageBox cols={6}>
                                No hay usuarios asociados a esta cuenta
															</MessageBox>
														)}
													</Container>
												</div>

												<Stack direction="horizontal">
													<Button
														className="justify-content-left"
														id="goBack"
														onClick={() => navigate(-1)}
													>
														<BsArrowLeftCircle /> Atras
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
export default AccountDetails;
