import axios from 'axios';
import moment from 'moment';
import React, {
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import DatePicker from 'react-datepicker';

import {
	DirectionsRenderer,
	GoogleMap,
	InfoWindow,
	Marker,
	useJsApiLoader,
} from '@react-google-maps/api';
import { Card, Col, Row } from 'react-bootstrap';
import Geocode from 'react-geocode';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import AccordionRoutes from '../../components/ModalGenerateRoutes/AccordionRoutes';
import { calculateDirectionsResponseUTILS } from '../../mapsUtils';
import { GoogleMapsApiKey, getError } from '../../utils';
import './RoutesList.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_MAP_REQUEST':
		return { ...state, loadingMap: true };
	case 'FETCH_MAP_SUCCESS':
		return { ...state, map: action.payload, loadingMap: false };
	case 'FETCH_MAP_FAIL':
		return { ...state, loadingMap: false, error: action.payload };
	case 'FETCH_REL_REQUEST':
		return { ...state, loadingRel: true };
	case 'FETCH_REL_SUCCESS':
		return {
			...state,
			orderRoutesRel: action.payload,
			loadingRel: false,
			orderRoutesItemQuantity: action.payload.length,
		};

	case 'FETCH_COMPLETED_SUCCESS':
		return {
			...state,
			completedShipments: action.payload,
			loading: false,
			completedItemQuantity: action.payload.length,
		};
	case 'FETCH_CANCELLED_SUCCESS':
		return {
			...state,
			cancelledShipments: action.payload,
			loading: false,
			cancelledItemQuantity: action.payload.length,
		};
	case 'UPDATE_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, loadingRel: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'SHOW_GENERATE_ROUTES':
		return { ...state, generateRoutesModal: true };
	case 'HIDE_GENERATE_ROUTES':
		return { ...state, generateRoutesModal: false };
	default:
		return state;
	}
};

const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	transition: 'border .3s ease-in-out',
};

function RoutesList() {
	const [
		{ loadingMap, loadingRel, error, successDelete, orderRoutesRel },
		dispatch,
	] = useReducer(reducer, {
		orderRoutesRel: [],
		loadingMap: true,
		loadingRel: true,
		error: '',
	});

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: GoogleMapsApiKey,
		libraries: ['places', 'geometry'],
	});
	Geocode.setApiKey(GoogleMapsApiKey);

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	const [center, setCenter] = useState({});
	const [showDirections, setShowDirections] = useState([]);
	const [activeMarker, setActiveMarker] = useState(null);

	//state variables

	const [routeDate, setRouteDate] = useState(new Date());

	useMemo(() => ({ ...baseStyle }), []);

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			if (userInfo.isAdmin) {
				fetchDataMap();		
			} else {
				navigate('/');
			}
		}
	}, [successDelete]);

	const getRelations = async (selectedDate = new Date(), origin) => {
		try {
			dispatch({ type: 'FETCH_REL_REQUEST' });
			const response = await axios.get(
				`/api/orderDailyRouteRelation/byRoute/${selectedDate}/${userInfo._id}`
			);
			//RECALCULATE ROUTES 
			const coordinateOrigin =  new window.google.maps.LatLng(
				origin.lat,
				origin.lng
			);
			let routesArray = [];
			const promises = response.data.map(async (shArray) => {
				const routeId =  shArray[0].dailyRoute._id;
				const asignedDelivery = shArray[0].dailyRoute.asignedDelivery;
				shArray.sort((a, b) => a.numberInRoute - b.numberInRoute);
				const orderArray = shArray.map((item) => item.order);
				orderArray.map((order)=> {
					order.routeId = routeId;
					order.asignedDelivery = asignedDelivery;
				});
				
			  const results = await calculateDirectionsResponseUTILS(
					orderArray,
					coordinateOrigin,
					false
			  );
			  return results;
			});
			
			// Use Promise.all to wait for all promises to resolve
			try {
			  routesArray = await Promise.all(promises);
			} catch (error) {
			  console.error('Error in Promise.all:', error);
			}

			setShowDirections(response.data.map(() => true));
			dispatch({ type: 'FETCH_REL_SUCCESS', payload: routesArray });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const fetchDataMap = async () => {
		try {
			dispatch({ type: 'FETCH_MAP_REQUEST' });
			const { data } = await axios.get('/api/maps/mapAccount/defaultMap');
			if (data) {
				setCenter({ lat: data.latCentral, lng: data.lngCentral });
			}
			await getRelations(undefined, { lat: data.latCentral, lng: data.lngCentral });

			dispatch({ type: 'FETCH_MAP_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_MAP_FAIL', payload: getError(err) });
		}
	};

	const changeDate = async (selectedDate) => {
		await getRelations(selectedDate, center) ;
		setRouteDate(selectedDate);
	};

	//#region MODALS

	//#endregion

	const toggleDirectionVisibility = (index) => {
		const updatedShowDirections = [...showDirections];
		updatedShowDirections[index] = !updatedShowDirections[index];
		setShowDirections(updatedShowDirections);
	};

	return (loadingMap || loadingRel) ? (
		<Row
			className="d-flex justify-content-center align-items-center spinner-row"
			style={{ minHeight: '100vh' }}
		>
			<LoadingBox className="col-1" />
		</Row>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<div>
				<Helmet>
					<title>Lista de Rutas</title>
				</Helmet>

				<div className="container admin-con">

					<div className="w-100">
						<h3
							className="text-right mt-3 mb-3 mx-3"
							style={{ margin: '2.5% 1' }}
						>
							{'  '}
							<svg
								className="me-1"
								xmlns="http://www.w3.org/2000/svg"
								height="1em"
								viewBox="0 0 512 512"
							>
								{
									'Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->'
								}
								<path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z" />
							</svg>
              Histórico de Rutas
						</h3>
						<div className="card-container">
							<Row>
								<Col className="text-end">
									{' '}
									<DatePicker
										className="datepicker"
										showIcon={true}
										locale="es"
										selected={routeDate}
										onChange={async (e) => {
											await changeDate(e);
										}}
										dateFormat={'dd/MM/yy'}
										// minDate={new Date()}
										popperPlacement="bottom-end"
										popperModifiers={{
											flip: { behavior: ['bottom'] },
											preventOverflow: { enabled: false },
											hide: { enabled: false },
										}}
									></DatePicker>
								</Col>
							</Row>

							<Card className="acc-container">
								<Card>
									<Row>
										<AccordionRoutes
											routesArray={orderRoutesRel}
											showDirection={showDirections}
											toggleVisibility={(i) => toggleDirectionVisibility(i)}
											isList={true}
										/>
									</Row>
									<div className="mt-2">
										{isLoaded && orderRoutesRel.length > 0 ? (
										// Render the map directly
											<Row>
												<React.Fragment>
													<div>
														<Col
															xs={8}
															className="google-map-container-accordion"
														>
															<GoogleMap
																className="px-0"
																center={center}
																zoom={12}
																mapContainerStyle={{
																	width: '100%',
																	height: '100%',
																}}
																options={{
																	zoomControl: true,
																	streetViewControl: false,
																	mapTypeControl: false,
																	fullscreenControl: false,
																	scaleControl: true,
																}}
																onLoad={(map) =>
																	dispatch({
																		type: 'FETCH_MAP_SUCCESS',
																		payload: map,
																	})
																}
															>
																{orderRoutesRel.map((r, i) => {
																	if (r) {
																		let color = '#92c0f7';
																		if (
																			r.vehicle &&
                                      r.vehicle.color
																		) {
																			color = r.vehicle.color;
																		}

																		return (
																			<div key={i}>
																				{showDirections[i] && ( // Only render when showDirections[i] is true
																					<DirectionsRenderer
																						directions={
																							r.responseToRenderize
																						}
																						options={{
																							polylineOptions: {
																								strokeColor: color,
																							},
																							suppressMarkers: true,
																						}}
																					/>
																				)}
																				{showDirections[i] &&
                                          r.responseToRenderize.routes[0].legs.map(
                                          	(step, index) => (
                                          		<Marker
                                          			key={index}
                                          			position={step.end_location}
                                          			label={String(index + 1)}
                                          			onClick={() =>
                                          				setActiveMarker(step)
                                          			}
                                          		/>
                                          	)
                                          )}
																				{activeMarker && (
																					<InfoWindow
																						position={activeMarker.end_location}
																						onCloseClick={() =>
																							setActiveMarker(null)
																						}
																					>
																						<div>
																							<p>{activeMarker.end_address}</p>
																							<p>
																								<strong>
                                                  Hora estimada de entrega:{' '}
																									{moment(
																										new Date(
																											activeMarker.estimatedTime
																										)
																									)
																										.utc()
																										.format('HH:mm')}
																								</strong>
																							</p>
																						</div>
																					</InfoWindow>
																				)}
																			</div>
																		);
																	}
																})}
																{/* Your map-related JSX content */}
															</GoogleMap>
														</Col>
													</div>
												</React.Fragment>
											</Row>
										) : null}
									</div>
								</Card>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default RoutesList;
