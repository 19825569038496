import axios from 'axios';
import React, { useContext, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import LoadingBox from '../components/LoadingBox';
import { getStatus } from '../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false };

	default:
		return state;
	}
};

function ProfileScreen() {
	const navigate = useNavigate();

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [name, setName] = useState(userInfo.name);
	const [email, setEmail] = useState(userInfo.email);
	const [password, setPassword] = useState('');

	const [confirmPassword, setConfirmPassword] = useState('');

	const [{loadingUpdate},dispatch] = useReducer(reducer, {
		loadingUpdate: false,
	});

	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			if (confirmPassword != password) {
				//TODO: missing password check
				console.info('missing check');
			}
			const { data } = await axios.put(
				'/api/users/profile',
				{
					name,
					email,
					password,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'USER_SIGNIN', payload: data });
			localStorage.setItem('userInfo', JSON.stringify(data));
			toast.success('Usuario actualizado correctamente');
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	};
	return (
		<Container className="small-container">
			<Helmet>
				<title>Perfil</title>
			</Helmet>

			{loadingUpdate && <LoadingBox></LoadingBox>}

			<h1 className="my-3">Perfil</h1>
			<Form onSubmit={submitHandler}>
				<Form.Group className="mb-3" controlId="name">
					<Form.Label>Nombre</Form.Label>
					<Form.Control
						value={name}
						onChange={(e) => setName(e.target.value)}
						required
					></Form.Control>
				</Form.Group>
				<Form.Group className="mb-3" controlId="email">
					<Form.Label>Email</Form.Label>
					<Form.Control
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value.toLowerCase())}
						required
					></Form.Control>
				</Form.Group>
				<Form.Group className="mb-3" controlId="password">
					<Form.Label>Contraseña</Form.Label>
					<Form.Control
						type="password"
						onChange={(e) => setPassword(e.target.value)}
						required
					></Form.Control>
				</Form.Group>
				<Form.Group className="mb-3" controlId="confirmPassword">
					<Form.Label>Repetir Contraseña</Form.Label>
					<Form.Control
						type="password"
						onChange={(e) => setConfirmPassword(e.target.value)}
						required
					></Form.Control>
				</Form.Group>
				<div className="mb-3">
					<Button type="submit">Update</Button>
				</div>
			</Form>
		</Container>
	);
}
export default ProfileScreen;
