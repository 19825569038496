import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Container,
	Dropdown,
	DropdownButton,
	Modal,
	Table,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsFileEarmarkRichtext, BsPlusCircle, BsTrash } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, getStatus, maxitemsPerPage } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			payments: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function PaymentList() {
	const [{ loading, error, payments, successDelete, itemQuantity }, dispatch] =
    useReducer(reducer, {
    	payments: [{}],
    	loading: true,
    	error: '',
    	itemQuantity: 0,
    });

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const params = useParams();
	const navigate = useNavigate();
	const { debtId } = params;

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = payments.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = payments.slice(indexOfFirstPost, indexOfLastPost);
	};

	//MODAL
	const [paymentToDelete, setPaymentToDelete] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			getAccount();
		}
	}, [successDelete]);

	//GET ACCOUNT OF USER
	const getAccount = async () => {
		try {
			const { data } = await axios.get(`/api/users/user/${userInfo._id}`);
			if (debtId) {
				getPaymentsByDebt();
			} else {
				getPayments(data._id);
			}
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//GET PAYMENTS OF ACCOUNT
	const getPayments = async (accountId) => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const { data } = await axios.get(
				`/api/payments/getPaymentsByAccount/${accountId}`
			);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//GET PAYMENTS OF ACCOUNT
	const getPaymentsByDebt = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const { data } = await axios.get(
				`/api/payments/getPaymentsByDebt/${debtId}`
			);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	async function deletePaymentHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.delete(`/api/payments/${paymentToDelete._id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'DELETE_SUCCESS' });
			toast.success('Pago Eliminado');
		} catch (err) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	}

	async function detailsPaymentHandler(payment) {
		navigate(`/paymentDetails/${payment._id}`);
	}

	async function newPaymentHandler() {
		navigate('/newPayment');
	}

	async function handleDelete(payment) {
		setPaymentToDelete(payment);
		handleShow();
	}

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Pago</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar el pago?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deletePaymentHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Helmet>
				<title>Lista de Pagos</title>
			</Helmet>

			<div className="container admin-con">
				<div>
					<Container fluid>
						<h1 className="text-center mt-5">Lista de Pagos</h1>
						<div
							style={{
								display: 'flex',
								justifyContent: 'right',
								alignItems: 'center',
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Crear Contacto"
								onClick={() => newPaymentHandler()}
							>
								<span>
									<BsPlusCircle /> Crear Pago
								</span>
							</Button>
						</div>

						{loading ? (
							<LoadingBox></LoadingBox>
						) : error ? (
							<MessageBox variant="danger">{error}</MessageBox>
						) : (
							<Card>
								<Table responsive size="sm">
									<thead className="align-items-center table-order tHead">
										<th className="col-lg-2 tableHeader">ID Deuda</th>
										<th className="col-lg-2 tableHeader">Deuda Total</th>
										<th className="col-lg-2 tableHeader">Monto Pago</th>
										<th className="col-lg-2 tableHeader">
                      Balance Luego del Pago
										</th>
										<th className="col-lg-2 tableHeader">Fecha</th>
										<th className="col-lg-2 tableHeader">Acciones</th>
									</thead>

									{currentPosts.map((payment, i) => (
										<tbody
											key={i}
											className="align-items-center table-order tableBodyHover"
										>
											<tr id="data" key={payment._id}>
												<td className="col-lg-2 tableBody">
													{payment.debt ? payment.debt._id : null}
												</td>
												<td className="col-lg-2 tableBody">
													{payment.debt
														? Math.round(payment.debt.amount * 100) / 100
														: null}
												</td>
												<td className="col-lg-2 tableBody">
													{Math.round(payment.amount * 100) / 100}
												</td>
												<td className="col-lg-2 tableBody">
													{Math.round(payment.balanceAfterPay * 100) / 100}
												</td>
												<td className="col-lg-2 tableBody">
													{' '}
													{moment(new Date(payment.date)).format('DD/MM/YY')}
												</td>
												<td className="col-lg-2 tableBody">
													<DropdownButton drop="start" title="">
														<Dropdown.Item
															onClick={() => detailsPaymentHandler(payment)}
														>
															<BsFileEarmarkRichtext />
                              Ver Detalles
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() => handleDelete(payment)}
														>
															<BsTrash> </BsTrash>Eliminar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										</tbody>
									))}
								</Table>
							</Card>
						)}
					</Container>
				</div>
				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</div>
	);
}

export default PaymentList;
