import React, { useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap'; // Asumiendo que estás utilizando react-bootstrap
import Dropzone from 'react-dropzone'; // Asumiendo que estás utilizando react-dropzone
import { FaTrash } from 'react-icons/fa';
import '../../../App.css';
import '../ModalConfirmShipment/ModalConfirmShipment.css';

function ConfirmShipmentModal({
	show,
	onHide,
	onConfirm,
	isMobile,
	name,
	setName,
	document,
	setDocument,
	file,
	setFile,
	onDrop,
}) {
	const baseStyle = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		transition: 'border .3s ease-in-out',
	};

	const style = useMemo(() => ({ ...baseStyle }), []);

	const handleDeleteFile = (index) => {
		const updatedFiles = [...file];
		updatedFiles.splice(index, 1);
		setFile(updatedFiles);
	};

	return (
		<Modal
			size="lg"
			show={show}
			onHide={onHide}
			aria-labelledby="example-modal-sizes-title-lg"
			animation={true}
			className="modal-auto-height"
		>
			<Modal.Header closeButton>
				<Modal.Title id="example-modal-sizes-title-lg">
          Confirmar Entrega
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="confirmation-modal">
				<Form>
					<Form.Group className="mb-3" controlId="nombre">
						<Form.Label className="text-center">Nombre del receptor</Form.Label>
						<Form.Control
							type="text"
							placeholder="Ingresa nombre del receptor"
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="document">
						<Form.Label className="text-center">
              Documento del receptor
						</Form.Label>
						<Form.Control
							type="text"
							placeholder="Ingresa documento del receptor"
							value={document}
							onChange={(e) => setDocument(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="document">
						{isMobile ? (
							<React.Fragment>
								<Form.Label className="text-center">
                  Adjuntar Archivo
								</Form.Label>
								<Dropzone onDrop={onDrop}>
									{({ getRootProps, getInputProps }) => (
										<div>
											<div {...getRootProps({ style })}>
												<input
													{...getInputProps()}
													onChange={(e) => setFile(e.target.files[0])}
												/>
												<p>Cargue un archivo o tome una foto</p>
												{file
													? file.map((f, i) => {
														return (
															<div key={i} style={{ color: 'black' }}>
																{f.name}
																{'    '}
																<Button onClick={() => handleDeleteFile(i)}>
																	<FaTrash />
																</Button>
															</div>
														);
													})
													: null}
											</div>
										</div>
									)}
								</Dropzone>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Form.Label className="text-center">
                  Adjuntar Archivo
								</Form.Label>
								<Dropzone onDrop={onDrop}>
									{({ getRootProps, getInputProps }) => (
										<div>
											<div {...getRootProps({ style })}>
												<input
													{...getInputProps()}
													onChange={(e) => setFile(e.target.files[0])}
												/>
												<p>
                          Arrastre y suelte un archivo aquí, o haga clic para
                          seleccionar un archivo
												</p>
												{file
													? file.map((f, i) => {
														return (
															<div key={i} style={{ color: 'black' }}>
																{f.name}
															</div>
														);
													})
													: null}
											</div>
										</div>
									)}
								</Dropzone>
							</React.Fragment>
						)}
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer className="confirmation-modal">
				<Button variant="secondary" onClick={onHide}>
          Cerrar
				</Button>
				<Button variant="success" onClick={onConfirm}>
          Confirmar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ConfirmShipmentModal;
