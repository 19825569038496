import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Container,
	Dropdown,
	DropdownButton,
	Table,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle, BsFileEarmarkRichtext } from 'react-icons/bs';
import { FcCancel } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, maxitemsPerPage } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTS_SUCCESS':
		return {
			...state,
			debts: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function IncompletedShipmentList() {
	const [{ loading, error, successDelete, debts, itemQuantity }, dispatch] =
    useReducer(reducer, {
    	debts: [],
    	loading: true,
    	error: '',
    	itemQuantity: 0,
    });

	//const params = useParams();
	const navigate = useNavigate();
	//const { month } = params;
	const { state } = useContext(Store);
	const { userInfo } = state;

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = debts.slice(indexOfFirstPost, indexOfLastPost);
	};

	//GET ACCOUNT OF USER
	const getAccount = async () => {
		try {
			const { data } = await axios.get(`/api/users/user/${userInfo._id}`);
			getDebts(data._id);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//GET DEBTS OF ACCOUNT WHERE SHIPMENTS ARE NOT COMPLETED
	const getDebts = async (accountId) => {
		if (userInfo.userType === 'Gerente') {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const response = await axios.get(
					`/api/debts/getDebtByAccountIncompletedShipments/${accountId}`
				);
				dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: response.data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		} else {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const response = await axios.get(
					`/api/debts/getDebtByUserIncompletedShipments/${userInfo._id}`
				);
				dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: response.data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		}
	};

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			getAccount();
		}
	}, [successDelete]);

	async function detailsShipmentHandler(debt) {
		navigate(`/shipments/details/${debt._id}`);
	}

	async function editStatusHandler(order, status) {
		const posibleStatus = [
			'Ingresado',
			'Confirmado',
			'Entregado',
			'En Camino',
			'Cancelacion Pendiente',
			'Cancelado',
		];
		if (posibleStatus.includes(status)) {
			try {
				await axios.put(`/api/orders/changeStatus/${order._id}`, {
					status,
				});
				window.location.reload();
			} catch (err) {
				toast.error(getError(err));
			}
		}
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Envios</title>
			</Helmet>

			<div className="container admin-con">
				<div>
					<Container fluid>
						<h2 className="text-center mt-5 mb-3">Envios no Completados</h2>
						<div
							style={{
								display: 'flex',
								justifyContent: 'right',
								alignItems: 'center',
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Detalle de envios"
								onClick={() => navigate(-1)}
							>
								<span>
									<BsArrowLeftCircle /> Volver a Balances
								</span>
							</Button>
						</div>

						{loading ? (
							<LoadingBox></LoadingBox>
						) : error ? (
							<MessageBox variant="danger">{error}</MessageBox>
						) : (
							<Card>
								<Table responsive size="sm">
									<thead className="align-items-center table-order tHead">
										<tr className="align-items-center table-order text-center">
											<th className="tableHeader">Fecha de Orden</th>
											<th className="tableHeader">Fecha de Envío</th>
											<th className="tableHeader">Usuario Responsable</th>
											<th className="tableHeader">Tipo</th>
											<th className="tableHeader">Precio</th>
											<th className="tableHeader">Saldo</th>
											{/*  <th className="col-lg-2 tableHeader">
                      Zona Origen
                    </th> */}
											<th className="tableHeader">Zona Destino</th>
											<th className="tableHeader">Estado</th>
											<th className="tableHeader"></th>
										</tr>
									</thead>

									{loading ? (
										<LoadingBox></LoadingBox>
									) : error ? (
										<MessageBox variant="danger">{error}</MessageBox>
									) : (
										currentPosts.map((debt) => {
											return debt.shipment ? (
												<tbody className="align-items-center table-order tableBodyHover">
													<tr id="data" key={debt._id}>
														<td className="tableBody">
															{debt.shipment.orderDate
																? moment(
																	new Date(debt.shipment.orderDate)
																).format('DD/MM/YY')
																: null}
														</td>
														<td className="tableBody">
															{debt.shipment.shipmentDate
																? moment(
																	new Date(debt.shipment.shipmentDate)
																).format('DD/MM/YY')
																: null}
														</td>
														<td className="tableBody">
															{debt.shipment.createdBy
																? debt.shipment.createdBy.name
																: ''}
														</td>
														<td className="tableBody">
															{debt.shipment.type
																? debt.shipment.type.name
																: ''}
														</td>
														<td className="tableBody">
															{Math.round(debt.amount)}
														</td>
														<td className="tableBody">
															{Math.round(debt.balance)}
														</td>
														<td className="tableBody">
															{debt.shipment.route.destinationAddress
																? debt.shipment.route.destinationAddress.zone
																	? debt.shipment.route.destinationAddress.zone
																		.name
																	: null
																: null}
														</td>

														<td className="tableBody">
															{debt.shipment.status}
														</td>

														<td className="tableBody">
															<DropdownButton drop="start" title="">
																{/* <div className="border border-2"></div> */}
																<Dropdown.Item
																	onClick={() => detailsShipmentHandler(debt)}
																>
																	<BsFileEarmarkRichtext /> Ver Detalles
																</Dropdown.Item>
																{userInfo.userType === 'Gerente' ? (
																	<Dropdown.Item
																		disabled={
																			debt.shipment.status === 'Cancelado' ||
                                      debt.shipment.status ===
                                        'Cancelacion Pendiente'
																		}
																		//className="btn-success btn light"
																		onClick={() =>
																			editStatusHandler(
																				debt.shipment,
																				'Cancelado'
																			)
																		}
																	>
																		<FcCancel /> Cancelar Envio
																	</Dropdown.Item>
																) : null}
															</DropdownButton>
														</td>
													</tr>
												</tbody>
											) : null;
										})
									)}
								</Table>
							</Card>
						)}
					</Container>
				</div>
				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</div>
	);
}

export default IncompletedShipmentList;
