import React from 'react';
import Card from 'react-bootstrap/Card';

function ClientCard({ title, text, imgSrc }) {
	return (
		<Card style={{ width: '100%' }} bg='light' className='abs-center'>
			<Card.Img variant='top' src={imgSrc} style={{ height: '310px' }} />
			<Card.Body>
				<Card.Title>{title}</Card.Title>
				<Card.Text style={{ minHeight: '75px' }}>{text}</Card.Text>
			</Card.Body>
		</Card>
	);
}

export default ClientCard;
