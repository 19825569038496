import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MdAddchart } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import DynamicTable from '../components/DynamicTable';
import FilterForm from '../components/FilterForm';
import MessageBox from '../components/MessageBox';
import Pagination from '../components/Pagination/Pagination';
import { getError } from '../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST_SHIPMENTS':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS_SHIPMENTS':
		return {
			...state,
			shipments: action.payload.shipments,
			allShipments: action.payload.allShipments,
			accounts: action.payload.accounts,
			page: action.payload.page,
			pages: action.payload.pages,
			countShipments: action.payload.countShipments,
			pageSize: action.payload.pageSize,
			loading: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_REQUEST_DAILYROUTES':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS_DAILYROUTES':
		return {
			...state,
			dailyRoutes: action.payload.dailyRoutes,
			allDailyRoutes: action.payload.allDailyRoutes,
			page: action.payload.page,
			pages: action.payload.pages,
			countDailyRoutes: action.payload.countDailyRoutes,
			pageSize: action.payload.pageSize,
			loading: false,
		};
	case 'FETCH_FAIL_DAILYROUTES':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

export default function Reports() {
	const [
		{
			loading,
			error,
			shipments,
			pageSize,
			countShipments,
			allShipments,
			dailyRoutes,
			allDailyRoutes,
			countDailyRoutes,
		},
		dispatch,
	] = useReducer(reducer, {
		allShipments: [],
		shipments: [],
		accounts: [],
		dailyRoutes: [],
		allDailyRoutes: [],
		loading: true,
		error: '',
		pageSize: 0,
		countShipments: 0,
		countDailyRoutes: 0,
	});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const { search } = useLocation();

	const [selectedReport, setSelectedReport] = useState('');
	const [filterOptions, setFilterOptions] = useState({});
	const [zoneListFetched, setZoneListFetched] = useState(false);
	const [vehicleListFetched, setVehicleListFetched] = useState(false);
	const [accountListFetched, setAccountsListFetched] = useState(false);

	const navigate = useNavigate();

	const sp = new URLSearchParams(search);
	let page = sp.get('page') || 1;
	const dateFrom = sp.get('dateFrom') || '';
	const dateTo = sp.get('dateTo') || '';
	const status = sp.get('status') || '';
	const zone = sp.get('zone') || '';
	const account = sp.get('account') || '';
	const vehicle = sp.get('vehicle') || '';

	const [filtered, setFiltered] = useState(false);
	const [zoneNamesList, setZoneNamesList] = useState();
	const [vehiclesNamesList, setVehiclesNamesList] = useState();
	const [accountsList, setAccountsList] = useState();

	const onPageChange = (page) => {
		navigate(getFilterUrl({ page: page }));
	};
	const reports = [
		{ value: 'Envios por Movil', label: 'Envios por movil' },
		{ value: 'Envios por Zona', label: 'Envios por zona' },
	];

	const getShipments = async () => {
		if (userInfo) {
			try {
				dispatch({ type: 'FETCH_REQUEST_SHIPMENTS' });
				const { data } = await axios.get('/api/orders/reportsShipments/search' + search, {
					params: { Id: userInfo._id },
				});
				dispatch({ type: 'FETCH_SUCCESS_SHIPMENTS', payload: data });
				if (!accountListFetched && data.shipments.length !== 0) {
					setAccountsList(data.accounts);
					setAccountsListFetched(true);
					if (data.accounts.length === 1) {
						setFilterOptions((prevOptions) => ({
							...prevOptions,
							account: data.accounts[0]._id,
						}));
					}
				}
				if (!zoneListFetched && data.shipments.length !== 0) {
					const zoneList = [];
					data.shipments.forEach((shipment) => {
						const zoneId = shipment.route.destinationAddress.zone._id;
						const zoneName = shipment.route.destinationAddress.zone.name;
						const existingZone = zoneList.find((zone) => zone._id === zoneId);
						if (!existingZone) {
							zoneList.push({ _id: zoneId, name: zoneName });
						}
					});
					const zoneListJson = JSON.stringify(zoneList);
					setZoneNamesList(zoneListJson);
					setZoneListFetched(true);
				}
			} catch (err) {
				console.error('Error fetching shipments:', err);
				dispatch({ type: 'FETCH_FAIL_SHIPMENTS', payload: getError(err) });
			}
		} else {
			navigate('/');
		}
	};
	const getDailyRoutes = async () => {
		if (userInfo) {
			try {
				dispatch({ type: 'FETCH_REQUEST_DAILYROUTES' });
				const { data } = await axios.get('/api/dailyRoutes/reportsDailyRoutes/search' + search);
				dispatch({ type: 'FETCH_SUCCESS_DAILYROUTES', payload: data });
				if (!vehicleListFetched && data.dailyRoutes.length !== 0) {
					const vehiclesList = [];
					data.dailyRoutes.forEach((dailyRoute) => {
						const vehicleId = dailyRoute.vehicle._id;
						const vehicleName =
              dailyRoute.vehicle.brand +
              ' ' +
              dailyRoute.vehicle.model +
              ' ' +
              dailyRoute.vehicle.registrationNumber;
						const existingVehicle = vehiclesList.find((vehicle) => vehicle.name === vehicleName);
						if (!existingVehicle) {
							vehiclesList.push({ _id: vehicleId, name: vehicleName });
						}
					});
					const vehicleListJson = JSON.stringify(vehiclesList);
					setVehiclesNamesList(vehicleListJson);
					setVehicleListFetched(true);
				}
			} catch (err) {
				console.error('Error fetching shipments:', err);
				dispatch({ type: 'FETCH_FAIL_DAILYROUTES', payload: getError(err) });
			}
		} else {
			navigate('/');
		}
	};

	useEffect(() => {
		if (selectedReport === 'Envios por Zona') {
			getShipments();
		}
		if (selectedReport === 'Envios por Movil') {
			getDailyRoutes();
		}
	}, [userInfo, search, page, filterOptions.formType]);

	useEffect(() => {
		handleApplyFilter();
	}, [filtered, filterOptions.account, filterOptions.status, filterOptions.zone, filterOptions.dateFrom, filterOptions.dateTo, filterOptions.vehicle]);

	const handleClearFilter = () => {
		if (selectedReport === 'Envios por Zona') {
			setFilterOptions({
				formType: 'Envios por Zona',
				status: '',
				dateFrom: '',
				dateTo: '',
				zone: '',
				account: '',
				page: 1,
			});
			setFiltered(false);
		}
		if (selectedReport === 'Envios por Movil') {
			setFilterOptions({
				formType: 'Envios por Movil',
				dateFrom: '',
				dateTo: '',
				vehicle: '',
				page: 1,
			});
			setFiltered(false);
		}
	};

	const handleInputReportChange = (e) => {
		setSelectedReport(e.target.value);
		if (e.target.value === 'Envios por Zona') {
			setFilterOptions({
				formType: 'Envios por Zona',
				status: status,
				dateFrom: dateFrom,
				dateTo: dateTo,
				zone: zone,
				account: account,
				page: 1,
			});
		}
		if (e.target.value === 'Envios por Movil') {
			setFilterOptions({
				formType: 'Envios por Movil',
				dateFrom: dateFrom,
				dateTo: dateTo,
				vehicle: vehicle,
				page: 1,
			});
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilterOptions((prevOptions) => ({
			...prevOptions,
			[name]: value,
			formType: filterOptions.formType,
		}));
		if (name === 'account') {
			setFilterOptions((prevOptions) => ({
				...prevOptions,
				zone: '',
			}));
			setZoneListFetched(false);
		}
	};

	const getFilterUrl = (filter) => {
		const { status, zone, dateFrom, dateTo, vehicle, account } = filterOptions;
		const filterFormType = filterOptions.formType;
		const filterStatus = status || '';
		const filterDateFrom = dateFrom || '';
		const filterDateTo = dateTo || '';
		const filterAccount = account || '';
		const filterZone = zone || '';
		const filterPage = filter.page || 1;
		const filterVehicle = vehicle || '';

		const params = new URLSearchParams();
		if (filterOptions.formType === 'Envios por Zona') {
			params.append('formType', filterFormType);
			params.append('page', filterPage);
			params.append('status', filterStatus);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('zone', filterZone);
			params.append('account', filterAccount);
		}
		if (filterOptions.formType === 'Envios por Movil') {
			params.append('formType', filterFormType);
			params.append('page', filterPage);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('vehicle', filterVehicle);
		}
		return `/AdminScreen/reports/search?${params.toString()}`;
	};

	const handleApplyFilter = () => {
		const filterUrl = getFilterUrl(filterOptions);
		navigate(filterUrl);
		setFiltered(true);
	};

	return (
		<div className='container admin-con flex-column align-items-center'>
			<Row style={{ margin: '5% 0 2.5%' }}>
				<Col className='d-flex align-items-start'>
					<h1 className='text-right m-0'>
						<MdAddchart></MdAddchart> Generación de Reportes
					</h1>
				</Col>
				<Col>
					<label>Reporte a crear</label>
					<Form.Select value={selectedReport} onChange={handleInputReportChange}>
						<option value=''>Seleccione un reporte</option>
						{reports.map((report) => (
							<option key={report.value} value={report.value}>
								{report.label}
							</option>
						))}
					</Form.Select>
				</Col>
			</Row>
			<Row>
				{selectedReport ? (
					<div>
						<h2 className='mb-3'>Filtrar {selectedReport} por:</h2>
						<FilterForm
							filterOptions={filterOptions}
							handleInputChange={handleInputChange}
							clearFilter={() => handleClearFilter(filterOptions.formType)}
							ZoneNamesList={zoneNamesList}
							vehiclesNamesList={vehiclesNamesList}
							accounts={accountsList}
							handleApplyFilter={() => handleApplyFilter(filterOptions.formType)}
						/>
					</div>
				) : (
					''
				)}
			</Row>
			{selectedReport === 'Envios por Zona' && shipments && shipments.length !== 0 ? (
				<>
					<Row>
						<DynamicTable
							data={shipments}
							allData={allShipments}
							loading={loading}
							error={error}
							dataName='shipments'
						/>
					</Row>
					<Row>
						<Pagination
							className='pagination-bar'
							totalCount={countShipments}
							onPageChange={onPageChange}
							currentPage={parseInt(page)}
							pageSize={pageSize}
							siblingCount
						/>
					</Row>
				</>
			) : selectedReport === 'Envios por Movil' && dailyRoutes && dailyRoutes.length !== 0 ? (
				<>
					<Row>
						<DynamicTable
							data={dailyRoutes}
							allData={allDailyRoutes}
							loading={loading}
							error={error}
							dataName='dailyRoutes'
						/>
					</Row>
					<Row>
						<Pagination
							className='pagination-bar'
							totalCount={countDailyRoutes}
							onPageChange={onPageChange}
							currentPage={parseInt(page)}
							pageSize={pageSize}
							siblingCount
						/>
					</Row>
				</>
			) : selectedReport ? (
				<div className='container m-3'>
					<Row>
						<MessageBox variant='light'>No hay resultados...</MessageBox>
					</Row>
				</div>
			) : null}
		</div>
	);
}
