import { SkeletonText } from '@chakra-ui/react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useReducer } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Geocode from 'react-geocode';
import { GoogleMapsApiKey } from '../../utils';
import './ContactDetails.css';
//#region REDUCER
const reducer = (state, action) => {
	switch (action.type) {
	//#region GOOGLE API ACTIONS
	case 'SET_CENTER':
		return { ...state, center: action.payload };
	case 'SET_GMAP':
		return { ...state, gMap: action.payload };
	case 'SET_ADDRESS':
		return { ...state, address: action.payload };
		//#endregion
	default:
		return state;
	}
};
//#endregion

function ContactDetails({ show, contact, onHide, admin = false }) {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: GoogleMapsApiKey,
		libraries: ['places', 'geometry'],
	});
	Geocode.setApiKey(GoogleMapsApiKey);
	const [
		{  address,  center,  },
		dispatch,
	] = useReducer(reducer, {
		error: '',
		address: '-',
		//#region GOOGLE API STATES
		gMap: null,
		center: {},
		map: null,
		//#endregion
	});

	useEffect(() => {
		const getAddress = async () => {
			if (validateCoord(contact.destination)) {
				const { results } = await Geocode.fromLatLng(
					contact.destination.lat,
					contact.destination.lng
				);
				dispatch({
					type: 'SET_ADDRESS',
					payload: results[0].formatted_address,
				});
				dispatch({
					type: 'SET_CENTER',
					payload: {
						lat: contact.destination.lat,
						lng: contact.destination.lng,
					},
				});
			}
		};
		if (contact && show) {
			getAddress();
		}
	}, [contact]);

	const validateCoord = (coord) =>
		coord && coord.lat != null && coord.lng != null;
	if (!isLoaded) {
		return <SkeletonText />;
	}
	return (
		<Modal
			dialogClassName="contact-details-modal"
			size="xl"
			show={show}
			onHide={onHide}
			animation={true}
		>
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal.Header closeButton>
				<Modal.Title>
					<span className="contact-details-title">Detalles del Contacto</span>
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlid="name">
						<Form.Label>Nombre</Form.Label>
						<Form.Control
							type="text"
							required
							disabled
							value={contact.name}
						></Form.Control>
					</Form.Group>
					<Form.Group className="mb-3" controlid="email">
						<Form.Label>E-mail</Form.Label>
						<Form.Control
							disabled
							type="email"
							required
							value={contact.email}
						></Form.Control>
					</Form.Group>

					<Form.Group className="mb-3" controlid="phone">
						<Form.Label>Telefono</Form.Label>
						<Form.Control
							disabled
							type="text"
							defaultValue={contact.phone}
						></Form.Control>
					</Form.Group>

					{admin && (
						<Form.Group className="mb-3" controlid="account">
							<Form.Label>Cuenta</Form.Label>
							<Form.Control
								disabled
								type="text"
								value={contact.account ? contact.account.name : ''}
							></Form.Control>
						</Form.Group>
					)}
				</Form>

				<Form.Group className="mb-3" controlid="address">
					<Form.Label>Direccion</Form.Label>
					<Form.Control disabled type="text" value={address}></Form.Control>
				</Form.Group>
				{contact.destination && (
					<div className="google-map-container">
						<GoogleMap
							center={center || null}
							zoom={15}
							mapContainerStyle={{
								width: '100%',
								height: '100%',
							}}
							options={{
								zoomControl: true,
								streetViewControl: false,
								mapTypeControl: false,
								fullscreenControl: false,
								scaleControl: true,
							}}
							onLoad={(map) => dispatch({ type: 'SET_GMAP', payload: map })}
						>
							<Marker
								position={{
									lat: contact.destination.lat,
									lng: contact.destination.lng,
								}}
							/>
						</GoogleMap>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
}
export default ContactDetails;
