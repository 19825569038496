import React, { useContext, useState, useEffect } from 'react';
import './EmailConfig.css';
import { Store } from '../../Store';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaRegTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import { regexEmail } from '../../utils';
import BtnPlus from '../BtnPlus/BtnPlus';

export default function EmailConfig() {
	const [emails, setEmails] = useState([]);
	const [loading, setLoading] = useState(true);
	const { state } = useContext(Store);
	const { userInfo } = state;
	const [emailToAdd, setEmailToAdd] = useState('');

	useEffect(() => {
		const fetchInfo = async () => {
			try {
				const { data } =
          (await axios.get('/api/config', {
          	headers: {
          		Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
          	},
          })) || {};
				if (data) {
					setEmails(data.companyEmail);
					setLoading(false);
				}
			} catch (ex) {
				console.error(ex);
			}
		};
		fetchInfo();
	}, []);

	useEffect(() => {
		if (!loading) {
			saveEmailsHandler();
		}
	}, [emails]);

	const addEmailHandler = async (e) => {
		e.preventDefault();
		if (regexEmail.test(emailToAdd) && emails.indexOf(emailToAdd) === -1) {
			let newEmails = [emailToAdd].concat(emails);
			setEmails(newEmails);
		} else {
			toast.error('Direccion de e-mail inválida.');
		}
	};

	const deleteEmailHandler = async (email) => {
		let deleteIndex = emails.indexOf(email);
		if (deleteIndex != -1) {
			let newEmails = new Array(...emails);
			newEmails.splice(deleteIndex, 1);
			setEmails(newEmails);
		}
	};

	const saveEmailsHandler = async () => {
		try {
			const res = await axios.put(
				'/api/config/emails',
				{
					newEmails: emails,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			console.error('response', res);
			toast.success('Emails actualizados');
		} catch (ex) {
			toast.error('Error al guardar e-mails. Intente de nuevo');
		}
	};

	return (
		<div className="email-config">
			<p>Agregue aquí los correos electrónicos de los administradores.</p>
			<Form className="row align-items-center" onSubmit={addEmailHandler}>
				<Form.Group className="col-lg-6 col-10" controlId="name">
					<Form.Control
						onChange={(e) => setEmailToAdd(e.target.value)}
						required
					></Form.Control>
				</Form.Group>
				<BtnPlus type="submit"></BtnPlus>
			</Form>
			<div className="container  ps-0 mt-2">
				<ListGroup className="mb-1 email-list">
					{emails.length > 0 &&
            emails.map((email, index) => {
            	return (
            		<ListGroup.Item key={index}>
            			<Row className="justify-content-between align-items-center">
            				<Col xs={11}>
            					<div className="email">{email}</div>
            				</Col>
            				<Col xs={1}>
            					<Button
            						variant="light"
            						className="p-0"
            						onClick={() => deleteEmailHandler(email)}
            					>
            						<FaRegTrashAlt></FaRegTrashAlt>
            					</Button>
            				</Col>
            			</Row>
            		</ListGroup.Item>
            	);
            })}
				</ListGroup>
			</div>
		</div>
	);
}
