import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Dropdown,
	DropdownButton,
	Modal,
	Row,
	Table,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import {
	BsFileEarmarkRichtext,
	BsPencilSquare,
	BsPlusCircle,
	BsTrash,
} from 'react-icons/bs';
import { FaIdCard } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, getStatus, maxitemsPerPage } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			users: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function UsersAdminList() {
	const [{ loading, error, users, successDelete, itemQuantity }, dispatch] =
    useReducer(reducer, {
    	users: [],
    	loading: true,
    	error: '',
    	itemQuantity: 0,
    });

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);
	};

	//MODAL
	const [userToDelete, setUserToDelete] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				//GET USERS ADMINS (true as param)
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/users/deliveries/getDeliveries', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(getError(error));
				}
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function handleDelete(user) {
		setUserToDelete(user);
		handleShow();
	}

	async function deleteUserHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.delete(`/api/users/${userToDelete._id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'DELETE_SUCCESS' });
			handleClose();
			toast.success('Usuario Eliminado');
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	}

	async function editUserHandler(user) {
		navigate(`/AdminScreen/editUser/${user._id}`);
	}

	async function detailsUserHandler(user) {
		navigate(`/AdminScreen/user/${user._id}`);
	}

	async function newUserHandler() {
		navigate('/AdminScreen/newAdmin');
	}

	return (
		<div>
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Usuario</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar el usuario?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deleteUserHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<Helmet>
				<title>Lista de Repartidores</title>
			</Helmet>

			<div className="container admin-con">
				<div className="w-100" style={{ overflow: 'visible' }}>
					<h3 className="text-right mt-3 mb-3" style={{ margin: '2.5% 0' }}>
						<FaIdCard /> Repartidores
					</h3>
					<div
						style={{
							display: 'flex',
							justifyContent: 'right',
							alignItems: 'center',
						}}
					>
						<Button
							className="btn btn-dark m-1 fixed-right"
							value="Crear Usuario"
							onClick={() => newUserHandler()}
						>
							<span>
								<BsPlusCircle /> Crear Repartidor
							</span>
						</Button>
					</div>
					{loading ? (
						<Row className="justify-content-center spinner-row">
							<LoadingBox className="col-1" />
						</Row>
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Card>
							<Table responsive size="sm">
								<thead className="align-items-center table-order tHead">
									<th className="col-lg-3 tableHeader">Nombre</th>
									<th className="col-lg-3 tableHeader">Email</th>
									<th className="col-lg-3 tableHeader">Vehiculo</th>
									<th className="col-lg-1 tableHeader">Acciones</th>
								</thead>
								{currentPosts.map((user) => (
									<tbody
										key={user.name}
										className="align-items-center table-order tableBodyHover"
									>
										<tr key={user._id}>
											<td className="col-lg-3 tableBody">{user.name}</td>
											<td className="col-lg-3 tableBody">{user.email}</td>
											<td className="col-lg-3 tableBody">{user.vehicle ? (`${user.vehicle.brand} ${user.vehicle.model} - ${user.vehicle.registrationNumber}`)
												: 'no'}</td>
											<td className="col-lg-1 tableBody">
												<DropdownButton drop="start" title="" style={{ position: 'static' }}>
													<Dropdown.Item
														onClick={() => detailsUserHandler(user)}
													>
														<BsFileEarmarkRichtext />
                            Ver Detalles
													</Dropdown.Item>
													<Dropdown.Item onClick={() => editUserHandler(user)}>
														<BsPencilSquare />
                            Editar
													</Dropdown.Item>
													<Dropdown.Item onClick={() => handleDelete(user)}>
														<BsTrash> </BsTrash>Eliminar
													</Dropdown.Item>
												</DropdownButton>
											</td>
										</tr>
									</tbody>
								))}
							</Table>
						</Card>
					)}
				</div>
				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</div>
	);
}

export default UsersAdminList;
