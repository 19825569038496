import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import LoadingBox from '../components/LoadingBox';
import { getError } from '../utils';

export default function VerifyUser() {
	const navigate = useNavigate();
	const params = useParams();
	const { token } = params;

	const reducer = (state, action) => {
		switch (action.type) {
		case 'FETCH_REQUEST':
			return { ...state, loading: true };
		case 'FETCH_SUCCESS':
			return { ...state, user: action.payload, loading: false };
		case 'FETCH_FAIL':
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
		}
	};

	useEffect(() => {
		const verifyUser = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.put(`/api/users/verifyUser/${token}`);
				ctxDispatch({ type: 'FETCH_SUCCESS', payload: data });
				toast.success('Usuario verificado correctamente');
				navigate('/signin');
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				toast.error(getError(err));
			}
		};
		verifyUser();
	}, [navigate]);

	const { dispatch: ctxDispatch } = useContext(Store);
	const [{loading}, dispatch] = useReducer(reducer, {		loading: true,
	});

	return <div>			
		{loading && <LoadingBox></LoadingBox>}
	</div>;
}
