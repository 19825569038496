import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError, getStatus } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			debts: action.payload,
			loadingFetch: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};

function AddPayment() {
	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [amount, setAmount] = useState(null);
	const [debt, setDebt] = useState({});
	const [debtAmount, setDebtAmount] = useState('');
	const [debtOwner, setDebtOwner] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [date, setDate] = useState(new Date());
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);
	const today = new Date().toISOString().substring(0, 10);

	const [{ loadingFetch, loadingCreate, debts }, dispatch] = useReducer(
		reducer,
		{
			loadingFetch: true,
			debts: [],
			loadingCreate: false,
			error: '',
		}
	);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			if (userInfo.isAdmin) {
				try {
					const result = await axios.get('/api/debts', {
						headers: {
							Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
						},
					});
					dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
					setDebt(result.data[0]);
				} catch (error) {
					dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
					if (getStatus(error) === 401) {
						ctxDispatch({ type: 'USER_SIGNOUT' });
						navigate('/signin');
						toast.error('Sesion expirada. Vuelve a ingresar.');
					} else {
						console.error(error);
						toast.error(getError(error));
					}
				}
			} else {
				await getAccount();
			}
		};
		fetchData();
	}, []);

	//GET ACCOUNT OF USER
	const getAccount = async () => {
		try {
			const { data } = await axios.get(`/api/users/user/${userInfo._id}`);
			getDebts(data._id);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//GET DEBTS OF ACCOUNT
	const getDebts = async (accountId) => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const response = await axios.get(
				`/api/debts/getDebtByAccount/${accountId}`
			);
			dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
			setDebt(response.data[0]);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const handlePago = async () => {
		handleShow();
		const debtToShow = debts.find((d) => d._id === debt);
		setDebtAmount(Math.round(debtToShow.amount));
		setDebtOwner(debtToShow.owner ? debtToShow.owner.name : 'sin cuenta');
	};

	const addPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await axios.post(
				'/api/payments/createPayment',
				{
					amount,
					date,
					debt,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			toast.success('Pago creado');
			if (userInfo.isAdmin) {
				navigate('/AdminScreen/payments');
			} else {
				navigate('/payments');
			}
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	return (
		<div>
			{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Agregar Pago</Modal.Title>
				</Modal.Header>
				<Modal.Body>
          Seguro desea agregar un pago de ${amount} a la deuda {debtOwner} : $
					{debtAmount}?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={addPaymentHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Container className="large-container">
				<Helmet>
					<title>Agregar Pago</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Agregar Pago
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Row>
													<Col md={5}>
														<Form.Group className="mb-3" controlId="amount">
															<Form.Label>Monto</Form.Label>
															<Form.Control
																type="number"
																max={debt.balance}
																onChange={(e) => setAmount(e.target.value)}
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group className="mb-3" controlId="date">
															<Form.Label>Fecha de Pago</Form.Label>
															<Form.Control
																defaultValue={today}
																type="date"
																onChange={(e) => {
																	setDate(e.target.value);
																}}
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<Form.Group className="mb-3" controlId="owner">
													<Form.Label>Selecciona una deuda</Form.Label>
													<Form.Select
														className="border border-1"
														onChange={async (e) => {
															await setDebt(e.target.value);
														}}
													>
														{debts.map((debt) => (
															<option key={debt._id} value={debt._id}>
																{(debt.owner ? debt.owner.name : 'sin cuenta') +
                                  ': $' +
                                  Math.round(debt.amount)}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handlePago}
													>
                            Agregar Pago
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddPayment;
