import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Form,
	Modal,
	OverlayTrigger,
	Row,
	Tooltip,
} from 'react-bootstrap';
import { BsArrowDown, BsArrowUp, BsXLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Store } from '../../Store.js';

// ...


//CHANGE ROUTE ARRAY FOR SHIPMENT LIST `1

const RouteEdition = ({
	show,
	routesArray,
	index,
	onHide,
	onRecalculateRoute,
}) => {

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//MODAL
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	//ROUTE
	const [sortedOrders, setSortedOrders] = useState(
		routesArray[index].sortedOrders
	);
	const [deleteIndex, setDeleteIndex] = useState(null);
	const [optimize, setOptimize] = useState(true);

	useEffect(() => {
		if (!userInfo || (userInfo.isAdmin && !userInfo.isSuperAdmin)) {
			navigate('/');
			return;
		}
	}, [show]);

	async function handleDelete(i) {
		setDeleteIndex(i);
		handleShow();
	}

	async function deleteStopHandler() {
		const updatedSortedOrders = [...sortedOrders];
		updatedSortedOrders.splice(deleteIndex, 1);
		setSortedOrders(updatedSortedOrders);
		handleClose();
	}

	async function moveBefore(currentIndex) {
		if (currentIndex > 0 && currentIndex < sortedOrders.length) {
			const updatedSortedOrders = [...sortedOrders];

			[
				updatedSortedOrders[currentIndex],
				updatedSortedOrders[currentIndex - 1],
			] = [
				updatedSortedOrders[currentIndex - 1],
				updatedSortedOrders[currentIndex],
			];

			setSortedOrders(updatedSortedOrders);
			setOptimize(false);
		}
	}

	const moveAfter = (currentIndex) => {
		if (currentIndex >= 0 && currentIndex < sortedOrders.length - 1) {
			const updatedSortedOrders = [...sortedOrders];

			[
				updatedSortedOrders[currentIndex],
				updatedSortedOrders[currentIndex + 1],
			] = [
				updatedSortedOrders[currentIndex + 1],
				updatedSortedOrders[currentIndex],
			];

			setSortedOrders(updatedSortedOrders);
			setOptimize(false);
		}
	};

	async function confirmChanges() {
		await onRecalculateRoute(sortedOrders, index, optimize);
		onHide();
	}

	async function cancelChanges() {
		onRecalculateRoute(null, index);
		onHide();
	}

	return (
		<div>
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Destino</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar el destino?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deleteStopHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<Card className="mb-2 bg-light ">
				<Card.Body>
					{sortedOrders.length > 0 ? (
						sortedOrders.map((r, i) => {
							return (
								<Row key={i} className="mb-1">
									<Col
										xs={12}
										md={12}
										className="m-0 d-flex align-items-center"
									>
										<OverlayTrigger
											key="top"
											placement="top"
											overlay={
												<Tooltip id={'tooltip-top'} style={{ zIndex: 9999 }}>
                          Quitar Destino
												</Tooltip>
											}
										>
											<Button
												style={{ marginRight: '8px' }}
												className="btn-sm p-1"
												onClick={() => handleDelete(i)}
											>
												<BsXLg />
											</Button>
										</OverlayTrigger>

										<Form.Control
											type="text"
											value={r.route.destinationAddress.address.split(',')[0]}
											readOnly
											className="p-0 text-truncate"
										/>

										<Button
											style={{ marginLeft: '8px' }}
											className="btn-sm p-1"
											onClick={() => moveBefore(i)}
											disabled={i < 1}
										>
											<BsArrowUp />
										</Button>

										<Button
											style={{ marginLeft: '8px' }}
											className="btn-sm p-1"
											onClick={() => moveAfter(i)}
											disabled={i === sortedOrders.length-1}

										>
											<BsArrowDown />
										</Button>
									</Col>
								</Row>
							);
						})
					) : (
						<div>{'Ruta sin envios'}</div>
					)}
					<div className="text-end">
						<Row className="mt-2 justify-content-end">
							<Col >
								<Button
									onClick={() => cancelChanges()}
									className={'mt-1 mb-1'}
								>
                  Cancelar
								</Button>
							</Col>
							<Col >
								<Button
									onClick={() => confirmChanges()}
									className={'mt-1 mb-1'}
								>
                  Confirmar
								</Button>
							</Col>
						</Row>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
};

export default RouteEdition;
