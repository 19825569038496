import { SkeletonText } from '@chakra-ui/react';
import {
	Autocomplete,
	GoogleMap,
	Marker,
	Polygon,
	useJsApiLoader,
} from '@react-google-maps/api';
import axios from 'axios';
import React, {
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import {
	Accordion,
	Button,
	Card,
	Col,
	Container,
	FloatingLabel,
	Form,
	Modal,
	OverlayTrigger,
	Row,
	Stack,
	Table,
	Tooltip,
} from 'react-bootstrap';
import Geocode from 'react-geocode';
import { Helmet } from 'react-helmet-async';
import {
	BsArrowLeftCircle,
	BsCheckSquareFill,
	BsGeoAltFill,
	BsPencilSquare,
	BsPlusCircle,
	BsSave,
	BsTrash,
	BsXSquareFill,
} from 'react-icons/bs';
import { RiPaintFill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { GoogleMapsApiKey, getError, getStatus } from '../../utils';



const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, zones: action.payload, loading: false };
	case 'FETCH_TYPES_REQUEST':
		return { ...state, loadingTypes: true };
	case 'FETCH_TYPES_SUCCESS':
		return {
			...state,
			loadingTypes: false,
			firstTypes: action.payload.slice(
				0,
				Math.ceil(action.payload.length / 2)
			),
			secondTypes: action.payload.slice(Math.ceil(action.payload.length / 2)),
		};
	case 'FETCH_REL_REQUEST':
		return { ...state, loadingRel: true };
	case 'FETCH_REL_SUCCESS':
		return { ...state, rels: action.payload, loadingRel: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	case 'EDIT_REQUEST':
		return { ...state, loading: true };
	case 'EDIT_FAIL':
		return { ...state, loading: false };
	case 'EDIT_SUCCESS':
		return {
			...state,
			loading: false,
		};
	case 'EDIT_COLOR_REQUEST':
		return { ...state, loading: true };
	case 'EDIT_COLOR_FAIL':
		return { ...state, loading: false };
	case 'EDIT_COLOR_SUCCESS':
		return {
			...state,
			loading: false,
		};
	default:
		return state;
	}
};

function AddZones() {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: GoogleMapsApiKey,
		libraries: ['places', 'geometry'],
	});
	Geocode.setApiKey(GoogleMapsApiKey);
	const [
		{
			loading,
			error,
			zones,
			successDelete,
			loadingCreate,
			loadingDelete,
			loadingTypes,
			firstTypes,
			secondTypes,
			rels,
			loadingRel,
		},
		dispatch,
	] = useReducer(reducer, {
		zones: [],
		firstTypes: [],
		secondTypes: [],
		rels: [],
		loadingRel: true,
		loadingTypes: true,
		loadingMap: true,
		loading: true,
		loadingCreate: false,
		loadingDelete: false,
		error: '',
	});

	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	//MODAL
	const [zoneToDelete, setZoneToDelete] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	// Store Polygon path in state
	const [path, setPath] = useState([]);

	// const initialPath = [
	// 	{ lat: -34.81461606054473, lng: -56.194258499189004 },
	// 	{ lat: -34.84811639706187, lng: -56.21850567781073 },
	// 	{ lat: -34.83818398868773, lng: -56.14696582537315 },
	// ];

	const [polygons, setPolygons] = useState([zones]);

	const [toSave, setToSave] = useState();
	const [nameValue, setName] = useState('');
	const [colorValue, setColor] = useState('');
	const [isRed, setIsRed] = useState(false);
	const [center, setCenter] = useState({ lat: null, lng: null });

	const [originMarker, setOriginMarker] = useState({
		lat: null,
		lng: null,
	});

	//AUTOCOMPLETE
	const [autocomplete, setAutocomplete] = useState(null);

	//AUTOCOMPLETE HANDLING
	//loading autocomplete
	const onLoadAutoComplete = (autocompleteInstance) => {
		setAutocomplete(autocompleteInstance);
	};

	//PRICES FOR TYPES AND ZONES
	const [newTypeZoneRel, setNewTypeZoneRel] = useState([]);
	const [editableZone, setEditableZone] = useState({});
	const [editableZoneColor, setEditableZoneColor] = useState({});
	const [newColor, setNewColor] = useState();

	/** @type React.MutableRefObject<HTMLInputElement> */
	const originRef = useRef(null);
	// Define refs for Polygon instance and listeners
	const polygonRef = useRef(null);
	const listenersRef = useRef([]);

	//GET ZONES
	useEffect(() => {
		setToSave(false);
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	const fetchData = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const { data } = await axios.get(`/api/zones/map/${id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
			await getCenter();
			setPolygons(data);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	const getCenter = async () => {
		try {
			const { data } = await axios.get(`/api/maps/${id}`);
			await setCenter({ lat: data.latCentral, lng: data.lngCentral });
			setOriginMarker({
				lat: data.latCentral,
				lng: data.lngCentral,
			});
			if (originRef.current) {
				Geocode.fromLatLng(data.latCentral, data.lngCentral).then(
					(response) => {
						originRef.current.value = response.results[0].formatted_address;
					},
					(error) => {
						console.error(error);
					}
				);
			}
			await getTypes(data.account);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//GET TYPES BY ACCOUNT
	const getTypes = async () => {
		try {
			dispatch({ type: 'FETCH_TYPES_REQUEST' });
			const { data } = await axios.get('/api/shipmentTypes/');
			const sortedTypes = await data.reduce((acc, element) => {
				if (element.name === 'Normal') {
					return [element, ...acc];
				}
				return [...acc, element];
			}, []);
			dispatch({ type: 'FETCH_TYPES_SUCCESS', payload: sortedTypes });
			await getRelation(id);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//GET TYPE-ZONE RELATION BY ACCOUNT
	const getRelation = async (mapId) => {
		try {
			dispatch({ type: 'FETCH_REL_REQUEST' });
			const { data } = await axios.get(
				`/api/typeZoneRelation/relationByMap/${mapId}`
			);
			dispatch({ type: 'FETCH_REL_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	// Call setPath with new edited path
	const onEdit = useCallback(() => {
		if (polygonRef.current) {
			const nextPath = polygonRef.current
				.getPath()
				.getArray()
				.map((latLng) => {
					return { lat: latLng.lat(), lng: latLng.lng() };
				});

			setPath(nextPath);
		}
	}, [setPath]);

	// Bind refs to current Polygon and listeners
	const onLoad = useCallback(
		(polygon) => {
			polygonRef.current = polygon;
			const path = polygon.getPath();
			listenersRef.current.push(
				path.addListener('set_at', onEdit),
				path.addListener('insert_at', onEdit),
				path.addListener('remove_at', onEdit)
			);
		},
		[onEdit]
	);

	// Clean up refs
	const onUnmount = useCallback(() => {
		listenersRef.current.forEach((lis) => lis.remove());
		polygonRef.current = null;
	}, []);

	//ADD ZONE
	function newZoneHandler() {
		setPath([
			{
				lat: originMarker.lat - 0.0154,
				lng: originMarker.lng + 0.0005,
			},
			{
				lat: originMarker.lat + 0.0159, // Adjust the latitudes as needed
				lng: originMarker.lng - 0.0226, // Adjust the longitudes as needed
			},
			{
				lat: originMarker.lat + 0.0165, // Adjust the latitudes as needed
				lng: originMarker.lng + 0.0206, // Adjust the longitudes as needed
			},
		]);
		setIsRed(false);

		setColor(
			'#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')
		);

	
		setName(`Zone#${zones.length}`);
		setToSave(true);
	}

	//SAVE ZONE
	async function addZoneHandler() {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await axios.post('/api/zones/createZone', {
				name: nameValue,
				location: path,
				color: colorValue,
				map: id,
				isRed: isRed
			});
			setToSave(false);
			fetchData();
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Zona creada');
		} catch (ex) {
			toast.error(getError(ex));
		}
	}


	// EDIT MARKER POSITION
	const onEditOrigin = async (evt) => {
		originMarker.lat = evt.latLng.lat();
		originMarker.lng = evt.latLng.lng();
		Geocode.fromLatLng(evt.latLng.lat(), evt.latLng.lng()).then(
			(response) => {
				originRef.current.value = response.results[0].formatted_address;
			},
			(error) => {
				console.error(error);
			}
		);
	};

	const editOrigin = async () => {
		try {
			dispatch({ type: 'EDIT_REQUEST' });
			await axios.put(
				`/api/maps/editOrigin/${id}`,
				{
					lat: originMarker.lat,
					lng: originMarker.lng,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({ type: 'EDIT_SUCCESS' });
		} catch {
			dispatch({ type: 'EDIT_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	//handling changes autocomplete
	const onOriginChanged = () => {
		if (autocomplete) {
			const originPlace = autocomplete.getPlace();
			setOriginMarker({
				lat: originPlace.geometry.location.lat(),
				lng: originPlace.geometry.location.lng(),
			});
		} else {
			console.warning('Autocomplete is not loaded yet!');
		}
	};

	//PRICE PER TYPE AND ZONE
	//make prices editable by zone
	const editPrices = async (selectedZone) => {
		setEditableZone(selectedZone);
	};

	//EDIT COLOR
	const editColor = async (selectedZone) => {
		setEditableZoneColor(selectedZone);
	};

	const editHandler = async (selectedZone, selectedType, relationPrice) => {
		setNewTypeZoneRel((prevTypeZoneRel) => {
			let createNew = true;
  
			const updatedTypeZoneRel = prevTypeZoneRel.map((element) => {
				if (element.type === selectedType && element.zone === selectedZone) {
					createNew = false;
					return { ...element, price: relationPrice };
				} else {
					return element;
				}
			});
  
			if (createNew) {
				const newRelation = {
					zone: selectedZone,
					type: selectedType,
					price: Number(relationPrice),
				};
				updatedTypeZoneRel.push(newRelation);
			}
  
			return updatedTypeZoneRel;
		});
	};

	//get prices
	function getPrice(selectedZoneId, selectedTypeId) {
		let priceToShow = 0;
		const filterByZone = rels.filter((r) => r.zone._id === selectedZoneId);
		const filterByZoneAndType = filterByZone.find(
			(r) => r.type._id === selectedTypeId
		);
		if (filterByZoneAndType) {
			priceToShow = filterByZoneAndType.price;
		}
		return priceToShow;
	}

	//UPDATE PRICES IN DB
	const savePrices = async () => {
		try {
			dispatch({ type: 'EDIT_REQUEST' });
			await axios.put('/api/typeZoneRelation/savePrices', {
				relArray: newTypeZoneRel,
			});
			dispatch({ type: 'EDIT_SUCCESS' });
			setEditableZone([]);
			//window.location.reload();
		} catch {
			dispatch({ type: 'EDIT_FAIL' });
		}
	};

	//UPDATE COLOR IN DB
	const saveColor = async () => {
		try {
			dispatch({ type: 'EDIT_COLOR_REQUEST' });
			await axios.put(
				`/api/zones/changeColor/${editableZoneColor._id}`,
				{
					color: newColor,
				}
			);
			dispatch({ type: 'EDIT_COLOR_SUCCESS' });
			setEditableZoneColor(null);
			window.location.reload();
		} catch {
			dispatch({ type: 'EDIT_COLOR_FAIL' });
		}
	};

	//DELETE ZONE
	async function handleDelete(zone) {
		setZoneToDelete(zone);
		handleShow();  }

	async function deleteZoneHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.delete(`/api/zones/${zoneToDelete._id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'DELETE_SUCCESS' });
			setShowModal(false);
			toast.success('Zona Eliminada');
		} catch (err) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	}

	if (!isLoaded) {
		return <SkeletonText />;
	}
	return (
		<div>
			{(loading || loadingCreate || loadingDelete) && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Zona</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar la zona?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deleteZoneHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<div className="large-container">
				<Helmet>
					<title>Agregar Zona</title>
				</Helmet>

				<div className="container admin-con">
					<div className="w-100">
						<Row className=" d-flex justify-content-center align-items-center">
							<Col md={12} className="m-2 bg-white">
								<div className="borderLine"></div>
								<div className="mb-3 mt-md-4">
									<div className="mb-3">
										<h2 className="fw-bold mb-3 text-uppercase px-5 text-center">
                      Crear Mapa
										</h2>
										<div className="borderLine"></div>
										<div className="m-1 border border-1"></div>
										<div className="d-flex mt-2 justify-content-center align-items-center">
											{loading ? (
												<LoadingBox></LoadingBox>
											) : (
												<div className="container">
													<div className="w-100">
														<Row>
															<Col md={12}>
																<Form
																	onSubmit={(e) => e.preventDefault()}
																	className="m-2"
																>
																	{toSave ? (
																		<div>

																			<h6 className="mb-3 align-items-center">
                                    NUEVA ZONA
																			</h6>
																			<Form.Group className="mb-3" controlId="name">
																				<Form.Label>Nombre</Form.Label>
																				<Form.Control
																					type="text"
																					value={nameValue}
																					onChange={(e) => setName(e.target.value)}
																				></Form.Control>
																			</Form.Group>

																			<div style={{ display: 'flex', alignItems: 'center' }}>
																				<Form.Group controlId="color" style={{ marginRight: '20px' }}>
																					<Form.Label>Color</Form.Label>
																					<Form.Control
																						type="color"
																						value={colorValue}
																						onChange={(e) => {
																							setColor(e.target.value);
																						}}
																						disabled={isRed}
																					/>
																				</Form.Group>

																				<Form.Group controlId="isRed">
																					<Form.Check
																						type="checkbox"
																						label="Zona Roja"
																						checked={isRed}
																						onChange={(e) => {
																							setIsRed(e.target.checked);
																							if (e.target.checked) {
																								setColor('#fa0505');
																								setName(`RedZone#${zones.length}`);

																							} else {
																								setColor('#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0'));
																								setName(`Zone#${zones.length}`);

																							}
																						}}
																					/>
																				</Form.Group>
																			</div>

																		</div>

																	) : null}

																	<Stack
																		direction="vertical"
																		gap={10}
																		className="mt-3"
																	>
																		<Button
																			className="btn m-1"
																			disabled={toSave}
																			value="Agregar Zona"
																			onClick={() => newZoneHandler()}
																		>
																			<BsPlusCircle /> Agregar Zona
																		</Button>
																		<Button
																			className="btn m-1"
																			disabled={!toSave}
																			value="Crear Zona"
																			onClick={() => addZoneHandler()}
																		>
																			<BsSave /> Guardar Zona
																		</Button>

																		<div className="w-100 border border-1 border-light mb-3"></div>

																		<Form.Group
																			className="mb-1"
																			controlId="name"
																		>
																			<Form.Label>EDITAR ORIGEN</Form.Label>
																			<Autocomplete
																				className="m-2"
																				onLoad={onLoadAutoComplete}
																				onPlaceChanged={onOriginChanged}
																				restrictions={{
																					country: ['uy'],
																				}}
																			>
																				<Form.Control
																					type="text"
																					placeholder="Origen"
																					ref={originRef}
																					required
																				></Form.Control>
																			</Autocomplete>
																		</Form.Group>
																		<Button
																			variant="outline-success"
																			className="btn m-1"
																			value="SetOrigin"
																			onClick={() => editOrigin()}
																		>
																			<BsGeoAltFill /> Guardar Origen
																		</Button>

																		<Button
																			className="m-2"
																			id="goBack"
																			onClick={() => navigate(-1)}
																		>
																			<BsArrowLeftCircle /> Atras
																		</Button>
																	</Stack>
																</Form>
															</Col>
														</Row>

														<Row>
															<Col md={12}>
																<section id="fleet-map-container">
																	<GoogleMap
																		className="px-0"
																		center={center}
																		zoom={12}
																		mapContainerStyle={{
																			width: '100%',
																			height: '100%',
																		}}
																		options={{
																			zoomControl: true,
																			streetViewControl: false,
																			mapTypeControl: false,
																			fullscreenControl: false,
																			scaleControl: true,
																		}}
																	>
																		<Marker
																			label={'O'}
																			position={{
																				lat: originMarker.lat,
																				lng: originMarker.lng,
																			}}
																			draggable
																			onDragEnd={onEditOrigin}
																		/>
																		{toSave ? (
																			<Polygon
																			// Make the Polygon editable / draggable
																				draggable
																				editable
																				options={{
																					fillColor: colorValue,
																					fillOpacity: 0.6,
																					strokeWeight: 1,
																					strokeColor: colorValue,
																				}}
																				path={path}
																				// Event used when manipulating and adding points
																				onMouseUp={onEdit}
																				// Event used when dragging the whole Polygon
																				onDragEnd={onEdit}
																				onLoad={onLoad}
																				onUnmount={onUnmount}
																			/>
																		) :null}

																		<></>
																		{polygons.map(
																			(zone) => (
																				zone['options'] = {
																					fillColor: zone.color,
																					fillOpacity: 0.6,
																					strokeWeight: 1,
																					strokeColor: zone.color
																				},
                                        
																				<Polygon
																					options={zone.options}
																					path={zone.location}
																				/>
                                        
																			)
																		)}
																		<></>
																	</GoogleMap>
																</section>
															</Col>
														</Row>
													</div>
												</div>
											)}
										</div>
										<div className="m-1 border border-1">
											<Container fluid>
												<h5 className="text-center my-3">Lista de Zonas</h5>
												{loadingTypes || loadingRel || loadingCreate ? (
													<LoadingBox></LoadingBox>
												) : error ? (
													<MessageBox variant="danger">{error}</MessageBox>
												) : (
													<Accordion
														className="bg-transparent ml-5"
														defaultActiveKey={0}
														alwaysOpen
													>
														{polygons.map((zone, i) => {
															return (
																<Card className="mb-2 bg-black" key={i}>
																	<Accordion.Item eventKey={i} >
																		<Accordion.Header className="table-container">
																			{zone.name}
																			<Form.Control
																				style={{ margin: '0px 15px' }}
																				type="color"
																				value={zone.color}
																				disabled
																			></Form.Control>
																			{zone.isRed ? null :    'Precio (UYU)'}
                                   
																		</Accordion.Header>
																		{zone.isRed ? (null) : (

																			<Accordion.Body className="p-0">
																				<Table responsive size="sm">
																					<tbody className="align-items-center table-order tableBodyHover">
																						<Row
																							md={12}
																							className="text-center m-1"
																						>
																							{firstTypes.map((t) => {
																								return (
																									<Col className="text-center" key={i}>
																										<OverlayTrigger
																											key="top"
																											placement="top"
																											overlay={
																												<Tooltip
																													id={'tooltip-top'}
																												>
																													{t.name}
																												</Tooltip>
																											}
																										>
																											<FloatingLabel
																												label={t.abbreviation}
																											>
																												<Form.Control
																													size="sm"
																													className="priceByZone"
																													type="number"
																													defaultValue={getPrice(
																														zone._id,
																														t._id
																													)}
																													disabled={
																														!(
																															zone ===
																editableZone
																														)
																													}
																													onChange={(e) => {
																														editHandler(
																															zone,
																															t,
																															e.target.value
																														);
																													}}
																												></Form.Control>
																											</FloatingLabel>
																										</OverlayTrigger>
																									</Col>
																								);
																							})}
																						</Row>
																						<Row
																							md={12}
																							className="text-center m-1"
																						>
																							{secondTypes.map((t,i ) => {
																								return (
																									<Col className="text-center" key={i}>
																										<OverlayTrigger
																											key="top"
																											placement="top"
																											overlay={
																												<Tooltip
																													id={'tooltip-top'}
																												>
																													{t.name}
																												</Tooltip>
																											}
																										>
																											<FloatingLabel
																												label={t.abbreviation}
																											>
																												<Form.Control
																													size="sm"
																													className="priceByZone"
																													type="number"
																													defaultValue={getPrice(
																														zone._id,
																														t._id
																													)}
																													disabled={
																														!(
																															zone ===
																editableZone
																														)
																													}
																													onChange={(e) => {
																														editHandler(
																															zone,
																															t,
																															e.target.value
																														);
																													}}
																												></Form.Control>
																											</FloatingLabel>
																										</OverlayTrigger>
																									</Col>
																								);
																							})}
																						</Row>
																						<Row
																							md={12}
																							className="text-center m-1"
																						>
																							{zone !== editableZone &&
												zone !== editableZoneColor ? (
																									<React.Fragment>
																										<Col
																											md={4}
																											className="text-center"
																										>
																											<Button
																												onClick={() =>
																													editColor(zone)
																												}
																												disabled={zone.isRed}
																											>
																												<RiPaintFill /> Editar Color
																											</Button>
																										</Col>
																										<Col
																											md={4}
																											className="text-center"
																										>
																											<Button
																												onClick={() =>
																													editPrices(zone)
																												}
																											>
																												<BsPencilSquare /> Editar
														Precios
																											</Button>
																										</Col>
																										<Col
																											md={4}
																											className="text-center"
																										>
																											<Button
																												onClick={() =>
																													handleDelete(zone)
																												}
																											>
																												<BsTrash /> Eliminar
																											</Button>
																										</Col>
																									</React.Fragment>
																								) : zone === editableZone ? (
																									<React.Fragment>
																										<Row
																											md={12}
																											className="text-center m-1"
																										>
																											<Col>
																												<OverlayTrigger
																													key="top"
																													placement="top"
																													overlay={
																														<Tooltip
																															id={'tooltip-top'}
																														>
															  Guardar Precios
																														</Tooltip>
																													}
																												>
																													<Button
																														variant="outline-success"
																														className="mt-1"
																														onClick={() =>
																															savePrices()
																														}
																													>
																														<BsCheckSquareFill />
																													</Button>
																												</OverlayTrigger>
																											</Col>
																											<Col>
																												<OverlayTrigger
																													key="top"
																													placement="top"
																													overlay={
																														<Tooltip
																															id={'tooltip-top'}
																														>
															  Cancelar Edicion
																														</Tooltip>
																													}
																												>
																													<Button
																														variant="outline-secondary"
																														className="mt-1"
																														onClick={() =>
																															setEditableZone(null)
																														}
																													>
																														<BsXSquareFill />
																													</Button>
																												</OverlayTrigger>
																											</Col>
																										</Row>
																									</React.Fragment>
																								) : zone === editableZoneColor ? (
																									<React.Fragment>
																										<Row
																											md={12}
																											className="text-center m-1"
																										>
																											<Col>
																												<OverlayTrigger
																													key="top"
																													placement="top"
																													overlay={
																														<Tooltip
																															id={'tooltip-top'}
																														>
															  Guardar Color
																														</Tooltip>
																													}
																												>
																													<Button
																														variant="outline-success"
																														className="mt-1"
																														onClick={() =>
																															saveColor()
																														}
																													>
																														<BsCheckSquareFill />{' '}
																													</Button>
																												</OverlayTrigger>
																											</Col>
																											<Col>
																												<OverlayTrigger
																													key="top"
																													placement="top"
																													overlay={
																														<Tooltip
																															id={'tooltip-top'}
																														>
																															{' '}
															  Cancelar Edicion
																														</Tooltip>
																													}
																												>
																													<Button
																														variant="outline-secondary"
																														className="mt-1"
																														onClick={() =>
																															setEditableZoneColor(
																																null
																															)
																														}
																													>
																														<BsXSquareFill />{' '}
																													</Button>
																												</OverlayTrigger>
																											</Col>
																											<Col>
																												<Form.Control
																													style={{
																														margin: '0 auto',
																													}}
																													type="color"
																													disabled={
																														!(
																															zone ===
															  editableZoneColor
																														)
																													}
																													defaultValue={zone.color}
																													onChange={(e) => {
																														if (
																															zone ===
															  editableZoneColor
																														) {
																															setNewColor(
																																e.target.value
																															);
																														}
																													}}
																												></Form.Control>
																											</Col>
																										</Row>
																									</React.Fragment>
																								) : null}
																						</Row>
																					</tbody>
																				</Table>
																			</Accordion.Body>
																		)}
																	</Accordion.Item>
																</Card>
															);
														})}
													</Accordion>
												)}
											</Container>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>
	);
}
export default AddZones;
