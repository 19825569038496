import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';

import './EditVehicleModal.css';

export default function EditVehicleModal({
	show,
	vehicleBrand,
	vehicleModel,
	vehicleRegistrationNumber,
	vehicleColor,
	handleClose,
	submitHandler,
}) {
	const [validated, setValidated] = useState(false);
	const [brand, setBrand] = useState('');
	const [color, setColor] = useState('');
	const [model, setModel] = useState('');
	const [registrationNumber, setRegistrationNumber] = useState('');
	const [shiftStart, setShiftStart] = useState('');
	const [shiftEnd, setShiftEnd] = useState('');
	const [deliveryTime, setDeliveryTime] = useState('');
	useEffect(() => {
		if (show) {
			setBrand(vehicleBrand || '');
			setModel(vehicleModel || '');
			setColor(vehicleColor || '');
			setRegistrationNumber(vehicleRegistrationNumber || '');
		}
	}, [show]);
	const submit = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity()) {
			//chequea que se cumplan los required

			if (brand && model && registrationNumber)
				submitHandler({
					brand,
					model,
					color,
					registrationNumber,
					shiftStart: new Date('2010-10-20T' + shiftStart + 'Z'),
					shiftEnd: new Date('1970-01-01T' + shiftEnd + 'Z'),
					deliveryTime,
				});
			close();
		} else {
			setValidated(true); //deja el form como touched
		}
	};
	const close = () => {
		setBrand('');
		setRegistrationNumber('');
		setModel('');
		setColor('');
		setShiftStart('');
		setShiftStart('');
		setDeliveryTime('');
		handleClose();
	};
	return (
		<Modal
			dialogClassName='shipment-extra-info-modal'
			size='md'
			show={show}
			onHide={close}
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>Datos del Vehículo</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					noValidate
					id='form-new-vehicle'
					validated={validated}
					className='row gx-2 justify-content-between'
					onSubmit={submit}
				>
					<FloatingLabel className='col-md-6 mb-2' label='Marca'>
						<Form.Control
							value={brand}
							onChange={(e) => setBrand(e.target.value)}
							required
							type='text'
							placeholder='Marca'
						/>
						<Form.Control.Feedback type='invalid'>La marca es requerida.</Form.Control.Feedback>
					</FloatingLabel>
					<FloatingLabel className='col-md-6 mb-2' label='Modelo'>
						<Form.Control
							value={model}
							onChange={(e) => setModel(e.target.value)}
							required
							type='text'
							placeholder='Modelo'
						/>
						<Form.Control.Feedback type='invalid'>El modelo es requerido.</Form.Control.Feedback>
					</FloatingLabel>
					<FloatingLabel className='col-12 mb-2' label='Matrícula'>
						<Form.Control
							value={registrationNumber}
							onChange={(e) => setRegistrationNumber(e.target.value)}
							required
							type='text'
							placeholder='Matrícula'
						/>
						<Form.Control.Feedback type='invalid'>La matrícula es requerida.</Form.Control.Feedback>
					</FloatingLabel>
					<Row className='m-0 p-0'>
						<Col className='m-0 p-0' xs={9}>
							{' '}
							<FloatingLabel className='mb-2' label='Tiempo de envío (min)'>
								<Form.Control
									value={deliveryTime}
									onChange={(e) => setDeliveryTime(e.target.value)}
									required
									type='number'
								/>
								<Form.Control.Feedback type='invalid'>
									El tiempo de envío es requerido.
								</Form.Control.Feedback>
							</FloatingLabel>
						</Col>
						<Col className='p-0' style={{ marginLeft: '0.25rem' }}>
							<FloatingLabel className='mb-2' label='Color'>
								<Form.Control
									style={{ width: '100%' }}
									value={color}
									onChange={(e) => setColor(e.target.value)}
									type='color'
								/>
							</FloatingLabel>
						</Col>
					</Row>

					<Row className='m-0 p-0'>
						<Col className='m-0 p-0'>
							<FloatingLabel
								className='col-md-6 mb-2'
								label='Inicio de turno'
								style={{ width: '100%' }}
							>
								<Form.Control
									value={shiftStart}
									onChange={(e) => setShiftStart(e.target.value)}
									required
									type='time'
									placeholder='Comienzo de turno'
								/>
								<Form.Control.Feedback type='invalid'>
									El comienzo de turno es requerido.
								</Form.Control.Feedback>
							</FloatingLabel>
						</Col>
						<Col className='p-0' style={{ marginLeft: '0.25rem' }}>
							<FloatingLabel
								className='col-md-6 mb-2'
								label='Fin de turno'
								style={{ width: '100%' }}
							>
								<Form.Control
									value={shiftEnd}
									onChange={(e) => setShiftEnd(e.target.value)}
									required
									type='time'
									placeholder='Fin de turno'
								/>
								<Form.Control.Feedback type='invalid'>
									El fin de turno es requerido.
								</Form.Control.Feedback>
							</FloatingLabel>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer className='d-flex justify-content-end'>
				<Button className='m-0 col-3 me-2' form='form-new-vehicle' type='submit'>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
