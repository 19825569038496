import React from 'react';
import {
	FaBuilding,
	FaChartBar,
	FaCog,
	FaDolly,
	FaFileExport,
	FaIdCard,
	FaRegListAlt,
	FaRoute,
} from 'react-icons/fa';
import { RiTruckLine } from 'react-icons/ri';
import { TiContacts } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import './AdminNavbarOptions.css';

export default function AdminNavbarOptions({ handleCollapse }) {
	return (
		<ul className="admin-navbar-options">
			<li>
				<Link
					to="/AdminScreen/newShipment"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaDolly /> <h3>Nuevo Envio</h3>
				</Link>
			</li>

			<li>
				<Link
					to="/AdminScreen/shipments"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaRegListAlt />
					<h3> Envios</h3>
				</Link>
			</li>

			<li>
				<Link
					to="/AdminScreen/balances"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaChartBar />
					<h3>Balances</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/accounts"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaBuilding />
					<h3>Cuentas</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/contacts"
					onClick={handleCollapse}
					className="nav-link"
				>
					<TiContacts />
					<h3>Libreta de Direcciones</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/usersAdmin"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaIdCard />
					<h3>Repartidores</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/fleet-management"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaRoute />
					<h3>Mapa de reparto</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/vehicles"
					onClick={handleCollapse}
					className="nav-link"
				>
					<RiTruckLine />
					<h3>Gestión de Flota</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/routes"
					onClick={handleCollapse}
					className="nav-link"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						height="1em"
						viewBox="0 0 512 512"
					>
						{
							'Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->'
						}
						<path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z" />
					</svg>
					<h3>Histórico de rutas</h3>
				</Link>
			</li>
			<li>
				<Link
					className="nav-link"
					to="/AdminScreen/reports/search"
					onClick={handleCollapse}
				>
					<FaFileExport />
					<h3>Reportes</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/Config"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaCog />
					<h3>Configuracion</h3>
				</Link>
			</li>
		</ul>
	);
}
