import axios from 'axios';
import React, { useEffect, useReducer } from 'react';
import { Button, Card, Col, Container, Row, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_USER_SUCCESS':
		return { ...state, user: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function UserDetails() {
	const params = useParams();
	const { id } = params;
	const navigate = useNavigate();

	const [{ loading, user }, dispatch] = useReducer(reducer, {
		user: {},
		loading: true,
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get(`/api/users/userById/${id}`);
				await dispatch({ type: 'FETCH_USER_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchData();
	}, [id]);

	const resetPassword = async () => {
		const email = user.email;
		try {
			const result = await axios.post('/api/users/forgotPassword', {
				email,
			});
			toast.success(result.data.message);
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
		}
	};

	const sendVerificationLink = async () => {
		const userId = user._id;
		try {
			const result = await axios.get(`/api/users/sendVerification/${userId}`);
			toast.success(result.data.message);
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
		}
	};

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}

			<div className="container admin-con align-items-center">
				<Helmet>
					<title>Detalles del Usuario</title>
				</Helmet>
				<Container fluid className="my-3">
					<Row className="justify-content-center align-items-center">
						<Col md={10} lg={8} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Detalles del Usuario
										</h2>
										<div className="mb-3 mx-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Row>
													<Col>
														<Form.Group className="mb-3" controlid="name">
															<Form.Label>Nombre</Form.Label>
															<Form.Control
																type="text"
																required
																disabled
																value={user.name}
															></Form.Control>
														</Form.Group>
													</Col>

													<Col>
														<Form.Group className="mb-3" controlid="email">
															<Form.Label>E-mail</Form.Label>
															<Form.Control
																type="text"
																required
																disabled
																value={user.email}
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col>
														<Form.Group className="mb-3" controlid="tipo">
															<Form.Label>Tipo de Usuario</Form.Label>
															<Form.Control
																value={
																	user.userType ? user.userType : 'Cliente'
																}
																disabled
															></Form.Control>
														</Form.Group>
													</Col>
													{user.contact ? (
														<Col>
															<Form.Group className="mb-3" controlid="contact">
																<Form.Label>Contacto Asociado</Form.Label>
																<Form.Control
																	type="text"
																	required
																	disabled
																	value={user.contact.name}
																></Form.Control>
															</Form.Group>
														</Col>
													) : null}
												</Row>

												<div className="border border-2 border-light mt-1"></div>

												<Container fluid>
													<Row className="justify-content-center align-items-center mt-3">
														<Col md={6} className="justify-content-center">
															<Button onClick={resetPassword} variant="info">
                                Reestablecer Contraseña
															</Button>{' '}
														</Col>

														<Col md={6} className="justify-content-center">
															<Button
																disabled={user.isVerified}
																onClick={sendVerificationLink}
																variant="info"
															>
                                Enviar Link de Verificacion
															</Button>
														</Col>
													</Row>
												</Container>

												<div className="border border-1 border-light mt-3"></div>

												<Stack direction="horizontal">
													<Button
														className="justify-content-left"
														id="goBack"
														onClick={() => navigate(-1)}
													>
														<BsArrowLeftCircle /> Atras
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
export default UserDetails;
