import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	FloatingLabel,
	Form,
	Modal,
	Table
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsCheckSquare, BsPlusCircle, BsTrash, BsXCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import countries from '../../static/countries.json';
import statesJson from '../../static/countriesStates.json';
import { getError, getStatus, maxitemsPerPage } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			regions: action.payload.regions,
			loadingFetch: false,
			itemQuantity: action.payload.regions.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true, successUpdate: false };
	case 'UPDATE_SUCCESS':
		return {
			...state,
			loadingUpdate: false,
			successUpdate: true,
		};
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false, successUpdate: false };

	
	default:
		return state;
	}
};

function AdminRegions() {
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	const [
		{ loadingFetch, errorFetch, regions, successDelete, itemQuantity, loadingUpdate, successUpdate },
		dispatch,
	] = useReducer(reducer, {
		regions: [],
		loadingFetch: true,
		loadingUpdate: false,
		successUpdate: false,
		errorFetch: '',
		itemQuantity: 0,
	});
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = regions.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = regions.slice(indexOfFirstPost, indexOfLastPost);
	};
	const navigate = useNavigate();

	//MODAL
	const [regionToDelete, setRegionToDelete] = useState(null);
	const [country, setCountry] = useState('');
	const [states, setStates] = useState([]);
	const [newState, setNewState] = useState('');
	const [disableStates, setDisableStates] = useState(true);


	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {

			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get('/api/config/', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(error.message);
				}
			}
		};
		

		
		if (country) {
			setStates(statesJson[country]);
			setNewState(statesJson[country][0]);
		
		}


		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, country, successUpdate]);

	async function handleDelete(region) {
		 setRegionToDelete(region);
		handleShow();	}

	const deleteRegionHandler = async () => {
		dispatch({ type: 'DELETE_REQUEST' });

		try {
			await axios.put('/api/config/deleteRegion',
				{
					regionToDelete: regionToDelete.toString(),
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
			
			toast.success('Region eliminada Correctamente');
			dispatch({ type: 'DELETE_SUCCESS' });
			handleClose();
		} catch (error) {
			dispatch({
				type: 'DELETE_FAIL',
			});
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	async function addRegionHandler() {
		setDisableStates(false);
		
	}

	async function addRegion(newRegion) {
		setDisableStates(true);
		dispatch({ type: 'UPDATE_REQUEST' });

		try {
			await axios.put('/api/config/addRegion', 
				{
					newRegion: newRegion.toString(),
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
			closeAddRegion();
			toast.success('Region agregada correctamente');
			dispatch({ type: 'UPDATE_SUCCESS' });

		} catch (error) {
			dispatch({
				type: 'UPDATE_FAIL',
			});
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}

	}

	const closeAddRegion = async () =>{
		setNewState('');
		setCountry('');
		setStates([]);
		setDisableStates(true);
	};


	return (
		<div>
			{loadingFetch && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Region</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar la region?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={()=>deleteRegionHandler()}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<Helmet>
				<title>Lista de Regiones</title>
			</Helmet>

			<div className="container">
				<div className="w-100">
					<div
						style={{
							display: 'flex',
							justifyContent: 'right',
							alignItems: 'center',
						}}
					>
						<Button
							className="btn btn-dark m-1 fixed-right"
							value="Add Banner"
							onClick={() => addRegionHandler()}
						>
							<span>
								<BsPlusCircle /> Agregar Region
							</span>
						</Button>
					</div>
					{loadingFetch ? (
						<LoadingBox></LoadingBox>
					) : errorFetch ? (
						<MessageBox variant="danger">{errorFetch}</MessageBox>
					) : (
						<Card>
							<Table
								striped
								bordered
								hover
								responsive
								className="table-container">
																		
								<thead className="align-items-center table-order tHead">
									<tr className="align-items-center table-order r">
										<th className="tableHeader">
										</th>
										<th className="tableHeader"></th>
									</tr>
								</thead>
								{loadingUpdate ? (
									<LoadingBox></LoadingBox>
								) : errorFetch ? (
									<MessageBox variant="danger">{errorFetch}</MessageBox>
								) : (
									<tbody className="align-items-center table-order tableBodyHover pointerCursor">
										{currentPosts.map((region, i) => (
											<tr className='mx-2 px-2 align-items-center' key={i}>
												<td className="mx-1">
													{' '}
													{region}
												</td>
											
												<td >
										
													<BsTrash onClick={() => handleDelete(region)} /> 
								
												</td>
											</tr>
										))}
										{!disableStates ? (

											<tr className='ml-1 align-items-center justify-content-center'>
												<td className="text-left">
													<FloatingLabel controlId="country" label="País">
														<Form.Select
															onChange={(e) => setCountry(e.target.value)}
															aria-label="Pais"
															required
															value={country}
														>
															<option value="">Selecciona un pais</option>
															{countries.map((country) => (
																<option key={country} value={country}>
																	{country}
																</option>
															))}
														</Form.Select>
													</FloatingLabel>

												</td>
												<td className="text-left">
													<FloatingLabel controlId="state" label="Estado">
														<Form.Select
															onChange={(e) => {
																setNewState(e.target.value);}}
															aria-label="Estado"
															required
															value={newState}
															disabled={disableStates}
														>
															{states.map((countryState) => (
																<option key={countryState} value={countryState}>
																	{countryState}
																</option>
															))}
														</Form.Select>
													</FloatingLabel>
												</td>
												<td className="align-items-center justify-content-center">
													<div>
														<Button  onClick={()=> addRegion(newState)}><BsCheckSquare/></Button>
														{' '}
														<Button onClick={() => closeAddRegion()}><BsXCircle/></Button>
													</div>
												</td>
											</tr>
										) : null}
									</tbody>
								)}
							</Table>
						</Card>
					)}
				</div>

				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</div>
	);
}
export default AdminRegions;
