import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import classnames from 'classnames';

function LoadingBox({ className }) {
	return (
		<div className={classnames('spinner-container', { [className]: className })}>
			<div className='spinner-center loading'>
				<Spinner className='spinner-border' role='status'>
					<span className='sr-only'>Cargando</span>
				</Spinner>
			</div>
		</div>
	);
}
export default LoadingBox;
