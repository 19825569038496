import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	InputGroup,
	Modal,
	Row,
	Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import {
	BsArrowLeftCircle,
	BsFillEyeFill,
	BsFillEyeSlashFill,
	BsPlusCircle
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError, getStatus } from '../../utils';
import EditVehicleModal from '../FleetManagement/EditVehicleModal/EditVehicleModal';

const reducer = (state, action) => {
	switch (action.type) {
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	case 'FETCH_VEHICLES_REQUEST':
		return { ...state, loadingVehicles: true };
	case 'FETCH_VEHICLES_SUCCESS':
		return { ...state, loadingVehicles: false, vehicles: action.payload };
	case 'FETCH_VEHICLES_FAIL':
		return { ...state, loadingVehicles: false, error: true };
	default:
		return state;
	}
};
function AddAdmin() {
	const navigate = useNavigate();

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [userType, setUserType] = useState('Repartidor');
	const [showVehicleModal, setShowVehicleModal] = useState('');
	//#endregion
	const [vehicle, setVehicle] = useState(null);


	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordType, setPasswordType] = useState('password');
	const [repeatPasswordType, setRepeatPasswordType] = useState('password');

	const toggleRepeatPassword = () => {
		if (repeatPasswordType === 'password') {
			setRepeatPasswordType('text');
			return;
		}
		setRepeatPasswordType('password');
	};

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	const [{ loadingCreate, loadingVehicles, vehicles }, dispatch] = useReducer(reducer, {
		loadingCreate: false,
		loadingVehicles: true,
		vehicles: [],
		error: '',
	});

	useEffect(() => {
		if (loadingVehicles) {
			fetchVehicles();
		}
	}, [loadingVehicles]);

	const fetchVehicles = async () => {
		try {
			const { data: vehicles } = await axios.get('/api/vehicles', {
				headers: {
					authorization: `Bearer ${userInfo.token}`,
				},
			});
			dispatch({
				type: 'FETCH_VEHICLES_SUCCESS',
				payload: vehicles,
			});
		} catch (err) {
			console.error(err);
			dispatch({ type: 'FETCH_VEHICLES_FAIL' });
		}
	};

	const addUserHandler = async () => {
		if (password !== confirmPassword) {
			toast.error('Las constraseñas ingresadas no coinciden');
			setShowModal(false);
		} else {
			try {
				dispatch({ type: 'CREATE_REQUEST' });
				await axios.post(
					'/api/users/createUser',
					{
						name,
						email,
						password,
						isAdmin: true,
						userType,
						phoneNumber,
						vehicle
					},
					{
						headers: {
							Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
						},
					}
				);
				dispatch({ type: 'CREATE_SUCCESS' });
				toast.success('Repartidor creado');
				navigate('/AdminScreen/usersAdmin');
			} catch (error) {
				if (getError(error).split(' ')[0] === 'E11000') {
					toast.error(
						'El correo ingresado ya se encuentra asociado a un usuario'
					);
				} else {
					dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
					if (getStatus(error) === 401) {
						ctxDispatch({ type: 'USER_SIGNOUT' });
						navigate('/signin');
						toast.error('Sesion expirada. Vuelve a ingresar.');
					} else {
						console.error(error);
						toast.error(getError(error));
					}
				}
			}
		}
	};

	const addVehicle = async (vehicleData) => {
		const {
			brand,
			registrationNumber,
			model,
			color,
			shiftStart,
			shiftEnd,
			deliveryTime,
		} = vehicleData;

		try {
			const newVehicle = await axios.post(
				'/api/vehicles',
				{
					brand,
					model,
					registrationNumber,
					color,
					shiftStart,
					shiftEnd,
					deliveryTime,
				},
				{
					headers: {
						authorization: `Bearer ${userInfo.token}`,
					},
				}
			);
			dispatch({ type: 'FETCH_VEHICLES_REQUEST' });
			await fetchVehicles();
			setVehicle(newVehicle.vehicle);
			toast.success('Vehículo creado');
		} catch (ex) {
			console.error(ex);
			toast.error(getError(ex));
		}
	};




	return (
		<div>
			{loadingCreate && <LoadingBox></LoadingBox>}
			<EditVehicleModal
				handleClose={() => setShowVehicleModal(false)}
				show={showVehicleModal}
				submitHandler={addVehicle}
			/>
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Crear Repartidor</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea crear Repartidor ?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
              Cancelar
					</Button>
					<Button onClick={addUserHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<div
				className="large-container align-items-center admin-con"
			>				<Helmet>
					<title>Crear Repartidor</title>
				</Helmet>
				<Container fluid>
					<Row className="mt-5 justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Crear Repartidor
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="nam">
													<Form.Label>Nombre</Form.Label>
													<Form.Control
														type="text"
														required
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Row>
													<Col>
														<Form.Group className="mb-3" controlid="email">
															<Form.Label>E-mail</Form.Label>
															<Form.Control
																type="email"
																required
																onChange={(e) =>
																	setEmail(e.target.value.toLowerCase())
																}
															></Form.Control>
														</Form.Group>
													
													</Col>
													<Col>
														<Form.Group className="mb-3" controlid="phone">
															<Form.Label>Telefono</Form.Label>
															<Form.Control
																type="text"
																required={userType === 'Repartidor'}
																onChange={(e) =>
																	setPhoneNumber(e.target.value.toLowerCase())
																}
															></Form.Control>
														</Form.Group>
													</Col>

												</Row>

												{/* <Row className='mb-3'> */}
												<Form.Label>Vehículo</Form.Label>
												<InputGroup
													className="mb-3"
													style={{ padding: 0 }}
													controlid="vehicle"
												>
													<Form.Select
														required
														className="border"
														onChange={(e) => setVehicle(e.target.value)}
														style={{ marginRight: '10px' }}
													>
														{vehicle ? (
															<option key={vehicle._id} value={vehicle._id}>
																{vehicle.brand} {vehicle.model} - {vehicle.registrationNumber}
															</option>
														) : (
															<option key={0} value={null}>Selecciona un vehiculo</option>
														)}
														{vehicles.map((vehicle) => {
															return (
																<option
																	key={vehicle._id}
																	value={vehicle._id}
																>
																	{vehicle.brand} {vehicle.model} - {vehicle.registrationNumber}
																</option>
															);
														})}
													</Form.Select>
													<Button
														variant="btn btn--secondary"
														className="eyeButton"
														onClick={()=> setShowVehicleModal(true)}
													>
														<BsPlusCircle/>													</Button>
												</InputGroup>
								


												<Form.Label>Contraseña</Form.Label>
												<InputGroup
													className="mb-3"
													style={{ padding: 0 }}
													controlid="password"
												>
													<Form.Control
														type={passwordType}
														required
														onChange={(e) => setPassword(e.target.value)}
														style={{ marginRight: '10px' }}

													/>
													<Button
														variant="btn btn--secondary"
														className="eyeButton"
														id="button-addon2"
														onClick={togglePassword}
													>
														{passwordType === 'password' ? (
															<BsFillEyeSlashFill />
														) : (
															<BsFillEyeFill />
														)}
													</Button>
												</InputGroup>

												<Form.Label>Repetir Contraseña</Form.Label>
												<InputGroup
													className="mb-3"
													style={{ padding: 0 }}
													controlid="password"
												>
													<Form.Control
														type={repeatPasswordType}
														required
														onChange={(e) => setConfirmPassword(e.target.value)}
														style={{ marginRight: '10px' }}

													/>
													<Button
														variant="btn btn--secondary"
														className="eyeButton"
														id="button-addon2"
														onClick={toggleRepeatPassword}
													>
														{repeatPasswordType === 'password' ? (
															<BsFillEyeSlashFill />
														) : (
															<BsFillEyeFill />
														)}
													</Button>
												</InputGroup>

												<Row>
													<Col>
														<Form.Group className="my-3" controlid="contact">
															<Form.Label>Tipo de Usuario</Form.Label>
															<Form.Select
																onChange={(e) => setUserType(e.target.value)}
															>
																<option value="Repartidor">Repartidor</option>
																<option value="Administrador">
                                  Administrador
																</option>
															</Form.Select>
														</Form.Group>
													</Col>
												</Row>

												<Stack direction="horizontal" gap={10} className="mt-3">
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
													>
                            Crear Repartidor
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
export default AddAdmin;
