import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import './ShipmentAdditionalDetailsModal.css';

export default function ShipmentAdditionalDetailsModal({
	show,
	name,
	phone,
	handleClose,
	submitHandler,
}) {
	const [validated, setValidated] = useState(false);
	const [clientName, setClientName] = useState('');
	const [clientPhone, setClientPhone] = useState('');
	const [itemQuantity, setItemQuantity] = useState('');
	const [comments, setComments] = useState('');
	const phoneInputRef = useRef(null);
	useEffect(() => {
		if (show) {
			setClientName(name || '');
			setClientPhone(phone || '');
		}
	}, [show]);
	const submit = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity()) {
			//chequea que se cumplan los required
			if (
				clientName &&
        clientPhone &&
        clientPhone.match('[^A-Za-z]') &&
        parseInt(itemQuantity) > 0
			)
				submitHandler({
					clientName,
					clientPhone,
					itemQuantity,
					comments,
				});
			close();
		} else {
			setValidated(true); //deja el form como touched
		}
	};
	const close = () => {
		setClientName('');
		setClientPhone('');
		setItemQuantity('');
		setComments('');
		handleClose();
	};
	return (
		<Modal
			dialogClassName="shipment-extra-info-modal"
			size="md"
			show={show}
			onHide={close}
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>Datos adicionales</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					noValidate
					id="form-extra-info"
					validated={validated}
					className="row gx-2 justify-content-between"
					onSubmit={submit}
				>
					<FloatingLabel className="col-md-12 mb-2" label="Nombre cliente">
						<Form.Control
							value={clientName}
							onChange={(e) => setClientName(e.target.value)}
							required
							type="text"
							placeholder="Nombre del Cliente"
						/>
						<Form.Control.Feedback type="invalid">
              El nombre del cliente es requerido.
						</Form.Control.Feedback>
					</FloatingLabel>
					<FloatingLabel className="col-md-6 mb-2" label="Teléfono de contacto">
						<Form.Control
							ref={phoneInputRef}
							value={clientPhone}
							onChange={(e) => setClientPhone(e.target.value)}
							pattern="[^A-Za-z]*"
							required
							type="text"
							placeholder="Teléfono de contacto"
						/>
						<Form.Control.Feedback type="invalid">
							{phoneInputRef.current &&
              phoneInputRef.current.validity.valueMissing
								? ' El Teléfono de contacto es requerido.'
								: 'El Teléfono no puede contener caracteres alfabéticos.'}
						</Form.Control.Feedback>
					</FloatingLabel>
					<FloatingLabel className="col-md-6 mb-2" label="Cantidad de Bultos">
						<Form.Control
							value={itemQuantity}
							onChange={(e) => setItemQuantity(e.target.value)}
							min={1}
							required
							type="number"
							placeholder="Cantidad de Bultos"
						/>
						<Form.Control.Feedback type="invalid">
              La cantidad de bultos debe ser mayor a 0.
						</Form.Control.Feedback>
					</FloatingLabel>
					<FloatingLabel className="col-12" label="Comentarios">
						<Form.Control
							value={comments}
							onChange={(e) => setComments(e.target.value)}
							as="textarea"
							placeholder="Comentarios"
						/>
					</FloatingLabel>
				</Form>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-end">
				<Button className="m-0 col-3 me-2" form="form-extra-info" type="submit">
          Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
