import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import axios from 'axios';
import React, {
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import {
	Button,
	Col,
	Container,
	Form,
	ListGroup,
	ListGroupItem,
	OverlayTrigger,
	Row,
	Stack,
	Table,
	Tooltip,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsTrash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../App.css';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { GoogleMapsApiKey, getError, getStatus } from '../../utils';

const containerStyle = {
	width: '900px',
	height: '600px',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
};

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, zones: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function ClientMap() {
	const [{ loading, error, zones, successDelete, loadingDelete }, dispatch] =
    useReducer(reducer, {
    	zones: [],
    	loadingMap: true,
    	loading: true,
    	loadingCreate: false,
    	loadingDelete: false,
    	error: '',
    });

	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	// Store Polygon path in state
	const [path, setPath] = useState([]);

	const [polygons, setPolygons] = useState([zones]);

	const [clientMap, setMap] = useState(null);

	// Define refs for Polygon instance and listeners
	const polygonRef = useRef(null);
	const listenersRef = useRef([]);

	const fetchContact = async () => {
		try {
			const { data } = await axios.get(
				`/api/contacts/getByUserId/${userInfo._id}`
			);
			//TODO DATA[0] INSTEAD OF [2] ([2] ONLY TO SEE DATA)
			getAccount(data[2]._id);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const getAccount = async (contactId) => {
		try {
			const { data } = await axios.get(
				`/api/accContRelation/accByContact/${contactId}`
			);
			getMap(data[0]._id);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const getMap = async (accountId) => {
		try {
			const { data } = await axios.get(`/api/maps/mapAccount/${accountId}`);
			if (data[0] !== null) {
				await setMap(data);

				await getZones(data._id);
			} else {
				navigate('/payments');
			}
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	const getZones = async (mapId) => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const { data } = await axios.get(`/api/zones/map/${mapId}`);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
			setPolygons(data);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//GET ZONES
	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchContact();
		}
	}, [successDelete]);

	// Call setPath with new edited path
	const onEdit = useCallback(() => {
		if (polygonRef.current) {
			const nextPath = polygonRef.current
				.getPath()
				.getArray()
				.map((latLng) => {
					return { lat: latLng.lat(), lng: latLng.lng() };
				});

			setPath(nextPath);
		}
	}, [setPath]);

	// Bind refs to current Polygon and listeners
	const onLoad = useCallback(
		(polygon) => {
			polygonRef.current = polygon;
			const path = polygon.getPath();
			listenersRef.current.push(
				path.addListener('set_at', onEdit),
				path.addListener('insert_at', onEdit),
				path.addListener('remove_at', onEdit)
			);
		},
		[onEdit]
	);

	// Clean up refs
	const onUnmount = useCallback(() => {
		listenersRef.current.forEach((lis) => lis.remove());
		polygonRef.current = null;
	}, []);

	//DELETE ZONE
	async function deleteZone(id) {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.delete(`/api/zones/${id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			dispatch({ type: 'DELETE_SUCCESS' });
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	}

	return (
		<div>
			<Container className="large-container">
				<Helmet>
					<title>Zonas de Envio</title>
				</Helmet>
				<div className="container">
					<div>
						<Container fluid>
							<Row className=" d-flex justify-content-center align-items-center">
								<Col md={10} className="m-3 bg-white">
									<div className="borderLine"></div>
									<div className="mb-3 mt-md-4">
										<div className="mb-3">
											<h2 className="fw-bold mb-3 text-uppercase px-5 text-center">
                        ZONAS DE ENVIO
											</h2>
											<div className="borderLine"></div>
											<div className="d-flex mt-2 justify-content-center align-items-center">
												{loading || loadingDelete ? (
													<LoadingBox></LoadingBox>
												) : (
													<React.Fragment>
														<LoadScript
															id="script-loader"
															googleMapsApiKey={GoogleMapsApiKey}
															language="en"
															region="us"
														>
															{clientMap ? (
																<GoogleMap
																	mapContainerStyle={containerStyle}
																	center={{
																		lat: clientMap.latCentral,
																		lng: clientMap.lngCentral,
																	}}
																	zoom={12}
																	version="weekly"
																	on
																>
																	<Polygon
																		// Make the Polygon editable / draggable
																		options={{
																			fillOpacity: 0.6,
																			strokeWeight: 1,
																		}}
																		path={path}
																		// Event used when manipulating and adding points
																		onMouseUp={onEdit}
																		// Event used when dragging the whole Polygon
																		onDragEnd={onEdit}
																		onLoad={onLoad}
																		onUnmount={onUnmount}
																	/>

																	<></>
																	{polygons.map(
																		(zone) => (
																			(zone['options'] = {
																				fillColor: zone.color,
																				fillOpacity: 0.6,
																				strokeWeight: 1,
																				strokeColor: zone.color,
																			}),
																			(
																				<Polygon
																					options={zone.options}
																					path={zone.location}
																				/>
																			)
																		)
																	)}
																	<></>
																</GoogleMap>
															) : null}
														</LoadScript>
													</React.Fragment>
												)}
											</div>
											<Stack direction="vertical">
												<Button
													className="m-2"
													id="newShipment"
													onClick={() =>
														navigate(`/newShipment/${clientMap._id}`)
													}
												>
													{' '}
                          Nuevo Envio
												</Button>
											</Stack>
											<div className="borderLine"></div>

											<Container fluid>
												<h5 className="text-center my-3">Lista de Zonas</h5>

												<Table responsive>
													<ListGroup>
														<ListGroupItem>
															<Row className="align-items-center table-order">
																<Col as={'th'} md={3}>
																	<span>Zona</span>
																</Col>
																<Col as={'th'} md={3}>
                                  Color
																</Col>
																<Col as={'th'} md={3}>
                                  Precio
																</Col>
																<Col as={'th'} md={3}></Col>
															</Row>
														</ListGroupItem>
													</ListGroup>

													{loading ? (
														<LoadingBox></LoadingBox>
													) : error ? (
														<MessageBox variant="danger">{error}</MessageBox>
													) : (
														<ListGroup>
															{polygons.map((zone, i) => (
																<ListGroupItem key={i}>
																	<Row className="align-items-center table-order tableBodyHover">
																		<Col md={3}>{zone.name}</Col>
																		<Col md={3}>
																			{' '}
																			<Form.Control
																				type="color"
																				disabled
																				value={zone.color}
																			></Form.Control>
																		</Col>
																		<Col md={3}>{zone.price}</Col>
																		<Col md={3}>
																			<OverlayTrigger
																				key="top"
																				placement="top"
																				overlay={
																					<Tooltip id={'tooltip-top'}>
                                            Eliminar
																					</Tooltip>
																				}
																			>
																				<Button
																					md={4}
																					variant="outline-danger"
																					className="mr-1"
																					onClick={() => deleteZone(zone._id)}
																				>
																					<BsTrash />
																				</Button>
																			</OverlayTrigger>
																		</Col>
																	</Row>
																</ListGroupItem>
															))}
														</ListGroup>
													)}
												</Table>
											</Container>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			</Container>
		</div>
	);
}
export default ClientMap;
