import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import { getStatus, whatsappBaseUrl } from '../../utils';
import LoadingBox from '../LoadingBox';
import './AdminInfo.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, gotaSettings: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

const AdminInfo = () => {
	const navigate = useNavigate();

	const [{loading}, dispatch] = useReducer(reducer, {
		gotaSettings: {},
		loading: true,
		error: '',
	});

	const { state: ctxState, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = ctxState;
	const [companyName, setCompanyName] = useState();
	const [companysubName, setCompanysubName] = useState();
	const [companyAddress, setCompanyAddress] = useState();
	const [companyPhone, setCompanyPhone] = useState();
	const [companyCity, setCompanyCity] = useState();
	const [whatsappNumber, setWhatsappNumber] = useState();
	const [facebookUrl, setFacebookUrl] = useState();
	const [instagramUrl, setInstagramUrl] = useState();
	const [googleMapLocationUrl, setGoogleMapLocationUrl] = useState();
	const [deliveryMapUrl, setDeliveryMapUrl] = useState();
	const [image, setImage] = useState();
	const [icon, setIcon] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/config/public');
				setCompanyName(data.companyName);
				setCompanysubName(data.companysubName);
				setCompanyAddress(data.companyAddress);
				setCompanyPhone(data.companyPhone);
				setCompanyCity(data.companyCity);
				setWhatsappNumber(data.whatsappNumber);
				setFacebookUrl(data.facebookUrl);
				setInstagramUrl(data.instagramUrl);
				setDeliveryMapUrl(data.deliveryMapUrl);
				setGoogleMapLocationUrl(data.googleMapLocationUrl);
				if (data.favicon) {
					setIcon(data.favicon);
				}
				if (data.image) {
					setImage(data.image);
				}
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	const setGotaSettings = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const whatsappUrl = `${whatsappBaseUrl}${whatsappNumber}`;
			const { data } = await axios.put(
				'/api/config',
				{
					companyName,
					companysubName,
					companyAddress,
					companyPhone,
					image,
					companyCity,
					whatsappNumber,
					whatsappUrl,
					facebookUrl,
					instagramUrl,
					googleMapLocationUrl,
					deliveryMapUrl,
					icon,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'UPDATE_SUCCESS', payload: data });
			toast.success('Empresa actualizada Correctamente');
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	};

	const uploadPicture = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, file.name.replaceAll(' ') + r);
		if (image) {
			formData.append('documentId', image._id);
		}
		const multiPart = { 'content-type': 'multipart/form-data' };
		const response = await axios.post(
			'/api/config/uploadSettingsImg',
			formData,
			{
				...multiPart,
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			}
		);
		setImage(response.data);
		toast.success('imagen agregada correctamente');
	};
	const uploadIcon = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, file.name.replaceAll(' ') + r);
		if (icon) {
			formData.append('documentId', icon._id);
		}
		const multiPart = { 'content-type': 'multipart/form-data' };
		const response = await axios.post(
			'/api/config/uploadSettingsIcon',
			formData,
			{
				...multiPart,
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			}
		);
		setIcon(response.data);
		toast.success('Icono agregado correctamente');
	};
	return (
		<div className="admin-info">
			{loading && <LoadingBox></LoadingBox>}

			<div className="container  ps-0 mt-2">
				<Form>
					<Row>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="companyName"
						>
							<Form.Label>Nombre de Empresa</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyName(e.target.value)}
								defaultValue={companyName}
							></Form.Control>
						</Form.Group>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="instagramUrl"
						>
							<Form.Label>URL Instagram</Form.Label>
							<Form.Control
								onChange={(e) => setInstagramUrl(e.target.value)}
								defaultValue={instagramUrl}
							></Form.Control>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="companysubName"
						>
							<Form.Label>Frase/tipo de Empresa</Form.Label>
							<Form.Control
								onChange={(e) => setCompanysubName(e.target.value)}
								defaultValue={companysubName}
							></Form.Control>
						</Form.Group>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="whatsappNumber"
						>
							<Form.Label>Numero Whatsapp</Form.Label>
							<Form.Control
								onChange={(e) => setWhatsappNumber(e.target.value)}
								defaultValue={whatsappNumber}
							></Form.Control>
							<small id="emailHelp" className="form-text text-muted">
                Respetando formato: 5989xxxxxxx
							</small>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="companyAdress"
						>
							<Form.Label>Direccion de Empresa</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyAddress(e.target.value)}
								defaultValue={companyAddress}
							></Form.Control>
						</Form.Group>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="facebookUrl"
						>
							<Form.Label>URL de Facebook</Form.Label>
							<Form.Control
								onChange={(e) => setFacebookUrl(e.target.value)}
								defaultValue={facebookUrl}
							></Form.Control>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="companyCity"
						>
							<Form.Label>Ciudad-Pais</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyCity(e.target.value)}
								defaultValue={companyCity}
							></Form.Control>
						</Form.Group>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="deliveryMapUrl"
						></Form.Group>
					</Row>
					<Row>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="companyPhone"
						>
							<Form.Label>Telefono</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyPhone(e.target.value)}
								defaultValue={companyPhone}
							></Form.Control>
						</Form.Group>
						<Form.Group
							className="mb-3 col-lg-6 col-12"
							controlId="companyLocationUrl"
						>
							<Form.Label>URL de ubicacion de la empresa</Form.Label>
							<Form.Control
								onChange={(e) => setGoogleMapLocationUrl(e.target.value)}
								defaultValue={googleMapLocationUrl}
							></Form.Control>
						</Form.Group>
					</Row>
					<Row className="mb-3">
						<Col>
							<Form.Group className="mb-3" controlId="">
								<Form.Label>Subir Logo</Form.Label>
								<input type="file" onChange={uploadPicture} name="logo"></input>
							</Form.Group>
						</Col>
						<Col>
							{image && image.fileLink ? (
								<img
									alt="logo_photo"
									src={image.fileLink}
									className="logo-photo img-fluid rounded img-thumbnail"
								/>
							) : (
								<img
									alt="logo_photo"
									src="/images/emptyPhoto.png"
									className="logo-photo img-fluid rounded img-thumbnail"
								/>
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3 col-10" controlId="inputIcon">
								<Form.Label>Subir Icono</Form.Label>
								<input type="file" onChange={uploadIcon} name="icon"></input>
							</Form.Group>
						</Col>
						<Col>
							{icon && icon.fileLink ? (
								<img
									alt="logo_photo"
									src={icon.fileLink}
									className="logo-photo img-fluid rounded img-thumbnail"
								/>
							) : (
								<img
									alt="logo_photo"
									src="/images/emptyPhoto.png"
									className="logo-photo img-fluid rounded img-thumbnail"
								/>
							)}
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>
							<Button
								className="d-flex justify-content-center align-items-center"
								md={10}
								onClick={setGotaSettings}
							>
                Actualizar
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};
export default AdminInfo;
