import axios from 'axios';
import React, { useLayoutEffect, useReducer } from 'react';
// import '@ionic/react/css/core.css';
import { Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';
import './App.css';
import AdminFooter from './components/AdminFooter';
import AdminNavbar from './components/AdminNavbar';
import Footerr from './components/Footer';
import LoadingBox from './components/LoadingBox';
import MessageBox from './components/MessageBox';
import Navbarr from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import AccountDetails from './screens/Accounts/AccountDetails';
import AccountList from './screens/Accounts/AccountList';
import EditAccount from './screens/Accounts/EditAccount';
import AddAccount from './screens/Accounts/NewAccount';
import AdminConfigScreen from './screens/AdminConfigScreen/AdminConfigScreen';
import AdminBalances from './screens/Balances/AdminBalances.jsx';
import Balances from './screens/Balances/Balances.jsx';
import AddBanner from './screens/Banners/AddBanner.jsx';
import AdminBanners from './screens/Banners/AdminBanners.jsx';
import EditBanner from './screens/Banners/EditBanner.jsx';
import ChangePassword from './screens/ChangePassword.jsx';
import Contacto from './screens/ContactInfo';
import ContactList from './screens/Contacts/ContactList';
import EditContact from './screens/Contacts/EditContact';
import DebtDetails from './screens/Debts/DebtDetails';
import DebtList from './screens/Debts/DebtList';
import EditDebt from './screens/Debts/EditDebt';
import AddDebt from './screens/Debts/NewDebt';
import FaqPage from './screens/FaqPage/FaqPage';
import EditVehicle from './screens/FleetManagement/EditVehicle.jsx';
import FleetList from './screens/FleetManagement/FleetList';
import FleetManagement from './screens/FleetManagement/FleetManagement';
import VehicleDetails from './screens/FleetManagement/VehicleDetails.jsx';
import HomeScreen from './screens/HomeScreen';
import AddZone from './screens/Maps/AddZones.jsx';
import MapList from './screens/Maps/AdminMapList';
import ClientMap from './screens/Maps/ClientMap.jsx';
import AdminPaymentList from './screens/Payments/AdminPaymentList.jsx';
import EditPayment from './screens/Payments/EditPayment';
import AddPayment from './screens/Payments/NewPayment';
import PaymentDetails from './screens/Payments/PaymentDetails';
import PaymentList from './screens/Payments/PaymentList';
import ProfileScreen from './screens/ProfileScreen.jsx';
import Reports from './screens/Reports';
import ResetPassword from './screens/ResetPassword.jsx';
import RoutesList from './screens/Routes/RoutesList';
import AdminNewShipment from './screens/Shipments/AdminNewShipment.jsx';
import AdminShipmentList from './screens/Shipments/AdminShipmentList.jsx';
import IncompletedShipmentList from './screens/Shipments/IncompletedShipmentList.jsx';
import NewShipment from './screens/Shipments/NewShipment.jsx';
import ShipmentDetails from './screens/Shipments/ShipmentDetails';
import ShipmentList from './screens/Shipments/ShipmentList';
import ShippingCalculator from './screens/Shipments/ShippingCalculator';
import Envios from './screens/ShippingInfo';
import SignInScreen from './screens/SignInScreen';
import SignupScreen from './screens/SignUpScreen.jsx';
import EditUser from './screens/Users/EditUser';
import AddAdmin from './screens/Users/NewAdmin';
import AddUser from './screens/Users/NewUser';
import UserDetails from './screens/Users/UserDetails.jsx';
import UsersAdminList from './screens/Users/UsersAdminList';
import UsersList from './screens/Users/UsersList';
import VerifyUser from './screens/VerifyUser.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			colors: action.payload.colorsObj,
			gotaSettings: action.payload.settings,
			loading: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function App() {
	const [{ loading, error, colors, gotaSettings }, dispatch] = useReducer(reducer, {
		colors: {},
		loading: true,
		error: '',
		gotaSettings: null,
	});

	//getting theme colors-to do: load before page

	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data: colorsObj } = await axios.get('/api/colors');
				const { data: settings } = await axios.get('/api/config/initData');
				dispatch({ type: 'FETCH_SUCCESS', payload: { colorsObj, settings } });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	//setting theme colors
	const GlobalStyles = createGlobalStyle`
:root {
  --primaryColor: ${colors.primaryColor};
  --secondaryColor: ${colors.secondaryColor};;
  --background-color: ${colors.backgroundColor};
  --utilsColor:${colors.utilsColor};
  --navFontColor:${colors.navFontColor};
  --shadowNavColor:${colors.shadowNavColor};
  --IconsColor:${colors.iconsColor};
  --titlesColor:${colors.titlesColor};
  --btnFontColor:${colors.btnFontColor};
}
`;

	return (
		<>
			{' '}
			{loading ? (
				<Row
					className='d-flex justify-content-center align-items-center spinner-row'
					style={{ minHeight: '100vh' }}
				>
					<LoadingBox className='col-1' />
				</Row>
			) : error ? (
				<MessageBox variant='danger'>{error}</MessageBox>
			) : (
				<>
					<GlobalStyles />
					<BrowserRouter>
						<Helmet>
							{gotaSettings && (
								<>
									<title>{gotaSettings.companyName}</title>
									<meta
										name='description'
										content={`${gotaSettings.companysubName + ' |'} GotaDelivery by Gotasoft`}
									/>
								</>
							)}
							{gotaSettings && gotaSettings.favicon && (
								<>
									<link
										rel='icon'
										type='image/png'
										sizes='32x32'
										href={gotaSettings.favicon.fileLink}
									></link>
									<link
										rel='icon'
										type='image/png'
										sizes='16x16'
										href={gotaSettings.favicon.fileLink}
									></link>
									<link rel='apple-touch-icon' href={gotaSettings.favicon.fileLink} />
								</>
							)}
						</Helmet>
						<ScrollToTop></ScrollToTop>
						<div className='d-flex flex-column site-container'>
							<Navbarr></Navbarr>
							<AdminNavbar></AdminNavbar>

							<main>
								<Routes>
									<Route path='/' element={<HomeScreen />}></Route>
									<Route path='/newShipment' element={<NewShipment />}></Route>
									<Route path='/signin' element={<SignInScreen />}></Route>
									<Route path='/reset-password/' element={<ResetPassword />}></Route>
									<Route path='/signup' element={<SignupScreen />}></Route>
									<Route path='/profile' element={<ProfileScreen />}></Route>
									<Route path='/AdminScreen/profile' element={<ProfileScreen />}></Route>
									<Route path='/change-password/:token' element={<ChangePassword />}></Route>
									<Route path='/verify-user/:token' element={<VerifyUser />}></Route>
									<Route
										path='/shipments/calculateShipment'
										element={<ShippingCalculator />}
									></Route>
									<Route path='/shipments/:month' element={<ShipmentList />}></Route>
									<Route path='/shipments' element={<ShipmentList />}></Route>
									<Route path='/incompletedShipments' element={<IncompletedShipmentList />}></Route>
									<Route path='/shipments/details/:orderId' element={<ShipmentDetails />}></Route>
									<Route
										path='AdminScreen/shipments/details/:orderId'
										element={<ShipmentDetails />}
									></Route>
									<Route path='/balances' element={<Balances />}></Route>
									<Route path='/newDebt' element={<AddDebt />}></Route>
									<Route path='/AdminScreen/editDebt/:id' element={<EditDebt />}></Route>
									<Route path='/AdminScreen/debtDetails/:id' element={<DebtDetails />}></Route>
									<Route path='/debt/:id' element={<DebtDetails />}></Route>
									<Route path='/payments/:debtId' element={<PaymentList />}></Route>
									<Route path='/payments' element={<PaymentList />}></Route>
									<Route path='/newPayment' element={<AddPayment />}></Route>
									<Route path='/AdminScreen/editPayment/:id' element={<EditPayment />}></Route>
									<Route
										path='/AdminScreen/paymentDetails/:id'
										element={<PaymentDetails />}
									></Route>
									<Route path='/paymentDetails/:id' element={<PaymentDetails />}></Route>
									<Route path='/contacto' element={<Contacto />}></Route>
									<Route path='/envios' element={<Envios />}></Route>
									<Route path='/AdminScreen' element={<AdminShipmentList />}></Route>
									<Route path='/AdminScreen/shipments' element={<AdminShipmentList />}></Route>
									<Route path='/AdminScreen/vehicles' element={<FleetList />}></Route>
									<Route path='/AdminScreen/editVehicle/:id' element={<EditVehicle />}></Route>
									<Route
										path='/AdminScreen/vehicleDetails/:id'
										element={<VehicleDetails />}
									></Route>
									<Route path='/AdminScreen/routes' element={<RoutesList />}></Route>
									<Route path='/AdminScreen/balances' element={<AdminBalances />}></Route>
									<Route path='/AdminScreen/payments' element={<AdminPaymentList />}></Route>
									<Route path='/AdminScreen/debts' element={<DebtList />}></Route>
									<Route path='/AdminScreen/maps' element={<MapList />}></Route>
									<Route path='/AdminScreen/accounts' element={<AccountList />}></Route>
									<Route path='/AdminScreen/newAccount' element={<AddAccount />}></Route>
									<Route path='/AdminScreen/newShipment' element={<AdminNewShipment />}></Route>
									<Route
										path='/AdminScreen/newShipment/:accountId'
										element={<AdminNewShipment />}
									></Route>
									<Route path='/AdminScreen/editAccount/:id' element={<EditAccount />}></Route>
									<Route
										path='/AdminScreen/accountDetails/:id'
										element={<AccountDetails />}
									></Route>
									<Route path='/AdminScreen/newUser/:accountId' element={<AddUser />}></Route>
									<Route path='/AdminScreen/newAdmin/:accountId' element={<AddAdmin />}></Route>
									<Route path='/AdminScreen/newUser' element={<AddUser />}></Route>
									<Route path='/AdminScreen/newAdmin' element={<AddAdmin />}></Route>
									<Route path='/AdminScreen/editUser/:id' element={<EditUser />}></Route>
									<Route path='/AdminScreen/user/:id' element={<UserDetails />}></Route>
									<Route path='/AdminScreen/users' element={<UsersList />}></Route>
									<Route path='/AdminScreen/usersAdmin' element={<UsersAdminList />}></Route>
									<Route path='/contacts' element={<ContactList />}></Route>
									<Route path='/AdminScreen/contacts' element={<ContactList />}></Route>
									
									<Route path='/contacts/edit/:id' element={<EditContact />}></Route>
									<Route path='/AdminScreen/contacts/edit/:id' element={<EditContact />}></Route>
									<Route path='/clientMap' element={<ClientMap />}></Route>

									<Route path='/AdminScreen/AdminBanners' element={<AdminBanners />}></Route>
									<Route path='/AdminScreen/AdminBanners/AddBanner' element={<AddBanner />}></Route>
									<Route
										path='/AdminScreen/AdminBanners/EditBanner/:id'
										element={<EditBanner />}
									></Route>
									<Route path='/AdminScreen/Config' element={<AdminConfigScreen />}></Route>
									<Route path='/AdminScreen/fleet-management' element={<FleetManagement />}></Route>
									<Route path='/faq' element={<FaqPage />}>
										+{' '}
									</Route>
									<Route path='/AdminScreen/mapScreen/:id' element={<AddZone />}></Route>
									<Route path='/AdminScreen/reports/search' element={<Reports />}></Route>
								</Routes>
							</main>
							<Footerr></Footerr>
							<AdminFooter></AdminFooter>
						</div>
					</BrowserRouter>
				</>
			)}
		</>
	);
}

export default App;
