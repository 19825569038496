import axios from 'axios';
import React, { useContext, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError, getStatus } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			loadingFetch: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddAccount() {
	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [name, setName] = useState('');
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const [{ loadingFetch, loadingCreate }, dispatch] = useReducer(
		reducer,
		{
			loadingFetch: true,
			loadingCreate: false,
			error: '',
		}
	);



	const addAccountHandler = async () => {
		if (name === '' || name === ' ') {
			toast.error('Debe ingresar un nombre para la cuenta');
			return;
		} else {
			try {
				dispatch({ type: 'CREATE_REQUEST' });
				 await axios.post(
					'/api/accounts/createAccount',
					{
						name,
						createdBy: userInfo._id,
					},
					{
						headers: {
							Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
						},
					}
				);
				toast.success('Cuenta creada');
				navigate('/AdminScreen/accounts');
			} catch (error) {
				if (getError(error).split(' ')[0] === 'E11000') {
					toast.error(
						'El nombre ingresado ya se encuentra asociado a una cuenta'
					);
				} else {
					dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
					if (getStatus(error) === 401) {
						ctxDispatch({ type: 'USER_SIGNOUT' });
						navigate('/signin');
						toast.error('Sesion expirada. Vuelve a ingresar.');
					} else {
						console.error(error);
						toast.error(error.message);
					}
				}
			}
		}
	};

	return (
		<div>
			<div>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear Cuenta</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear una Cuenta?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
              Cancelar
						</Button>
						<Button onClick={addAccountHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Crear Cuenta</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Crear Cuenta
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Nombre de la Cuenta</Form.Label>
													<Form.Control
														type="text"
														required
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>
									
												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
													>
                            Agregar Cuenta
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddAccount;
