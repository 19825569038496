import axios from 'axios';
import React, { useEffect, useReducer, } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	FloatingLabel,
	Form,
	Row,
	Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, debt: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function DebtDetails() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, debt }, dispatch] =
    useReducer(reducer, {

    	debt: {},
    	loading: true,
    	error: '',
    });


	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get(`/api/debts/${id}`);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
	
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};

		fetchData();
	}, [id]);




	return (
		<div>
			{(loading) && <LoadingBox></LoadingBox>}
			{/* BAR LOADER / ERRORS*/}
			<div
				className="container align-items-center admin-con"
			>				<Helmet>
					<title>Detalles de la Deuda</title>
				</Helmet>
				<Container fluid>
					<Row className="mt-5 justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Detalles de la Deuda
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Row>
													<Col md={5}>
														<Form.Group className="mb-3" controlId="amount">
															<Form.Label>Monto</Form.Label>
															<Form.Control
																type="text"
																defaultValue={debt.amount}
																disabled
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group className="mb-3" controlId="owner">
															<Form.Label>Tipo de envio</Form.Label>
															<Form.Control
																defaultValue={debt && debt.shipment && debt.shipment.type ? debt.shipment.type.name : null}
																className="border border-1"
																disabled
															
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col>
														<Form.Group className="mb-3" controlId="dues">
															<Form.Label>Cuotas</Form.Label>
															<Form.Control
																type="text"
																defaultValue={debt.dues}
																disabled
									
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group
															className="mb-3"
															controlId="expirationDate"
														>
															<Form.Label>Vencimiento</Form.Label>

															<Form.Control
																type="date"
																defaultValue={debt.expirationDate}
																disabled
															
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														<Form.Group
															className="mb-3"
															controlId="periodicity"
														>
															<Form.Label>Periodicicidad</Form.Label>
															<Form.Control
																type="text"
																defaultValue={debt.periodicity}
																disabled
															></Form.Control>
														</Form.Group>
													</Col>
												</Row>

												<Form.Group
													className="mb-3 mt-2 border"
													controlid="account"
												>
													<FloatingLabel
														controlId="floatingInput"
														label="Selecciona una cuenta"
														className="mb-1"
													>
														<Form.Control
															defaultValue={debt.owner ? debt.owner.name : null}
															disabled
														
														></Form.Control>
													</FloatingLabel>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
export default DebtDetails;
