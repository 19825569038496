import axios from 'axios';

//PREASIGN VEHICLE IN BATCH
export async function preasignVehicles(shipments, vehicles) {
	shipments.map(async (shipment) => {
		const coordinateDestination = await new window.google.maps.LatLng(
			shipment.destinationMarker.lat,
			shipment.destinationMarker.lng
		);

		vehicles.forEach((v) => {
			if (v.zone) {
				const zpolygon = new window.google.maps.Polygon({
					paths: v.zone.location,
				});

				const resultDestination =
          window.google.maps.geometry.poly.containsLocation(
          	coordinateDestination,
          	zpolygon
          );
				if (resultDestination) {
					shipment.vehicle = v;
				}
			}
		});
	});
	return shipments;
}

//PREASIGN VEHICLE FOR ONE SHIMPENT given marker
export async function preasignVehicle(destinationMarker) {
	const vehicles = await getVehicles();

	let vehicle;
	const coordinateDestination = await new window.google.maps.LatLng(
		destinationMarker.lat,
		destinationMarker.lng
	);
	if (vehicles.length > 0) {
		vehicles.forEach((v) => {
			if (v.zone) {
				const zpolygon = new window.google.maps.Polygon({
					paths: v.zone.location,
				});

				const resultDestination =
          window.google.maps.geometry.poly.containsLocation(
          	coordinateDestination,
          	zpolygon
          );
				if (resultDestination) {
					vehicle = v;
				}
			}
		});
	}
	return vehicle;
}


//PREASIGN DELIVERY FOR ONE VEHICLE 
export async function preasignDelivery(vehicleId) {
	const deliveries = await getDeliveries();
	let result = null;
	deliveries.map(async (delivery) => {
		if (delivery.vehicle && delivery.vehicle._id.toString() === vehicleId.toString()) {
			result = delivery;
		} 
	});
	return result;
}


export async function calculateDirectionsResponseUTILS(
	shipmentArray,
	coordinateOrigin,
	optimizeWaypoints = true
) {
	if(shipmentArray.length === 0){
		return [];
	}
	let waypts = [];
	shipmentArray.forEach(async (shipment) => {
		//METER TODO ESTO EN UNA FUNCION Y RECALCULAR UNICAMENTE CON LOS SHIPMENTS PASANDO
		//COMO PARAMETRO EL OPTIMIZEWZYPOINTS
		const coordinateDestination = new window.google.maps.LatLng(
			shipment.destinationMarker.lat,
			shipment.destinationMarker.lng
		);
		waypts.push({
			location: coordinateDestination,
			stopover: true,
		});
	});

	const promises = shipmentArray.map(async (shipment) => {
		const directionsService = new window.google.maps.DirectionsService();
		const results = await directionsService.route({
			origin: coordinateOrigin,
			destination: coordinateOrigin,
			// eslint-disable-next-line no-undef
			travelMode: google.maps.TravelMode.DRIVING,
			//ADD WAYPONINTS
			waypoints: waypts,
			optimizeWaypoints: optimizeWaypoints,
		});

		const timeAndDistance = await calculateTotalDistanceAndTime(
			results.routes[0].legs,
			shipment.vehicle || shipment.deliveryVehicle
		);

		const sortedOrders = await sortOrders(
			shipmentArray,
			results.routes[0].waypoint_order
		);

		const sortedOrderInfo = await calculateEstimatedTimes(
			results.routes[0].legs,
			shipment.vehicle || shipment.deliveryVehicle
		);

		const newRouteData = {
			vehicle: shipment.vehicle || shipment.deliveryVehicle,
			asignedDelivery: shipment.asignedDelivery || null,
			responseToRenderize: results,
			orderVector: results.routes[0].waypoint_order,
			sortedOrderInfo: sortedOrderInfo,
			totalDistance: timeAndDistance.totalDistance,
			totalTime: timeAndDistance.totalTime,
			sortedOrders: sortedOrders,
			originMarker: coordinateOrigin,
			_id: shipment.routeId
		};
		return newRouteData;
	});

	const resultsArray = await Promise.all(promises);

	return resultsArray[0];
}

export async function sortOrders(arrayToSort, routeVector) {

	let sortedArray = new Array(arrayToSort.length);
	await routeVector.forEach(async (element, i) => {
		sortedArray[i] = arrayToSort[element];
	});

	await sortedArray.forEach(async (element) => {
		if (element.vehicle && element.vehicle.shiftStart) {
			element.departureTime = element.vehicle.shiftStart;
		} else {
			let departureTime = new Date();
			departureTime.setUTCHours(9, 0, 0, 0);
			element.departureTime = departureTime.toISOString();
		}
		if (element.vehicle && element.vehicle.deliveryTime) {
			element.stopoverTime = element.vehicle.deliveryTime;
		} else {
			element.stopoverTime = 10;
		}

	});


	return sortedArray;
}

async function calculateEstimatedTimes(orderArray, vehicle) {
	let stopover = 15;
	if (vehicle && vehicle.deliveryTime) {
		stopover = vehicle.deliveryTime;
	}
	let initialHour = 9;
	if (vehicle && vehicle.shiftStart) {
		const timeStart = new Date(vehicle.shiftStart);
		initialHour = timeStart.getUTCHours();
	}
	let time = new Date();
	time.setUTCHours(initialHour, 0, 0, 0);
	// eslint-disable-next-line no-unused-vars
	let totalTime = 0;

	await orderArray.forEach(async (shipment) => {
		const eTime = stopover + Number(shipment.duration.value / 60);
		time.setUTCMinutes(time.getUTCMinutes() + eTime);
		shipment.estimatedTime = time.toISOString();
		totalTime += eTime;
	});

	return orderArray;
}

async function calculateTotalDistanceAndTime(
	arrayToSetDistanceAndTime,
	vehicle
) {
	let stopoverTime = 10;
	if (vehicle && vehicle.deliveryTime) {
		stopoverTime = vehicle.deliveryTime;
	}
	let time = 0;
	let distance = 0;

	await arrayToSetDistanceAndTime.forEach((element) => {
		const eTime =
      stopoverTime + parseFloat((element.duration.value / 60).toFixed(0));
		time += eTime;
		distance += element.distance.value;
	});
	time -= stopoverTime;

	return { totalTime: parseFloat(time.toFixed(0)), totalDistance: distance };
}

export async function recalculateRoute(
	arrayRoutes,
	newSortedOrders,
	indexRouteToModify,
	coordinateOrigin,
	optimize
) {
	const newRoute = await calculateDirectionsResponseUTILS(
		newSortedOrders,
		coordinateOrigin,
		optimize
	);

	arrayRoutes[indexRouteToModify] = newRoute;

	return arrayRoutes;
}

const getVehicles = async () => {
	try {
		const result = await axios.get('/api/vehicles/');
		return result.data;
	} catch (error) {
		console.error(error);
	}
};

const getDeliveries = async () => {
	try {
		const result = await axios.get('/api/users/deliveries/getDeliveries');
		return result.data;
	} catch (error) {
		console.error(error);
	}
};